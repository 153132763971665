import React, { useState, useEffect } from "react";
import SyncButtons from ".././ButtonComponents/SyncButtons";
import ManageLocalButtons from ".././ButtonComponents/ManageLocalButtons";
import TextareaAutosize from "react-textarea-autosize";
import MyLibraryInstructions from ".././PageComponents/MyLibraryInstructions.js";

import "../../components/styles/myLibrary.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

function MyLibrary() {
  const [records, setRecords] = useState([]);
  const [buttons, setButtons] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [promptFields, setPromptFields] = useState(false);
  const [editingRecordIndex, setEditingRecordIndex] = useState(null);
  const [isFilteringData, setIsFilteringData] = useState(false);
  const [subcategories, setSubCategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [hideQuickPicks] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [isInModal, setIsInModal] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const toggleInstructions = () => {
    setShowInstructions((prev) => !prev);
  };

  const [formData, setFormData] = useState({
    category: "",
    subcategory: "",
    area: "",
    type: "",
    title: "",
    prompt: "",
    description: "",
    source: "",
  });

  const filterRecords = (category, subcategory) => {
    const filtered = records.filter((record) => {
      if (subcategory) {
        // check if subcategory is selected or not
        return (
          record.category === category && record.subcategory === subcategory
        );
      } else {
        return record.category === category;
      }
    });
    setRecords(filtered); // Set the state after filtering
    // setFilteredRecords(filtered)
  };

  // Function to clear the filter
  const clearFilter = () => {
    setSelectedCategory("");
    setSelectedSubcategory("");
    readFromDatabase();
    stopEditing();
    // console.log("Clear Filter");
  };

  const addRecordToDatabase = (record) => {
    const openRequest = indexedDB.open("myDatabase2", 3);

    openRequest.onsuccess = function (e) {
      const db = e.target.result;
      const transaction = db.transaction(["myStore"], "readwrite");
      const store = transaction.objectStore("myStore");
      const addRequest = store.add(record);
      addRequest.onsuccess = function () {
        // State update function to trigger re-rendering of component
        readFromDatabase();
      };
    };
  };

  const handleButtonsUpdated = () => {
    // Refresh the buttons
    fetchButtonsFromLocal();
  };

  const fetchButtonsFromLocal = () => {
    const openRequest = indexedDB.open("myButtonDatabase", 5);

    openRequest.onupgradeneeded = function (e) {
      const db = e.target.result;

      // Create the object stores if they don't exist
      if (!db.objectStoreNames.contains("buttons")) {
        db.createObjectStore("buttons", { keyPath: "id", autoIncrement: true });
      }

      if (!db.objectStoreNames.contains("deletedButtons")) {
        db.createObjectStore("deletedButtons", {
          keyPath: "id",
          autoIncrement: true,
        });
      }
    };

    openRequest.onsuccess = function (e) {
      const db = e.target.result;
      const transaction = db.transaction(
        ["buttons", "deletedButtons"],
        "readonly"
      );
      const store = transaction.objectStore("buttons");
      const deletedStore = transaction.objectStore("deletedButtons");

      const getRequest = store.getAll();
      const getDeletedRequest = deletedStore.getAll();

      getRequest.onsuccess = function (e) {
        const allButtons = e.target.result;

        getDeletedRequest.onsuccess = function (e) {
          const deletedButtons = e.target.result;
          const deletedButtonIds = deletedButtons.map((button) => button.id);

          const nonDeletedButtons = allButtons.filter(
            (button) => !deletedButtonIds.includes(button.id)
          );

          // Sort the buttons alphabetically by the 'text' property
          nonDeletedButtons.sort((a, b) => a.text.localeCompare(b.text));

          setButtons(nonDeletedButtons);
        };
      };
    };
    openRequest.onerror = function (event) {
      console.error("Error opening database:", event.target.errorCode);
    };

    // // And similarly for other requests...
    // getRequest.onerror = function (event) {
    //     console.error("Error fetching buttons:", event.target.errorCode);
    //   };
  };

  const handleButtonClick = (button) => {
    if (editingRecordIndex !== null) {
      const newRecords = [...records];
      newRecords[editingRecordIndex].prompt += button.text;
      setRecords(newRecords);
    } else {
      // console.log("Used");
      setFormData((prevState) => ({
        ...prevState,
        prompt: prevState.prompt + button.text,
      }));
    }
  };

  const handleRecordChange = (e, index) => {
    const newRecords = [...records];
    newRecords[index][e.target.name] = e.target.value;
    setRecords(newRecords);

    if (e.target.name === "prompt") {
      // console.log("Used");
      setFormData({
        ...formData,
        prompt: e.target.value,
      });
    }

    updateRecordInDatabase(newRecords[index]); // Update the record in the database

    setEditingRecordIndex(index);
  };

  const handleTextareaFocus = (index) => {
    setEditingRecordIndex(index);
  };

  const updateRecordInDatabase = (record) => {
    const openRequest = indexedDB.open("myDatabase2", 3);

    openRequest.onsuccess = function (e) {
      const db = e.target.result;
      const transaction = db.transaction(["myStore"], "readwrite");
      const store = transaction.objectStore("myStore");

      const putRequest = store.put(record);
      setSelectedCategory("");
      setSelectedSubcategory("");
      // console.log("cleared Category and Subcategory");
    };
  };

  const deleteRecordFromDatabase = (recordId) => {
    const userConfirmation = window.confirm(
      "Are you sure you want to delete this Personal Prompt from your library?"
    );

    if (userConfirmation) {
      const openRequest = indexedDB.open("myDatabase2", 3);

      openRequest.onsuccess = function (e) {
        const db = e.target.result;
        const transaction = db.transaction(["myStore"], "readwrite");
        const store = transaction.objectStore("myStore");

        const deleteRequest = store.delete(recordId);

        deleteRequest.onsuccess = function () {
          readFromDatabase(); // Refresh the records
        };
      };
    }
  };

  const handleButtonTOCHatGPTClick = (text) => {
    const message = {
      source: "myReactApp",
      type: "sendToChatGPT",
      text: text,
    };
    window.top.postMessage(message, "https://chat.openai.com");
  };

  const handleAddNewRecord = async () => {
    // Create a new record with default values
    const newRecord = {
      category: "",
      subcategory: "",
      area: "",
      type: "",
      title: "",
      prompt: "",
      source: "",
      description: "",
    };
    // Add new record to the database
    const newRecordId = await addRecordToDatabase(newRecord);

    // Set the new record as the one being edited
    setEditingRecordIndex(newRecordId);
  };

  const readFromDatabase = () => {
    const openRequest = indexedDB.open("myDatabase2", 3);

    openRequest.onsuccess = function (e) {
      const db = e.target.result;
      const transaction = db.transaction(["myStore"], "readonly");
      const store = transaction.objectStore("myStore");
      const getRequest = store.getAll();

      getRequest.onsuccess = function (e) {
        let allRecords = e.target.result;
        allRecords.sort((a, b) => b.id - a.id);
        setRecords(allRecords);
      };

      getRequest.onerror = function (event) {
        console.error("Error fetching records:", event.target.errorCode);
      };
    };

    openRequest.onupgradeneeded = function (e) {
      const db = e.target.result;
      if (!db.objectStoreNames.contains("myStore")) {
        db.createObjectStore("myStore", { keyPath: "id", autoIncrement: true });
      }
    };

    openRequest.onerror = function (event) {
      console.error("Error opening database:", event.target.errorCode);
    };
  };

  const generateNewId = () => {
    return Date.now() + Math.floor(Math.random() * 1000);
  };

  // duplicate the current record
  const duplicateRecord = (recordId) => {
    const openRequest = indexedDB.open("myDatabase2", 3);
    openRequest.onsuccess = function (e) {
      const db = e.target.result;
      const transaction = db.transaction(["myStore"], "readonly");
      const store = transaction.objectStore("myStore");
      const getRequest = store.get(recordId);
      getRequest.onsuccess = function (e) {
        const recordToDuplicate = e.target.result;
        const newRecord = { ...recordToDuplicate, id: generateNewId() };
        const writeTransaction = db.transaction(["myStore"], "readwrite");
        const writeStore = writeTransaction.objectStore("myStore");
        const putRequest = writeStore.put(newRecord);
        putRequest.onsuccess = function (e) {
          readFromDatabase();
        };
        putRequest.onerror = function (e) {
          // console.error("Error duplicating record: ", e.target.error);
        };
      };
      getRequest.onerror = function (e) {
        // console.error("Error retrieving record: ", e.target.error);
      };
    };
    openRequest.onerror = function (e) {
      // console.error("Error opening database: ", e.target.error);
    };
  };

  const fetchCategoriesFromDatabase = () => {
    return new Promise((resolve, reject) => {
      const openRequest = indexedDB.open("myDatabase2", 3);

      openRequest.onsuccess = function (e) {
        const db = e.target.result;
        const transaction = db.transaction("myStore", "readonly"); // Change this to "myStore"
        const store = transaction.objectStore("myStore"); // Change this to "myStore"

        const getRequest = store.getAll();

        getRequest.onsuccess = function (e) {
          const records = e.target.result;
          const categories = [
            // Extract unique categories
            ...new Set(records.map((record) => record.category)),
          ];

          resolve(categories);
        };

        getRequest.onerror = function (e) {
          reject(e.target.error);
        };
      };

      openRequest.onupgradeneeded = function (e) {
        const db = e.target.result;
        if (!db.objectStoreNames.contains("myStore")) {
          db.createObjectStore("myStore", {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      };

      openRequest.onerror = function (e) {
        reject(e.target.error);
      };
    });
  };

  const handleCategoryChange = (e) => {
    const selectedCat = e.target.value;

    // Filter records by the selected category
    const relatedRecords = records.filter(
      (record) => record.category === selectedCat
    );
    // Extract the unique subcategory
    const uniquesubcategory = [
      ...new Set(relatedRecords.flatMap((record) => record.subcategory)),
    ];

    setSelectedCategory(selectedCat);
    setSubCategory(uniquesubcategory);
  };

  const handleFilterClick = () => {
    filterRecords(selectedCategory, selectedSubcategory);
    // console.log("filterRecords - handleFilterClick");
    startEditing();
  };

  const handleSubCategoryChange = (e) => {
    setSelectedSubcategory(e.target.value);
  };
  // Set isEditing to true when you start editing a category or subcategory.
  const startEditing = () => {
    setIsFilteringData(true);
    // console.log("setFilteringData = true");
  };

  // Set isEditing back to false when you're done editing.
  const stopEditing = () => {
    setIsFilteringData(false);
    // console.log("setFilteringData = false");
  };
  async function copyToClipboard() {
    try {
      // Check if there's text to be copied
      const selection = window.getSelection().toString();
      if (selection === "") {
        alert("No text is selected to copy.");
        return;
      }

      // Attempt to copy text to the clipboard
      await navigator.clipboard.writeText(selection);
      // console.log("Text successfully copied to clipboard.");
    } catch (err) {
      // console.error("Failed to copy text: ", err);
    }
  }
  // This hook runs whenever the 'selectedCategory' or 'selectedSubcategory' states change.
  useEffect(() => {
    if (!isFilteringData) {
      readFromDatabase(selectedCategory, selectedSubcategory);
    }
  }, [selectedCategory, selectedSubcategory, isFilteringData]);

  useEffect(() => {
    fetchCategoriesFromDatabase()
      .then((fetchedCategories) => {
        // console.log("db fetch categories");
        setCategories(fetchedCategories);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    // Check if the app is running in a modal
    const inModal = window.self === window.top;
    setIsInModal(inModal);
  }, []);

  // DOWNLOAD FUNCTIONALITY
  const convertToCSV = (data) => {
    const escape = (field) => {
      const str = field.toString();
      return `"${str.replace(/"/g, '""')}"`;
    };

    // Define the order of your columns here
    const columns = [
      "id",
      "title",
      "prompt",
      "description",
      "category",
      "subcategory",
      "source",
      "area",
      "type",
    ];

    // Generate the header
    const header = columns.map(escape).join(",");

    // Generate each row according to the column order
    const rows = data
      .map((row) => {
        return columns.map((col) => escape(row[col] || "")).join(",");
      })
      .join("\n");

    return `${header}\n${rows}`;
  };

  const downloadCSV = (csv, filename) => {
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    link.href = URL.createObjectURL(blob);
    link.style.visibility = "hidden";
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadDatabase = () => {
    const openRequest = indexedDB.open("myDatabase2", 3);

    openRequest.onsuccess = async function (e) {
      const db = e.target.result;
      const transaction = db.transaction(["myStore"], "readonly");
      const store = transaction.objectStore("myStore");

      const allRecords = [];
      store.openCursor().onsuccess = function (event) {
        const cursor = event.target.result;
        if (cursor) {
          allRecords.push(cursor.value);
          cursor.continue();
        } else {
          // When there are no more records
          const csvData = convertToCSV(allRecords);
          downloadCSV(csvData, "MyDatabase.csv");
        }
      };
    };
  };

  return (
    <div className="noSpace">
      {/* SHOW THE HELP BUTTON */}
      <div style={{ position: "relative" }}>
        <FontAwesomeIcon
          icon={faInfoCircle}
          style={{
            position: "absolute",
            top: "10px",
            right: "0px",
            cursor: "pointer",
          }}
          onClick={toggleInstructions}
        />
      </div>
      {/* SHOW THE BUTTONS OR THE PAGE */}
      {showInstructions ? (
        <div>
          <MyLibraryInstructions />
        </div>
      ) : (
        <div>
          {/* // HIDE SHOW INPUT FORM */}
          {/* TOP OF PAGE  */}
          <div className="flexRowNoWrap">
            <h1 className="marginRight10px">Prompt Library </h1>{" "}
            <button onClick={handleAddNewRecord}>Add New Prompt</button>
            <button className="download" onClick={downloadDatabase}>
              Download CSV
            </button>
          </div>
          {/* FILTER RECORDS  */}
          <div className=" flexRowWrap">
            <div className="borderAround flexRowNoWrap">
              <h3 style={{ marginLeft: "10px" }} className="marginRight10px ">
                Filter Records{" "}
              </h3>
              {/* // EDIT BUTTONS */}

              <select
                value={selectedCategory}
                onChange={handleCategoryChange}
                disabled={isFilteringData}
                className="marginRight10px"
              >
                <option value="" disabled>
                  Select a category
                </option>{" "}
                {categories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
              <select
                value={selectedSubcategory}
                onChange={handleSubCategoryChange}
                disabled={isFilteringData}
                className="marginRight10px"
              >
                <option value="" disabled>
                  Select a subcategory
                </option>
                {subcategories.map((subcategory, index) => (
                  <option key={index} value={subcategory}>
                    {subcategory}
                  </option>
                ))}
              </select>
              {!isFilteringData ? (
                <>
                  <button
                    onClick={handleFilterClick}
                    className="marginRight10px"
                  >
                    Filter
                  </button>
                </>
              ) : (
                <button onClick={clearFilter}>Clear Filter</button>
              )}
            </div>
            {/* // SHOW ALL FIELDS */}
            <label className="switch">
              <input
                type="checkbox"
                className="checkbox"
                checked={promptFields}
                onChange={(e) => setPromptFields(e.target.checked)}
              />
              <span className="slider"></span>
            </label>
            <label>
              {promptFields ? "Show Prompt Only" : "Show All Fields"}
            </label>
          </div>
          {/* =================== */}
          {/* RECORDS START HERE */}
          {/* =================== */}
          <div>
            {records.map((record, index) => (
              <div
                key={index}
                className={
                  index % 2 === 0
                    ? "record-even borderBottom"
                    : "record-odd borderBottom"
                }
              >
                <div
                  className={
                    index === editingRecordIndex
                      ? "selected_record"
                      : "prompt_records"
                  }
                >
                  {/* COLUMNS OF INPUTS */}
                  <div className="flexRowWrap fullWidth">
                    <div className="label_input">
                      <label>Category</label>
                      <input
                        name="category"
                        placeholder="Category"
                        value={record.category}
                        onChange={(e) => handleRecordChange(e, index)}
                      />
                    </div>{" "}
                    <div className="label_input">
                      <label>Subcategory</label>
                      <input
                        name="subcategory"
                        placeholder="Subcategory"
                        value={record.subcategory}
                        onChange={(e) => handleRecordChange(e, index)}
                      />
                    </div>{" "}
                    <div className="label_input">
                      <label>Title</label>
                      <input
                        name="title"
                        placeholder="Title"
                        className="width400"
                        value={record.title}
                        onChange={(e) => handleRecordChange(e, index)}
                      />
                    </div>{" "}
                    {/* AREA TYPE SOURCE */}
                    {promptFields && (
                      <>
                        <div className="label_input">
                          <label>Area</label>
                          <input
                            name="area"
                            placeholder="Area"
                            value={record.area}
                            onChange={(e) => handleRecordChange(e, index)}
                          />
                        </div>{" "}
                        <div className="label_input">
                          <label>Type</label>
                          <input
                            name="type"
                            placeholder="Type"
                            value={record.type}
                            onChange={(e) => handleRecordChange(e, index)}
                          />
                        </div>{" "}
                        <div className="label_input">
                          <label>Source</label>
                          <input
                            name="source"
                            placeholder="Source"
                            value={record.source}
                            onChange={(e) => handleRecordChange(e, index)}
                          />
                        </div>
                      </>
                    )}
                  </div>

                  {/* DESCRIPTION TEXTAREA */}
                  {promptFields && (
                    <>
                      <div className="flexRowWrap fullWidth">
                        <TextareaAutosize
                          className="autosize-textarea"
                          name="description"
                          placeholder="Description"
                          value={record.description}
                          onChange={(e) => handleRecordChange(e, index)}
                        />
                        <button onClick={() => duplicateRecord(record.id)}>
                          Duplicate
                        </button>{" "}
                        <button
                          className="delete"
                          onClick={() => deleteRecordFromDatabase(record.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </>
                  )}

                  <div className="flexRowWrap fullWidth">
                    {/* PROMPT TEXTAREA */}
                    <TextareaAutosize
                      className="autosize-textarea"
                      name="prompt"
                      placeholder="Prompt"
                      value={record.prompt}
                      onChange={(e) => handleRecordChange(e, index)}
                      onFocus={() => handleTextareaFocus(index)}
                    />
                    {/* BUTTON TO SEND TO CHATGPT OR COPY */}
                    {!isInModal ? (
                      <>
                        <button
                          onClick={() =>
                            handleButtonTOCHatGPTClick(record.prompt)
                          }
                        >
                          Send to ChatGPT
                        </button>
                      </>
                    ) : (
                      <button
                        onClick={copyToClipboard}
                        style={{ marginLeft: "10px" }}
                      >
                        <i className="fas fa-copy"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <SyncButtons onButtonsUpdated={handleButtonsUpdated} /> */}
          {/* <AreaDropdowns buttons={buttons} onOptionClick={handleButtonClick} /> */}
        </div>
      )}
    </div>
  );
}

export default MyLibrary;
// {hideQuickPicks && (
//   <>
//     <div className="flexRowWrap">
//       {/* SHOW QUICK PICK BUTTONS */}
//       <label className="switch">
//         <input
//           type="checkbox"
//           className="checkbox"
//           checked={isEditing}
//           onChange={(e) => setIsEditing(e.target.checked)}
//         />
//         <span className="slider"></span>
//       </label>
//       <label>
//         {isEditing
//           ? "Hide Edit Quick Picks"
//           : "Edit Quick Pick Buttons"}
//       </label>
//     </div>
//     <ManageLocalButtons
//       buttons={buttons}
//       fetchButtonsFromLocal={fetchButtonsFromLocal}
//       onButtonClick={handleButtonClick}
//       isEditing={isEditing}
//     />
//   </>
// )}
