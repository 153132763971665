import React, { useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";

function ManageLocalButtons({
  buttons,
  // onButtonClick,
  isEditing,
  fetchButtonsFromLocal,
  // setButtons,
}) {
  // const [editingButtonId, setEditingButtonId] = useState(null);
  // const [editingButtonText, setEditingButtonText] = useState("");
  // const [editingButtonArea] = useState("");
  // const [editingButtonAreas, setEditingButtonAreas] = useState({});
  const [areaButtonMap, setAreaButtonMap] = useState({});
  const AREA_ORDER = [
    "task",
    "context",
    "example",
    "persona",
    "format",
    "tone",
  ];

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleDeleteButton = (buttonId) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      const openRequest = indexedDB.open("myButtonDatabase", 5);

      openRequest.onsuccess = function (e) {
        const db = e.target.result;
        const transaction = db.transaction(
          ["buttons", "deletedButtons"],
          "readwrite"
        );
        const store = transaction.objectStore("buttons");
        const deletedStore = transaction.objectStore("deletedButtons");
        const deleteRequest = store.delete(buttonId);
        deleteRequest.onsuccess = function () {
          const addRequest = deletedStore.add({ id: buttonId });
          addRequest.onsuccess = function () {
            fetchButtonsFromLocal();
          };
          addRequest.onerror = function () {
            console.error("Failed to add to deleted buttons store.");
          };
        };
        deleteRequest.onerror = function () {
          console.error("Failed to delete the button.");
        };
      };
      openRequest.onerror = function (e) {
        console.error("Failed to open the database.", e);
      };
    }
  };

  function setButtonArea(id, newArea) {
    // console.log("Record ID= ", id, " Area= ", newArea);
    return new Promise((resolve, reject) => {
      const openRequest = indexedDB.open("myButtonDatabase", 5);

      openRequest.onsuccess = function (e) {
        const db = e.target.result;
        const transaction = db.transaction("buttons", "readwrite");
        const store = transaction.objectStore("buttons");
        const getRequest = store.get(id);
        getRequest.onsuccess = function (e) {
          const button = e.target.result;
          button.area = newArea;
          button.lastModified = new Date().toISOString(); // Update the lastModified timestamp
          const putRequest = store.put(button);
          putRequest.onsuccess = function () {
            resolve();
          };
          putRequest.onerror = function () {
            reject(new Error("Error updating button area"));
          };
        };
        getRequest.onerror = function () {
          reject(new Error("Error getting button"));
        };
      };
      openRequest.onerror = function (e) {
        reject(new Error("Error opening database"));
      };
    });
  }

  function handleEditOnchange(id, newText) {
    // console.log("Handle Edit Button");

    return new Promise((resolve, reject) => {
      const openRequest = indexedDB.open("myButtonDatabase", 5);
      openRequest.onsuccess = function (e) {
        const db = e.target.result;
        const transaction = db.transaction("buttons", "readwrite");
        const store = transaction.objectStore("buttons");
        const getRequest = store.get(id);
        getRequest.onsuccess = function (e) {
          const button = e.target.result;
          button.text = newText;
          const putRequest = store.put(button);
          putRequest.onsuccess = function () {
            // console.log(`Updated text of ID: ${id} to ${newText}`);
            fetchButtonsFromLocal();
            resolve();
          };
          putRequest.onerror = function () {
            // console.error("Error updating button text");
            reject(new Error("Error updating button text"));
          };
        };
        getRequest.onerror = function () {
          // console.error("Error getting button");
          reject(new Error("Error getting button"));
        };
      };
      openRequest.onerror = function (e) {
        // console.error("Error opening database");
        reject(new Error("Error opening database"));
      };
    });
  }

  useEffect(() => {
    const newAreaButtonMap = buttons.reduce((map, button) => {
      if (!map[button.area]) {
        map[button.area] = [];
      }
      map[button.area] = [...map[button.area], button].sort((a, b) =>
        a.text.localeCompare(b.text)
      );
      return map;
    }, {});
    setAreaButtonMap(newAreaButtonMap);
  }, [buttons]);

  return (
    <div>
      <div className="local_buttons">
        {AREA_ORDER.map((area) => {
          const areaButtons = areaButtonMap[area];
          if (!areaButtons) {
            return null; // Skip if there are no buttons for this area
          }
          return (
            <div key={area} className="flexColumn">
              <h2>{capitalizeFirstLetter(area) || "Unspecified"}</h2>
              {areaButtons.map((button) => (
                <div key={button.id} className="flexRowNoWrap">
                  <select
                    value={button.area || ""}
                    className="qp_select"
                    onChange={async (e) => {
                      const newArea = e.target.value;
                      try {
                        // Automatically update area in the local database
                        await setButtonArea(button.id, newArea);
                        // Fetch the updated buttons to reflect the change in the UI
                        fetchButtonsFromLocal();
                      } catch (error) {
                        console.error("Failed to update area", error);
                      }
                    }}
                  >
                    {AREA_ORDER.map((areaOption, index) => (
                      <option key={index} value={areaOption}>
                        {capitalizeFirstLetter(areaOption) || "Unspecified"}
                      </option>
                    ))}
                  </select>
                  {/* THE TEXT AREA FOR THE BUTTON TEXT */}
                  <TextareaAutosize
                    value={button.text}
                    className="takeTheRemainingSpace"
                    onChange={(e) => {
                      const newText = e.target.value;

                      // Update the local state immediately to prevent re-render and maintain cursor position
                      setAreaButtonMap((prev) => {
                        const updated = { ...prev };
                        const buttonIndex = updated[button.area].findIndex(
                          (b) => b.id === button.id
                        );
                        if (buttonIndex !== -1) {
                          updated[button.area][buttonIndex].text = newText;
                        }
                        return updated;
                      });

                      // Update the database asynchronously
                      handleEditOnchange(button.id, newText).catch((error) => {
                        console.error("Failed to update text", error);
                      });
                    }}
                  />

                  <button
                    className="edit_button"
                    onClick={() => handleDeleteButton(button.id)}
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default ManageLocalButtons;
