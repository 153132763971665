import React, { useState } from "react";
import DBExperts from "./DBExperts";
import Information from "./Information";
import ManageButtons from "./ButtonComponents/ManageButtons";
import withAuthentication from "../components/withAuthentication";
import ManageNiches from "./ManageNiches";
import "../components/styles/DBadmin.css";

const DBAdmin = () => {
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <div className="tabs">
        <button onClick={() => handleTabClick(0)}>Expert Data Table</button>
        <button onClick={() => handleTabClick(1)}>Information Table</button>
        <button onClick={() => handleTabClick(2)}>Manage Buttons</button>
        <button onClick={() => handleTabClick(3)}>Manage Niches</button>
      </div>
      <div className="tab-content">
        {activeTab === 0 && (
          <div>
            <DBExperts />
          </div>
        )}
        {activeTab === 1 && (
          <div>
            <Information />
          </div>
        )}
        {activeTab === 2 && (
          <div>
            <ManageButtons />
          </div>
        )}
        {activeTab === 3 && (
          <div>
            <ManageNiches />
          </div>
        )}
      </div>
    </div>
  );
};

export default withAuthentication(DBAdmin);
//export default DBAdmin;
