import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

const withAuthentication = WrappedComponent => {
  return props => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(authUser => {
        if (authUser) {
          setUser(authUser);
          setLoading(false);
        } else {
          navigate("/login");
        }
      });

      return () => {
        unsubscribe();
      };
    }, [navigate]);

    return <>{!loading && user && <WrappedComponent {...props} />}</>;
  };
};

export default withAuthentication;
