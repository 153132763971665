import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import React from "react";

const DownloadPrompts = ({ matchingItems, itemData }) => {
  const downloadPromptTexts = () => {
    // console.log("itemData");
    // console.log(itemData);
    let fileText = "";
    matchingItems.forEach((item, index) => {
      let modifiedPromptText = item.prompt_text;
      if (itemData[index]) {
        for (const key in itemData[index].inputValues) {
          modifiedPromptText = modifiedPromptText.replace(
            `[${key}]`,
            itemData[index].inputValues[key]
          );
        }
      }
      fileText += modifiedPromptText + "\n\n";
      if (
        itemData[index] &&
        itemData[index].inputValues &&
        itemData[index].inputValues.additionalText
      ) {
        fileText += itemData[index].inputValues.additionalText + "\n\n";
      }
    });

    const textBlob = new Blob([fileText], { type: "text/plain" });
    const url = URL.createObjectURL(textBlob);
    const tempLink = document.createElement("a");

    tempLink.href = url;
    tempLink.download = "prompt_texts.txt";
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };

  return (
    <>
      {matchingItems && matchingItems.length > 0 && (
        <button
          onClick={downloadPromptTexts}
          title="Download All Prompts and Responses (if you added them)"
        >
          <FontAwesomeIcon icon={faArrowDown} /> Download
        </button>
      )}
    </>
  );
};

export default DownloadPrompts;
