import React, { useState, useEffect } from "react";
import { realtimeDb, ref, set, push, onValue, update } from "../firebase";
import "../components/styles/niches.css";
import useDocumentTitle from "./PageComponents/useDocumentTitle";

const NicheRow = React.memo(
  ({ niche, handleEditNicheName, handleDeleteNiche }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedName, setEditedName] = useState(niche.name);

    const handleEdit = () => {
      setIsEditing(true);
    };

    const handleSave = () => {
      handleEditNicheName(niche.name, editedName);
      setIsEditing(false);
    };

    const handleChange = (event) => {
      setEditedName(event.target.value);
    };

    return (
      <div className="inline-row">
        {isEditing ? (
          <input
            className="niche-names"
            type="text"
            value={editedName}
            onChange={handleChange}
          />
        ) : (
          <span className="niche-names">{niche.name}</span>
        )}
        {isEditing ? (
          <button onClick={handleSave}>Save</button>
        ) : (
          <button onClick={handleEdit}>Edit</button>
        )}
        <button onClick={() => handleDeleteNiche(niche.name)}>Delete</button>
      </div>
    );
  }
);

function ManageNiches() {
  const [niches, setNiches] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedNiche] = useState("");
  const [selectedCategories] = useState([]);
  const [newNiche, setNewNiche] = useState("");
  // const [currentNiche] = useState(null);

  useEffect(() => {
    const nichesRef = ref(realtimeDb, "niches");
    onValue(nichesRef, (snapshot) => {
      const data = snapshot.val();
      const nichesArray = Object.values(data || {});
      setNiches(nichesArray);
    });

    const expertInfoRef = ref(realtimeDb, "expert_information");
    onValue(expertInfoRef, (snapshot) => {
      const data = snapshot.val();
      // Extract categories from data
      const categories = Object.values(data).map((record) => record.category);
      // Remove duplicates
      const uniqueCategories = [...new Set(categories)];
      setCategories(uniqueCategories);
    });
  }, []);

  const handleUpdateNiche = () => {
    if (!selectedCategories || selectedCategories.length === 0) {
      alert("Please select at least one category before updating the niche.");
      return;
    }

    const nicheToUpdate = niches.find((niche) => niche.name === selectedNiche);

    if (nicheToUpdate) {
      // The niche already exists, so update it
      const nicheRef = ref(realtimeDb, `niches/${nicheToUpdate.name}`);
      update(nicheRef, {
        name: nicheToUpdate.name,
        categories: selectedCategories,
      });
    } else {
      alert("The selected niche does not exist.");
    }
  };

  const handleRemoveCategoryFromNiche = (nicheName, category) => {
    const nicheToUpdate = niches.find((niche) => niche.name === nicheName);

    if (nicheToUpdate) {
      const updatedCategories = nicheToUpdate.categories.filter(
        (c) => c !== category
      );
      const nicheRef = ref(realtimeDb, `niches/${nicheToUpdate.id}`);
      update(nicheRef, {
        name: nicheName,
        categories: updatedCategories,
      });
    }
  };

  const handleAddNiche = () => {
    const newNicheRef = push(ref(realtimeDb, "niches"));
    const newNicheId = newNicheRef.key; // Get the ID of the new niche

    set(newNicheRef, {
      id: newNicheId, // Store the ID
      name: newNiche,
      categories: [],
    });

    setNewNiche("");
  };

  const handleDeleteNiche = (nicheName) => {
    const nicheToDelete = niches.find((niche) => niche.name === nicheName);
    if (nicheToDelete) {
      const nicheRef = ref(realtimeDb, `niches/${nicheToDelete.id}`);
      set(nicheRef, null);
    }
  };

  useEffect(() => {
    if (selectedNiche) {
      handleUpdateNiche();
    }
  }, [selectedCategories]);

  const handleAddCategoryToNiche = (nicheName, category) => {
    const nicheToUpdate = niches.find((niche) => niche.name === nicheName);

    if (nicheToUpdate) {
      // Check if the category already exists in the niche's categories
      if (
        nicheToUpdate.categories &&
        nicheToUpdate.categories.includes(category)
      ) {
        alert("This category already exists in the selected niche.");
        return;
      }

      const nicheRef = ref(realtimeDb, `niches/${nicheToUpdate.id}`); // Use the ID to reference the niche
      const updatedCategories = nicheToUpdate.categories
        ? [...nicheToUpdate.categories, category]
        : [category];

      update(nicheRef, {
        name: nicheName,
        categories: updatedCategories,
      });
    }
  };
  const handleEditNicheName = (oldName, newName) => {
    const nicheToUpdate = niches.find((niche) => niche.name === oldName);

    if (nicheToUpdate) {
      const nicheRef = ref(realtimeDb, `niches/${nicheToUpdate.id}`);
      update(nicheRef, {
        name: newName,
        categories: nicheToUpdate.categories || [], // If categories is undefined, set it to []
      });
    }
  };

  useDocumentTitle("ADMIN Niche Manager");

  // RETURN THE DATA
  return (
    <div>
      <h1>Manage Niches</h1>

      <button onClick={handleAddNiche}>Add Niche</button>

      <h2>Niches and their categories</h2>
      <div className="niches">
        {niches.map((niche, index) => (
          <div key={`${niche.name}-${index}`} className="niche-rows">
            <NicheRow
              key={niche.id}
              niche={niche}
              handleEditNicheName={handleEditNicheName}
              handleDeleteNiche={handleDeleteNiche}
            />

            <div className="categories">
              <span className="bread">
                {/* Categories added to {niche.name}-&nbsp; */}
                Remove
              </span>
              {niche.categories && niche.categories.length > 0 ? (
                <span>
                  {niche.categories.map((category, index) => (
                    <button
                      key={`${category}-${index}`}
                      onClick={() =>
                        handleRemoveCategoryFromNiche(niche.name, category)
                      }
                    >
                      {category}
                    </button>
                  ))}
                </span>
              ) : (
                <p>No categories assigned</p>
              )}
            </div>

            <div className="add_categories">
              <span>
                {/* Add Categories to {niche.name} */}
                Add
              </span>
              {categories
                .filter(
                  (category) =>
                    !niche.categories || !niche.categories.includes(category) // Filter out categories that are already added
                )
                .map((category, index) => (
                  <button
                    key={`${category}-${index}`}
                    onClick={() =>
                      handleAddCategoryToNiche(niche.name, category)
                    }
                  >
                    {category}
                  </button>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ManageNiches;
