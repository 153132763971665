import React, { useEffect, useState } from "react";

function ManageLocalButtons({
  buttons,
  onButtonClick,
  isEditing,
  fetchButtonsFromLocal,
}) {
  const [editingButtonId, setEditingButtonId] = useState(null);
  const [editingButtonText, setEditingButtonText] = useState("");
  const [editingButtonArea] = useState("");
  const [editingButtonAreas, setEditingButtonAreas] = useState({});
  const [areaButtonMap, setAreaButtonMap] = useState({});
  const AREA_ORDER = [
    "task",
    "context",
    "example",
    "persona",
    "format",
    "tone",
  ];
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleDeleteButton = (buttonId) => {
    const openRequest = indexedDB.open("myButtonDatabase", 5);

    openRequest.onsuccess = function (e) {
      const db = e.target.result;
      const transaction = db.transaction(
        ["buttons", "deletedButtons"],
        "readwrite"
      );
      const store = transaction.objectStore("buttons");
      const deletedStore = transaction.objectStore("deletedButtons");
      const deleteRequest = store.delete(buttonId);
      deleteRequest.onsuccess = function () {
        const addRequest = deletedStore.add({ id: buttonId });
        addRequest.onsuccess = function () {
          fetchButtonsFromLocal();
        };
        addRequest.onerror = function () {};
      };
      deleteRequest.onerror = function () {};
    };
    openRequest.onerror = function (e) {};
  };

  function setButtonArea(id, newArea) {
    // console.log("Record ID= ", id, " Area= ", newArea);
    return new Promise((resolve, reject) => {
      const openRequest = indexedDB.open("myButtonDatabase", 5);

      openRequest.onsuccess = function (e) {
        const db = e.target.result;
        const transaction = db.transaction("buttons", "readwrite");
        const store = transaction.objectStore("buttons");
        const getRequest = store.get(id);
        getRequest.onsuccess = function (e) {
          const button = e.target.result;
          button.area = newArea;
          const putRequest = store.put(button);
          putRequest.onsuccess = function () {
            resolve();
          };
          putRequest.onerror = function () {
            reject(new Error("Error updating button area"));
          };
        };
        getRequest.onerror = function () {
          reject(new Error("Error getting button"));
        };
      };
      openRequest.onerror = function (e) {
        reject(new Error("Error opening database"));
      };
    });
  }

  function handleEditButton(id, newText) {
    return new Promise((resolve, reject) => {
      const openRequest = indexedDB.open("myButtonDatabase", 5);

      openRequest.onsuccess = function (e) {
        const db = e.target.result;
        const transaction = db.transaction("buttons", "readwrite");
        const store = transaction.objectStore("buttons");

        const getRequest = store.get(id);

        getRequest.onsuccess = function (e) {
          const button = e.target.result;
          button.text = newText;
          const putRequest = store.put(button);
          putRequest.onsuccess = function () {
            // console.log(`Updated text of ID: ${id} to ${newText}`);
            fetchButtonsFromLocal();
            resolve();
          };
          putRequest.onerror = function () {
            reject(new Error("Error updating button text"));
          };
        };
        getRequest.onerror = function () {
          reject(new Error("Error getting button"));
        };
      };
      openRequest.onerror = function (e) {
        reject(new Error("Error opening database"));
      };
    });
  }

  async function handleDoneEditing() {
    await setButtonArea(
      editingButtonId,
      editingButtonAreas[editingButtonId] || editingButtonArea
    );
    await handleEditButton(editingButtonId, editingButtonText);
    setEditingButtonId(null);
  }

  useEffect(() => {
    const newAreaButtonMap = buttons.reduce((map, button) => {
      if (!map[button.area]) {
        map[button.area] = [];
      }
      map[button.area] = [...map[button.area], button].sort(
        (a, b) => b.frequency - a.frequency
      );
      return map;
    }, {});
    setAreaButtonMap(newAreaButtonMap);
  }, [buttons]);

  return (
    <div>
      <div>
        <div className="local_buttons">
          {AREA_ORDER.map((area) => {
            const areaButtons = areaButtonMap[area];
            if (!areaButtons) {
              return null; // Skip if there are no buttons for this area
            }
            return (
              <div key={area} className="flexRowWrap">
                <h2>{capitalizeFirstLetter(area) || "Unspecified"}</h2>
                {areaButtons.map((button) => (
                  <div key={button.id}>
                    <button
                      className="text_buttons"
                      style={{
                        backgroundColor:
                          button.frequency > 20
                            ? "green"
                            : button.frequency > 10
                            ? "#228b22"
                            : button.frequency > 1
                            ? "#32cd32"
                            : "#006400",
                      }}
                      onClick={() => onButtonClick(button)}
                    >
                      {button.id === editingButtonId ? (
                        <>
                          <input
                            value={editingButtonText}
                            onChange={(e) =>
                              setEditingButtonText(e.target.value)
                            }
                          />
                          <select
                            value={editingButtonAreas[editingButtonId] || ""}
                            onChange={(e) => {
                              setEditingButtonAreas((prev) => ({
                                ...prev,
                                [editingButtonId]: e.target.value,
                              }));
                            }}
                          >
                            <option value="task">Task</option>
                            <option value="context">Context</option>
                            <option value="example">Example</option>
                            <option value="persona">Persona</option>
                            <option value="format">Format</option>
                            <option value="tone">Tone</option>
                          </select>
                        </>
                      ) : (
                        <>{button.text}</>
                      )}
                    </button>

                    {/* // EDIT BUTTONS */}
                    {isEditing && (
                      <button
                        className="edit_button"
                        onClick={() => {
                          setEditingButtonId(button.id);
                          setEditingButtonText(button.text);
                        }}
                      >
                        Edit
                      </button>
                    )}

                    {/* // DELETE BUTTONS */}
                    {isEditing && (
                      <button
                        className="edit_button"
                        onClick={() => handleDeleteButton(button.id)}
                      >
                        Delete
                      </button>
                    )}

                    {button.id === editingButtonId && (
                      <button
                        className="edit_button"
                        onClick={handleDoneEditing}
                      >
                        Done
                      </button>
                    )}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ManageLocalButtons;
