import React from "react";
import "./styles/Accordion.css";
import SpeakButton from "./SpeakButton";
import StopSpeakButton from "./StopSpeakButton";

const preprocessContent = (content) => {
  return { __html: content.replace(/<XXX>/g, "<br /><br />") };
};

const Accordion = ({ title, content, customContent }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="accordion-wrapper">
      <div
        className={`accordion-title ${isOpen ? "open" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        <p className="force-right">{customContent}</p>
      </div>
      {isOpen && (
        <div className="accordion-content">
          <div dangerouslySetInnerHTML={preprocessContent(content)} />
          <br />
          <div className="flexRowNoWrap">
            <SpeakButton text={content} />
            <StopSpeakButton />
          </div>
        </div>
      )}
    </div>
  );
};

export default Accordion;
