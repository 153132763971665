import React, { useState, useEffect } from "react";
import { realtimeDb, ref, set, push, onValue, update } from "../../firebase";
import useDocumentTitle from "../PageComponents/useDocumentTitle";
import "../../components/styles/ManageButtons.css";

const ButtonRow = React.memo(
  ({ button, handleEditButton, handleDeleteButton }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [setIsFirstEdit] = useState(true);
    const [editedText, setEditedText] = useState(button.text);
    const [editedArea, setEditedArea] = useState(button.area);

    const handleEdit = () => {
      setIsEditing(true);
      setIsFirstEdit(false);
    };

    const handleSave = () => {
      handleEditButton(button, { text: editedText, area: editedArea });
      setIsEditing(false);
      setIsFirstEdit(true);
    };

    const handleTextChange = (event) => {
      setEditedText(event.target.value);
    };

    // const handleAreaChange = (event) => {
    //   setEditedArea(event.target.value);
    // };

    return (
      <div className="inline_row">
        {isEditing ? (
          <>
            <textarea
              className="button-text"
              type="text"
              rows={"1"}
              value={editedText}
              onChange={handleTextChange}
            />
            {/* <select
              value={editedArea}
              onChange={(e) => setEditedArea(e.target.value)}
            >
              <option value="" disabled>
                Select an area
              </option>
              <option value="task">Task</option>
              <option value="context">Context</option>
              <option value="example">Example</option>
              <option value="persona">Persona</option>
              <option value="format">Format</option>
              <option value="tone">Tone</option>
            </select> */}
          </>
        ) : (
          <>
            <span className="button-text">{button.text}</span>
            <span className="button-area">{button.area}</span>
          </>
        )}
        {isEditing ? (
          <button onClick={handleSave}>Save</button>
        ) : (
          <button onClick={handleEdit}>Edit</button>
        )}
        <button
          className="delete"
          onClick={() => handleDeleteButton(button.text)}
        >
          Delete
        </button>
      </div>
    );
  }
);

function ManageButtons() {
  const [buttons, setButtons] = useState([]);
  const [newButton, setNewButton] = useState("");
  const [newButtonArea, setNewButtonArea] = useState("");
  const areaOrder = ["task", "context", "example", "persona", "format", "tone"];

  useEffect(() => {
    const buttonsRef = ref(realtimeDb, "buttons");
    onValue(buttonsRef, (snapshot) => {
      const data = snapshot.val();

      // Convert the data to an array and sort it
      const buttonsArray = Object.entries(data || {})
        .map(([key, value]) => ({
          ...value,
          id: key,
        }))
        .sort((a, b) => {
          const areaOrderDiff =
            areaOrder.indexOf(a.area) - areaOrder.indexOf(b.area);

          if (areaOrderDiff !== 0) return areaOrderDiff;

          // If areas are the same, sort by frequency
          return (b.frequency || 0) - (a.frequency || 0);

          // Return zero if no other conditions are met
          // return 0;  // This line can be omitted if you're sure one of the above conditions will always be met
        });

      setButtons(buttonsArray);
    });
  }, []);

  const handleAddButton = () => {
    const newButtonRef = push(ref(realtimeDb, "buttons"));
    const newButtonId = newButtonRef.key; // Get the ID of the new button

    set(newButtonRef, {
      id: newButtonId, // Store the ID
      text: newButton,
      area:
        newButtonArea !== null && newButtonArea !== undefined
          ? newButtonArea
          : "Unspecified", // Default area

      action: "append", // Default action
      target: "prompt", // Default target
    });

    setNewButton("");
    setNewButtonArea(""); // Clear the new button area input
  };

  const handleEditButton = (button, newValues) => {
    const buttonToUpdate = buttons.find((b) => b.id === button.id);

    if (buttonToUpdate) {
      const buttonRef = ref(realtimeDb, `buttons/${buttonToUpdate.id}`);
      update(buttonRef, {
        text: newValues.text,
        area:
          newValues.area !== null && newValues.area !== undefined
            ? newValues.area
            : "Unspecified", // Use "Unspecified" as default area if newValues.area is undefined or null
        action: buttonToUpdate.action, // Keep the same action
        target: buttonToUpdate.target, // Keep the same target
      });
    }
  };

  const handleDeleteButton = (buttonText) => {
    const buttonToDelete = buttons.find((button) => button.text === buttonText);
    if (buttonToDelete) {
      const buttonRef = ref(realtimeDb, `buttons/${buttonToDelete.id}`);
      set(buttonRef, null);
    }
  };

  useDocumentTitle("Button Manager");

  return (
    <div>
      <h2>Manage Buttons</h2>
      <div className="input_add ">
        <div className="input_area flexRowNoWrap">
          <textarea
            type="text"
            className="text_input_area"
            value={newButton}
            onChange={(e) => setNewButton(e.target.value)}
          />
          <select
            value={newButtonArea}
            onChange={(e) => setNewButtonArea(e.target.value)}
          >
            <option value="task">Task</option>
            <option value="context">Context</option>
            <option value="example">Example</option>
            <option value="persona">Persona</option>
            <option value="format">Format</option>
            <option value="tone">Tone</option>
          </select>
          <button onClick={handleAddButton}>Add</button>
        </div>
      </div>
      <h2>Prompt Input Buttons</h2>
      <div className="button_containers">
        {buttons.map((button, index) => (
          <ButtonRow
            key={button.id}
            button={button}
            handleEditButton={handleEditButton}
            handleDeleteButton={handleDeleteButton}
          />
        ))}
      </div>
    </div>
  );
}

export default ManageButtons;
