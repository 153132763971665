import React from "react";
import PropTypes from "prop-types";

const CategorySelector = ({
  categories,
  selectedCategory,
  onCategoryChange,
}) => {
  // Handle input change
  const handleInputChange = (e) => {
    onCategoryChange(e.target.value);
  };

  // Handle button click
  const handleButtonClick = (category) => {
    onCategoryChange(category);
  };

  return (
    <div className="category-selector">
      <label htmlFor="category-input">Category: </label>
      <input
        id="category-input"
        type="text"
        value={selectedCategory}
        onChange={handleInputChange}
      />
      <div className="category-options">
        {categories.map((category, index) => (
          <button key={index} onClick={() => handleButtonClick(category)}>
            {category}
          </button>
        ))}
      </div>
    </div>
  );
};

CategorySelector.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedCategory: PropTypes.string.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
};

export default CategorySelector;
