import React, { useEffect, useState } from "react";

function AreaDropdowns({ buttons, onOptionClick }) {
  const [areaButtonMap, setAreaButtonMap] = useState({});
  const AREA_ORDER = [
    "Task",
    "Context",
    "Example",
    "Persona",
    "Format",
    "Tone",
  ];
  //   const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (button) => {
    onOptionClick(button);
  };

  useEffect(() => {
    const newAreaButtonMap = buttons.reduce((map, button) => {
      if (!map[button.area]) {
        map[button.area] = [];
      }
      map[button.area] = [...map[button.area], button].sort(
        (a, b) => b.frequency - a.frequency
      );
      return map;
    }, {});
    setAreaButtonMap(newAreaButtonMap);
  }, [buttons]);

  // NOTE THIS FILE GETS ITS CSS FROM myLibrary.css
  return (
    <div>
      <div className="flexRowWrap">
        {AREA_ORDER.map((area) => {
          const areaButtons = areaButtonMap[area.toLowerCase()];
          if (!areaButtons) {
            return null;
          }
          return (
            <div key={area}>
              <h2>{area || "Unspecified"}</h2>
              <select
                onChange={(e) => {
                  const selectedButton = areaButtons.find(
                    (button) => button.text === e.target.value
                  );
                  if (selectedButton) {
                    handleSelectChange(selectedButton);
                  }
                }}
              >
                <option>Select an option...</option>
                {areaButtons.map((button) => (
                  <option key={button.id} value={button.text}>
                    {button.text}
                  </option>
                ))}
              </select>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AreaDropdowns;
