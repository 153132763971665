import React, { useState, useEffect } from "react";
import CommunityLibrary from "./PageComponents/NicheSelector";
import TextChunker from "./PageComponents/ChunkText";
import MyLibrary from "./PageComponents/MyLibrary";
import CustomInstructions from "./PageComponents/CustomInstructions";
import QuickPick from "./PageComponents/QuickpickSelects";
import HelperModal from "./HelperModal";
// import Account from "./Account";

import "../components/styles/AppWindows.css";

const DEFAULT_TAB_KEY = "CommunityLibrary";

const AppWindows = () => {
  const [speedRate, setSpeedRate] = useState(
    parseFloat(localStorage.getItem("speedRate") || "1")
  );

  const [activeTab, setActiveTab] = useState(() => {
    const initialTab = localStorage.getItem("activeTab") || DEFAULT_TAB_KEY;

    // console.log("Initial tab:", initialTab);
    return initialTab;
  });

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  // useEffect(() => {
  //   function handleMessage(event) {
  //     if (event.data.type === "command") {
  //       console.log("WOW Received payload:", event.data.payload);
  //       // Handle the command or payload here
  //     }
  //   }

  //   window.addEventListener("message", handleMessage);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("message", handleMessage);
  //   };
  // }, []);

  // function handleButtonClick() {
  //   // Here's the message you want to send
  //   const message = {
  //     type: "response",
  //     payload: "Message from React app",
  //   };

  //   // Post the message to the parent window
  //   window.parent.postMessage(message, "*");
  // }

  const handleKeyDown = (event) => {
    switch (event.code) {
      case "F2":
        //console/log("user pressed f2");
        // Check if the browser supports the SpeechRecognition API
        if (window.SpeechRecognition || window.webkitSpeechRecognition) {
          const SpeechRecognition =
            window.SpeechRecognition || window.webkitSpeechRecognition;
          const recognition = new SpeechRecognition();

          // This will run when the browser starts listening for audio
          recognition.onstart = () => {
            //console/log("Speech recognition started");
          };

          // This will run when a result is returned by the speech recognition
          recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            //console/log(`You said: ${transcript}`);
          };

          // This will run when there's an error (can help in debugging)
          recognition.onerror = (event) => {
            console.error("Speech recognition error", event);
          };

          // This will run when the speech recognition service has stopped
          recognition.onend = () => {
            //console/log("Speech recognition ended");
          };

          // Start the recognition
          recognition.start();
        } else {
          //console/log("Your browser does not support speech recognition.");
        }

        break;
      case "F4":
        // Get the selected text
        const selectedText = window.getSelection().toString();
        if (selectedText) {
          // Set the utterance's text to the selected text
          utterance.text = selectedText;

          // Read the selected text
          window.speechSynthesis.speak(utterance);
        }
        break;

      case "F6":
        // Stop reading the text
        window.speechSynthesis.cancel();
        break;

      case "F9":
        // Send a message to the extension
        const message = {
          source: "myReactApp",
          type: "ToggleScreen",
          text: "Toggle Full screen",
        };
        window.top.postMessage(message, "https://chat.openai.com");
        //console/log("F9 pressed: Message sent to extension");
        break;

      default:
        // Optionally handle other keys or do nothing
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup - remove the listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // The empty dependency array means this useEffect runs once when the component mounts and cleans up when it unmounts

  const tabs = [
    {
      tab: "ChatGPT",
      key: "CommunityLibrary",
      label: "Community Library",
      component: <CommunityLibrary />,
      order: 1, // Define the order here
    },
    {
      tab: "ChatGPT",
      key: "MyLibrary",
      label: "My Library",
      component: <MyLibrary />,
      order: 2, // Define the order here
    },
    {
      tab: "ChatGPT",
      key: "CustomInstructions",
      label: "Custom Instructions",
      component: <CustomInstructions />,
      order: 3, // Define the order here
    },
    {
      tab: "ChatGPT",
      key: "QuickPick",
      label: "Quick Pick",
      component: <QuickPick />,
      order: 4, // Define the order here
    },
    {
      tab: "Utilities",
      key: "HelperModal",
      label: "Robo Help",
      component: <HelperModal />,
      order: 6, // Define the order here
    },
    {
      tab: "Utilities",
      key: "TextChunker",
      label: "Text Chunker",
      component: <TextChunker />,
      order: 5, // Define the order here
    },
  ];

  tabs.sort((a, b) => a.order - b.order);

  const activeComponent = tabs.find((tab) => tab.key === activeTab)
    ?.component || <HelperModal />;

  const nonAccountTabs = tabs.filter((tab) => tab.key !== "Utilities");
  const accountTab = tabs.find((tab) => tab.key === "Utilities");

  useEffect(() => {
    const handleMessage = (event) => {
      // Validate the origin and potentially the extension ID
      if (
        event.origin ===
          "chrome-extension://ciiiofmmdjkjnhpjhicjgmnnjdjahbli" &&
        event.data.extensionId === "ciiiofmmdjkjnhpjhicjgmnnjdjahbli"
      ) {
        //"ciiiofmmdjkjnhpjhicjgmnnjdjahbli"
        console.log("Valid message received: ", event.data);
      } else {
        console.log("Invalid Message received", event.data);
      }
      // console.log("event.data.type", event.data.type);
      // console.log("event.data.type", event.data.messageType);
      // console.log("event.data.text", event.data.text);
      if (event.data.messageType === "ReadingRate") {
        const newRate = parseFloat(event.data.text);
        localStorage.setItem("speedRate", newRate); // Save to localStorage
        setSpeedRate(newRate);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  // Create a SpeechSynthesisUtterance instance
  const utterance = new SpeechSynthesisUtterance();

  return (
    <div className="appWindowsContainer">
      <div className="flexRowNoWrap">
        {nonAccountTabs.map((tab) => (
          <button
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
            className={activeTab === tab.key ? "selectedTab" : ""}
          >
            {tab.label}
          </button>
        ))}
        {accountTab && (
          <button
            key={accountTab.key}
            onClick={() => setActiveTab(accountTab.key)}
            className={activeTab === accountTab.key ? "selectedTab" : ""}
          >
            {accountTab.label}
          </button>
        )}
      </div>
      <div className="section">
        {activeComponent}
        {activeTab === DEFAULT_TAB_KEY && <hr />}
      </div>
      {/* <button onClick={handleButtonClick}>Send Message to Parent</button> */}
    </div>
  );
};
export default AppWindows;
