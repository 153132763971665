import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";

// PAGES
// import CommunityLibraryPage from "./pages/CommunityLibrary";
import DBAdmin from "./pages/DBAdmin";
import ChatGPTHelper from "./pages/ChatGPTHelper";
import HelperModal from "./pages/HelperModal";
import ManageNiches from "./pages/ManageNiches";
import AppWindows from "./pages/AppWindows";
import WebInfoCollector from "./pages/WebInfoCollector";
import Hub from "./pages/Hub";
import AdminHubButtons from "./pages/AdminHubButtons";
import SelectorValuesManager from "./pages/SelectorValuesManager";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Assistant from "./pages/Assistant";
// import dbadmin from "./pages/dbadmin";
import QuickPick from "./pages/PageComponents/QuickpickSelects"; //
import MyLibrary from "./pages/PageComponents/MyLibrary"; //
import CustomInstructions from "./pages/PageComponents/CustomInstructions"; //
import SaveToLibrary from "./pages/SaveLibrary";
import TextChunker from "./pages/PageComponents/ChunkText"; //
import CommunityLibraryPage from "./pages/PageComponents/NicheSelector";
// import Home from "./pages/Home";
import Login from "./pages/Login";
import Account from "./pages/Account";
import Register from "./pages/Register";

// CSS
import "./components/styles/responsive.css";

const DefaultLayout = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);

const LoginWithKey = () => {
  const location = useLocation();
  return <Login key={location.key} />;
};

function App() {
  return (
    <Router>
      {/* <Elements stripe={stripePromise}> */}
      <div className="App">
        <Routes>
          <Route path="/assistant" element={<Assistant />} />
          <Route path="/hub" element={<Hub />} />
          <Route path="/save-to-library" element={<SaveToLibrary />} />

          {/* ONCE V10 OF THE EXTENSION IS PUBLISHED. REMOVE THEESE  */}
          <Route path="/my-library" element={<MyLibrary />} />
          <Route path="/textchunker" element={<TextChunker />} />
          <Route path="/community-library" element={<CommunityLibraryPage />} />
          <Route path="/custom-instructions" element={<CustomInstructions />} />
          <Route path="/quickpick" element={<QuickPick />} />
          <Route path="/register" element={<Register />} />
          <Route path="/helper_modal" element={<HelperModal />} />
          {/* END OF REMOVE THESE */}

          {/* <Route path="/" exact element={<Home />} /> */}

          <Route
            path="*"
            element={
              <DefaultLayout>
                <Routes>
                  <Route
                    path="/selector-values"
                    element={<SelectorValuesManager />}
                  />
                  {/* <Route path="/" exact element={<Home />} /> */}
                  <Route path="/login" element={<LoginWithKey />} />
                  <Route path="/dbadmin" element={<DBAdmin />} />{" "}
                  <Route path="/manageniches" element={<ManageNiches />} />
                  <Route path="/appwindows" element={<AppWindows />} />
                  <Route path="/account" element={<Account />} />
                  <Route path="/chatgpt_helper" element={<ChatGPTHelper />} />
                  {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
                  <Route
                    path="/admin-hub-buttons"
                    element={<AdminHubButtons />}
                  />
                  <Route
                    path="/web-info-collector"
                    element={<WebInfoCollector />}
                  />
                  {/* <Route
                    path="/community-library/:nicheName"
                    element={<CommunityLibraryPage />}
                  /> */}
                </Routes>
              </DefaultLayout>
            }
          />
        </Routes>
      </div>
      {/* </Elements> */}
    </Router>
  );
}

export default App;
