import React, { useEffect, useState } from "react";
import { realtimeDb, ref, onValue } from "../../firebase";
import CategorySelector from "./CategorySelector";
import MatchingItems from "./MatchingItems";
import useCheckExtension from "../useCheckExtension";

function FilteredCategories({
  templates,
  setTemplates,
  filteredSubCategories,
  setFilteredSubCategories,
  selectedNiche,
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  selectedTemplate,
  setSelectedTemplate,
  selectedTemplateData,
  setSelectedTemplateData,
  categories,
  setCategories,
  expertInformationArray,
  setExpertInformationArray,
  itemData,
  setItemData,
  resetForm,
}) {
  const matchingItems = expertInformationArray.filter(
    (item) => item.template === selectedTemplate
  );

  const [showInputForm, setShowInputForm] = useState(true);

  useCheckExtension();

  // selectedNiche changes, fetches categories and sets them in the state.
  useEffect(() => {
    if (selectedNiche) {
      const expertInformationRef = ref(realtimeDb, "expert_information/");
      onValue(expertInformationRef, (snapshot) => {
        const data = snapshot.val();
        const dataArray = Object.values(data || {});
        const categoriesSet = new Set();

        dataArray.forEach((item) => {
          if (selectedNiche.categories.includes(item.category)) {
            categoriesSet.add(item.category);
          }
        });

        const categoriesArray = Array.from(categoriesSet);
        setCategories(categoriesArray);
        setExpertInformationArray(dataArray);
      });
    } else {
      setCategories([]);
    }
  }, [selectedNiche]);

  // triggered when selectedCategory changes. It fetches the expert information and filters it based on
  // the selected category. It then extracts the unique subcategories from the filtered data and sets
  //  them in the filteredSubCategories state.
  useEffect(() => {
    if (selectedCategory) {
      const expertInfoRef = ref(realtimeDb, "expert_information");
      onValue(expertInfoRef, (snapshot) => {
        const data = snapshot.val();
        // Extract subcategories from data
        const subcategories = Object.values(data)
          .filter((record) => record.category === selectedCategory)
          .map((record) => record.sub_category);
        // Remove duplicates
        const uniqueSubcategories = [...new Set(subcategories)];
        setFilteredSubCategories(uniqueSubcategories); // Update filteredSubCategories
      });
    } else {
      // If selectedCategory is empty, reset the filteredSubCategories
      setFilteredSubCategories([]);
      setTemplates([]);
    }
    // Clear selectedSubCategory and selectedTemplate when selectedCategory changes
    setSelectedSubCategory("");
    setSelectedTemplate("");
    setTemplates([]);
  }, [selectedCategory]);

  // triggered when selectedSubCategory changes. It fetches the expert information
  // and filters it based on the selected subcategory. It then extracts the unique templates
  // from the filtered data and sets them in the templates state.
  useEffect(() => {
    if (selectedSubCategory) {
      const expertInfoRef = ref(realtimeDb, "expert_information");
      onValue(expertInfoRef, (snapshot) => {
        const data = snapshot.val();
        const matchingRecords = Object.values(data).filter(
          (record) => record.sub_category === selectedSubCategory
        );
        const templates = matchingRecords.map((record) => record.template);
        const uniqueTemplates = [...new Set(templates)];
        setTemplates(uniqueTemplates);
      });
    }
  }, [selectedSubCategory]);

  // useEffect(() => {
  //   const handleMessage = (event) => {
  //     if (event.data.type === "CHAT_ASSISTANT_MESSAGE") {
  //       // console.log("REACTAPP.PROMPTER got a message = " + event.data.text);
  //     }
  //   };

  //   window.addEventListener("message", handleMessage);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("message", handleMessage);
  //   };
  // }, []);

  // Handle input change
  const handleTemplateInputChange = (e) => {
    setSelectedTemplate(e.target.value);
  };

  // Handle button click
  const handleTemplateButtonClick = (template) => {
    setSelectedTemplate(template);
  };
  // Handle input change
  const handleSubCategoryInputChange = (e) => {
    setSelectedSubCategory(e.target.value);
  };

  // Handle button click
  const handleSubCategoryButtonClick = (subCategory) => {
    setSelectedSubCategory(subCategory);
  };
  // =============== PROMPT SELECTION TABLE BEGINS
  // =============================================
  return (
    <div style={{ paddingBottom: "50px" }}>
      <div className="prompt-container">
        {showInputForm && (
          <div className="input-controls">
            <CategorySelector
              categories={categories}
              selectedCategory={selectedCategory}
              onCategoryChange={(category) => setSelectedCategory(category)}
            />
            {/* SUB CATEGORIES */}
            <div className="subcategory-selector">
              <label htmlFor="subcategory-input">Sub-Category: </label>
              <input
                id="subcategory-input"
                type="text"
                value={selectedSubCategory}
                onChange={handleSubCategoryInputChange}
              />
              <SubCategoryButtons
                filteredSubCategories={filteredSubCategories}
                handleSubCategoryButtonClick={handleSubCategoryButtonClick}
              />
            </div>
            {/* TEMPLATES-TITLES */}
            <div className="template-selector">
              <label htmlFor="template-input">Title: </label>
              <input
                id="template-input"
                type="text"
                value={selectedTemplate}
                onChange={handleTemplateInputChange}
              />
              <TemplateButtons
                templates={templates}
                handleTemplateButtonClick={handleTemplateButtonClick}
              />
            </div>
          </div>
        )}{" "}
        {/* Use the MatchingItems component */}
        <MatchingItems
          matchingItems={matchingItems}
          selectedTemplateData={selectedTemplateData}
          setSelectedTemplateData={setSelectedTemplateData}
          itemData={itemData}
          setItemData={setItemData}
          resetForm={resetForm}
          showInputForm={showInputForm}
          setShowInputForm={setShowInputForm}
        />
      </div>
    </div>
  );

  function SubCategoryButtons({
    filteredSubCategories,
    handleSubCategoryButtonClick,
  }) {
    return (
      <div className="subcategory-options">
        {filteredSubCategories.map((subCategory, index) => (
          <button
            key={index}
            onClick={() => handleSubCategoryButtonClick(subCategory)}
          >
            {subCategory}
          </button>
        ))}
      </div>
    );
  }

  function TemplateButtons({ templates, handleTemplateButtonClick }) {
    return (
      <div className="template-options">
        {templates.map((template, index) => (
          <button
            key={index}
            onClick={() => handleTemplateButtonClick(template)}
          >
            {template}
          </button>
        ))}
      </div>
    );
  }
}

export default FilteredCategories;
