import "./firebase";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./globalStyles.css";
import "./setupPolyfills";
import "font-awesome/css/font-awesome.min.css";

// import { getApps } from "firebase/app"; // Import getApps from Firebase

// const apps = getApps();
// if (apps.length > 0) {
//console.log(
//     "Firebase apps initialized: ",
//     apps.map((app) => app.name)
//   );
// } else {
//   console.warn("No Firebase apps have been initialized.");
// }

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
