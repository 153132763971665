import React, { useState, useEffect } from "react";
import FilteredCategories from "../../components/Prompter/FilteredCategories";
import { useParams } from "react-router-dom";
import { realtimeDb, ref, onValue } from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import CommunityLibraryInstruction from "../../pages/PageComponents/CommunityLibraryInstructions";
function NicheSelector() {
  const [selectedNiche, setSelectedNiche] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [selectedTemplateData, setSelectedTemplateData] = useState(null);
  const [categories, setCategories] = useState([]);
  const [expertInformationArray, setExpertInformationArray] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [niches, setNiches] = useState([]);
  const [selectedNicheButton, setSelectedNicheButton] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const { nicheName } = useParams();
  const [buttons, setButtons] = useState([]);
  const [showInstructions, setShowInstructions] = useState(false);

  const toggleInstructions = () => {
    setShowInstructions((prev) => !prev);
  };

  const resetForm = () => {
    setSelectedNiche(null);
    setSelectedCategory("");
    setSelectedSubCategory("");
    setSelectedTemplate("");
    setSelectedTemplateData(null);
    setItemData([]);
    setExpertInformationArray([]);
  };

  useEffect(() => {
    // console.log("Niche name inside useEffect:", nicheName);
    const nichesRef = ref(realtimeDb, "niches");
    onValue(nichesRef, (snapshot) => {
      const data = snapshot.val();
      const nichesArray = Object.values(data || {});
      setNiches(nichesArray);

      // console.log("Fetched niches:", nichesArray); // Log fetched niches

      // Check if the nicheName exists in the niches
      const nicheFromParam = nichesArray.find(
        (niche) => niche.name === nicheName
      );
      // console.log("Niche from URL parameter:", nicheFromParam); // Log the niche found from the URL parameter

      if (nicheFromParam) {
        handleNicheClick(nicheFromParam);
      }
    });
  }, [nicheName]); // Add nicheName as a dependency

  useEffect(() => {
    // Check if the nicheName exists in the niches
    if (nicheName && niches.length > 0) {
      const nicheFromParam = niches.find(
        (niche) => niche.name.toLowerCase() === nicheName.toLowerCase()
      );

      if (nicheFromParam) {
        handleNicheClick(nicheFromParam);
      }
    }
  }, [nicheName, niches]); // Add niches as a dependency

  const handleNicheClick = (niche) => {
    setSelectedNiche(niche);
    setSelectedNicheButton(niche.name);
  };

  const handleReset = () => {
    setSelectedNiche(null);
    setSelectedNicheButton(null);
    setCategories([]);
    setSelectedSubCategory(""); // Add this line
    setSelectedTemplate("");
    resetForm();
  };

  return (
    <div>
      <div style={{ position: "relative" }}>
        <FontAwesomeIcon
          icon={faInfoCircle}
          style={{
            position: "absolute",
            top: "10px",
            right: "0px",
            cursor: "pointer",
          }}
          onClick={toggleInstructions}
        />
      </div>
      {showInstructions ? (
        <div>
          <CommunityLibraryInstruction />
        </div>
      ) : (
        <div>
          <div>
            <button className="reset" onClick={handleReset}>
              Reset
            </button>
            {niches.map(
              (niche, index) =>
                (selectedNicheButton === null ||
                  selectedNicheButton === niche.name) && (
                  <button key={index} onClick={() => handleNicheClick(niche)}>
                    {niche.name}
                  </button>
                )
            )}
          </div>
          {/* <h2>Prompt Selectors</h2> */}
          <FilteredCategories
            templates={templates}
            setTemplates={setTemplates}
            filteredSubCategories={filteredSubCategories}
            setFilteredSubCategories={setFilteredSubCategories}
            selectedSubCategory={selectedSubCategory}
            setSelectedSubCategory={setSelectedSubCategory}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            selectedNiche={selectedNiche}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            selectedTemplateData={selectedTemplateData}
            setSelectedTemplateData={setSelectedTemplateData}
            categories={categories}
            setCategories={setCategories}
            expertInformationArray={expertInformationArray}
            setExpertInformationArray={setExpertInformationArray}
            itemData={itemData}
            setItemData={setItemData}
            resetForm={resetForm}
          />
        </div>
      )}
    </div>
  );
}

export default NicheSelector;
