import React, { useState } from "react";
import "../../components/styles/QuickPick.css";
import SyncButtons from ".././ButtonComponents/SyncButtons";
import ManageLocalButtonsEdit from ".././ButtonComponents/ManageLocalButtonsEdit";
import useDocumentTitle from ".././PageComponents/useDocumentTitle";
import TextareaAutosize from "react-textarea-autosize";

function QuickPickEdits() {
  const [buttons, setButtons] = useState([]);
  const [newButtonText, setNewButtonText] = useState("");
  const [isEditing] = useState(false);
  const [buttonArea, setButtonArea] = useState("task");
  const [isFetching, setIsFetching] = useState(false);

  const handleButtonsUpdated = () => {
    // Refresh the buttons
    fetchButtonsFromLocal();
  };

  useDocumentTitle("Quick Pick - Editor");

  const fetchButtonsFromLocal = () => {
    // Prevent concurrent fetch operations
    if (isFetching) {
      // console.log("Fetch operation already in progress");
      return;
    }
    setIsFetching(true);
    // Open a connection to your IndexedDB database
    const openRequest = indexedDB.open("myButtonDatabase", 5);
    openRequest.onupgradeneeded = function (e) {
      const db = e.target.result;
      // Create the object stores if they don't exist
      if (!db.objectStoreNames.contains("buttons")) {
        db.createObjectStore("buttons", { keyPath: "id", autoIncrement: true });
      }
      if (!db.objectStoreNames.contains("deletedButtons")) {
        db.createObjectStore("deletedButtons", {
          keyPath: "id",
          autoIncrement: true,
        });
      }
    };
    openRequest.onsuccess = function (e) {
      // console.log("Database opened successfully");
      const db = e.target.result;
      // Start a transaction encompassing your buttons and deletedButtons object stores
      const transaction = db.transaction(
        ["buttons", "deletedButtons"],
        "readonly"
      );
      const store = transaction.objectStore("buttons");
      const deletedStore = transaction.objectStore("deletedButtons");

      const getRequest = store.getAll();
      const getDeletedRequest = deletedStore.getAll();

      getRequest.onsuccess = function (e) {
        // console.log("Get request onsuccess triggered");

        const allButtons = e.target.result;
        getDeletedRequest.onsuccess = function (e) {
          // console.log("Get deleted request onsuccess triggered");

          // Filter to get only non-deleted buttons
          const deletedButtons = e.target.result;
          const deletedButtonIds = deletedButtons.map((button) => button.id);
          const nonDeletedButtons = allButtons.filter(
            (button) => !deletedButtonIds.includes(button.id)
          );

          // Sort the non-deleted buttons first by frequency and then alphabetically
          nonDeletedButtons.sort((a, b) => {
            if (a.frequency > b.frequency) return -1;
            if (a.frequency < b.frequency) return 1;

            // When frequencies are equal, sort alphabetically
            return a.text.localeCompare(b.text);
          });
          setButtons(nonDeletedButtons);

          // Fetch operation complete, reset isFetching flag
          setIsFetching(false);
        };
      };
    };

    openRequest.onerror = function (e) {
      console.error("Open request error:", e);

      // Reset isFetching flag in case of error to allow future fetch operations
      setIsFetching(false);
    };
  };

  const handleButtonClick = (button) => {
    // Open the database
    const openRequest = indexedDB.open("myButtonDatabase", 5);

    openRequest.onsuccess = function (e) {
      const db = e.target.result;
      const transaction = db.transaction("buttons", "readwrite");
      const store = transaction.objectStore("buttons");
      const getRequest = store.get(button.id);

      getRequest.onsuccess = function (e) {
        const dbButton = e.target.result;

        if (!dbButton.hasOwnProperty("frequency")) {
          dbButton.frequency = 0;
        }

        dbButton.frequency += 1;
        const putRequest = store.put(dbButton);
        putRequest.onsuccess = function () {
          fetchButtonsFromLocal(); // Refresh the buttons
        };
      };
    };
    // SEND BUTTON TO CHATGPT THROUGH EXTENSION
    const message = {
      source: "myReactApp",
      type: "sendToChatGPT",
      text: button.text + " ",
    };
    window.top.postMessage(message, "https://chat.openai.com");
  };

  const handleAddButton = () => {
    const openRequest = indexedDB.open("myButtonDatabase", 5);

    openRequest.onupgradeneeded = function (e) {
      const db = e.target.result;
      const store = db.createObjectStore("buttons", {
        keyPath: "id",
        autoIncrement: true,
      });

      // Add new 'area' index to the 'buttons' object store
      store.createIndex("area", "area", { unique: false });
    };

    openRequest.onsuccess = function (e) {
      const db = e.target.result;
      const transaction = db.transaction("buttons", "readwrite");
      const store = transaction.objectStore("buttons");

      const addRequest = store.add({
        text: newButtonText,
        id: Date.now(),
        frequency: 0,
        area: buttonArea, // Add 'area' property to the button object being stored
      });

      addRequest.onsuccess = function () {
        fetchButtonsFromLocal(); // Refresh the buttons
        setNewButtonText(""); // Clear the input field
      };
    };
  };

  const getButtonColor = (frequency) => {
    if (frequency > 1) {
      return "yellow";
    }
    if (frequency > 10) {
      return "orange";
    }
    if (frequency > 20) {
      return "green";
    }
  };

  return (
    <div>
      <div className="flexRowNoWrap borderAround">
        <div className="edit_check_button">
          <select
            value={buttonArea}
            className="qp_select"
            onChange={(e) => setButtonArea(e.target.value)}
          >
            <option value="task">Task</option>
            <option value="context">Context</option>
            <option value="example">Example</option>
            <option value="persona">Persona</option>
            <option value="format">Format</option>
            <option value="tone">Tone</option>
          </select>

          <TextareaAutosize
            value={newButtonText}
            onChange={(e) => setNewButtonText(e.target.value)}
            placeholder="New button text"
            className="takeTheRemainingSpace"
          />
          <button onClick={handleAddButton}>Add New</button>
        </div>
      </div>

      <SyncButtons onButtonsUpdated={handleButtonsUpdated} />

      <ManageLocalButtonsEdit
        buttons={buttons}
        fetchButtonsFromLocal={fetchButtonsFromLocal}
        onButtonClick={handleButtonClick}
        isEditing={!isEditing}
        getButtonColor={getButtonColor}
        setButtons={setButtons}
      />
    </div>
  );
}

export default QuickPickEdits;
