import React, { useState, useEffect } from "react";
// import useDocumentTitle from "./useDocumentTitle";
import ChunkerInstruction from ".././PageComponents/ChunkerInstructions";
import "../../components/styles/TextChunker.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const ChunkText = () => {
  const [chunkSize, setChunkSize] = useState(2000);
  const [textInput, setTextInput] = useState("");
  const [textChunks, setTextChunks] = useState([]);
  const [selectedModel, setSelectedModel] = useState("GPT-4");
  const [selectedTextArea, setSelectedTextArea] = useState(null);
  const [isInModal, setIsInModal] = useState(false);
  const [buttons, setButtons] = useState([]);
  const [showInstructions, setShowInstructions] = useState(false);

  // useDocumentTitle("Text Chunker");

  const toggleInstructions = () => {
    setShowInstructions((prev) => !prev);
  };

  // Models and their corresponding token sizes (you can adjust as needed)
  const models = [
    { name: "GPT-3", tokens: 4096, characters: 4000 }, // Example conversion to characters
    { name: "GPT-4", tokens: 8192, characters: 2000 }, // Example conversion to characters
    // Add other models here
  ];

  // Function to handle model selection
  const handleModelChange = (e) => {
    const selected = e.target.value;
    setSelectedModel(selected);
    const model = models.find((model) => model.name === selected);
    setChunkSize(model.characters); // Set the chunk size based on characters
  };

  const handleTextAreaClick = (index) => {
    setSelectedTextArea(index);
  };

  const splitText = () => {
    const words = textInput.split(/\s+/);
    const chunks = [];

    for (let i = 0; i < words.length; i += chunkSize) {
      const chunk = words.slice(i, i + chunkSize).join(" ");
      chunks.push(chunk);
    }

    setTextChunks(chunks);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleButtonTOCHatGPTClick = (text) => {
    const message = {
      source: "myReactApp",
      type: "QuickPick",
      text: text,
    };
    window.top.postMessage(message, "https://chat.openai.com");
  };

  useEffect(() => {
    // Check if the app is running in a modal
    const inModal = window.self === window.top;
    setIsInModal(inModal);
  }, []);

  return (
    <div>
      <div className="noSpace" style={{ position: "relative" }}>
        <FontAwesomeIcon
          icon={faInfoCircle}
          style={{
            position: "absolute",
            top: "10px",
            right: "0px",
            cursor: "pointer",
          }}
          onClick={toggleInstructions}
        />
      </div>
      {showInstructions ? (
        <div>
          <ChunkerInstruction />
        </div>
      ) : (
        <div className="noSpace">
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="borderAround"
          >
            <label htmlFor="model">Choose Model:</label>
            <select
              id="model"
              value={selectedModel}
              onChange={handleModelChange}
            >
              {models.map((model, index) => (
                <option key={index} value={model.name}>
                  {model.name}
                </option>
              ))}
            </select>
            <label htmlFor="chunkSize">Chunk Size: (characters)</label>
            <input
              type="number"
              id="chunkSize"
              value={chunkSize}
              style={{ width: "100px" }}
              onChange={(e) => setChunkSize(Number(e.target.value))}
            />

            <button onClick={splitText}>Split Text</button>
          </div>

          <div>
            <label htmlFor="textInput">Enter the text to be chunked:</label>
            <textarea
              value={textInput}
              onChange={(e) => setTextInput(e.target.value)}
              rows="10"
              style={{ width: "98%" }}
              id="textInput"
            />
          </div>

          <div>
            <h2>Output Text Chunks</h2>
            {textChunks.map((chunk, index) => (
              <div key={index} style={{ marginTop: "1rem" }}>
                <textarea
                  value={chunk}
                  readOnly
                  rows="3"
                  className={
                    selectedTextArea === index ? "selected-textarea" : ""
                  }
                  style={{ width: "98%" }}
                  onClick={() => handleTextAreaClick(index)} // Add the click handler here
                />
                {!isInModal ? (
                  <>
                    <button onClick={() => handleButtonTOCHatGPTClick(chunk)}>
                      Paste to ChatGPT
                    </button>
                  </>
                ) : (
                  <button onClick={() => copyToClipboard(chunk)}>Copy</button>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChunkText;
