import React, { useState, useEffect } from "react";
import { realtimeDb, ref, push, onValue, set, update } from "../../firebase";
import "../../components/styles/WebInfoCollector.css";

const HelpInputForm = () => {
  const [data, setData] = useState({});
  const [setActiveField] = useState("");
  const [newInfo, setNewInfo] = useState({
    title: "",
    text: "",
    imageURL: "",
    x: 0,
    y: 0,
    h: 0,
    w: 0,
    order: 0,
    scale: 100,
    textOffsetX: 0,
    textOffsetY: 150,
    dateCreated: new Date(),
    cantDismiss: false,
    lastUpdated: new Date(),
    videoURL: "",
    category: "",
  });
  const [isFocused, setIsFocused] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([""]);

  const infoRef = ref(realtimeDb, "help-items");

  const addInfo = () => {
    // Push the new information to Firebase
    const newInfoRef = push(infoRef);
    set(newInfoRef, {
      ...newInfo,
      dateCreated: new Date().toISOString(),
      lastUpdated: new Date().toISOString(),
    }).then(() => {
      // Update the local state with the newly added record
      setNewInfo({
        title: "",
        text: "",
        imageURL: "",
        x: 0,
        y: 0,
        h: 0,
        w: 0,
        order: 0,
        scale: 100,
        textOffsetX: 0,
        textOffsetY: 150,
        dateCreated: new Date(),
        cantDismiss: false,
        lastUpdated: new Date(),
        videoURL: "",
        category: "",
      });
    });
  };

  const handleChange = (e) => {
    setNewInfo({ ...newInfo, [e.target.name]: e.target.value });
  };

  const handleCategoryButtonClick = (category) => {
    setNewInfo({ ...newInfo, category });

    // Call formatDate function and set the title input value
    if (category === "Daily News") {
      const date = new Date(); // current date
      const formattedDate = formatDate(date); // "July 7th Daily News"
      setNewInfo({ ...newInfo, title: formattedDate, category: "Daily News" });
    }
  };

  const handleLinkButtonClick = () => {
    const { title, text } = newInfo;
    const linkText = title ? title : "Insert_a_title";
    const link = `<a href="insert_link" target="_blank">${linkText}</a>`;
    setNewInfo({ ...newInfo, text: `${text} ${link}` });
  };

  useEffect(() => {
    const infoRef = ref(realtimeDb, "help-items");

    // Fetch initial data from Firebase
    onValue(infoRef, (snapshot) => {
      const fetchedData = snapshot.val();
      if (fetchedData) {
        setData(fetchedData);

        // Get all categories
        const allCategories = [
          ...new Set(Object.values(fetchedData).map((item) => item.category)),
        ];
        setCategories(allCategories);
      }
    });
  }, [selectedCategory]);

  function formatDate(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = date.getDate();
    let daySuffix;

    if (day > 3 && day < 21) daySuffix = "th";
    switch (day % 10) {
      case 1:
        daySuffix = "st";
        break;
      case 2:
        daySuffix = "nd";
        break;
      case 3:
        daySuffix = "rd";
        break;
      default:
        daySuffix = "th";
    }

    const month = monthNames[date.getMonth()];

    return `${month} ${day}${daySuffix} Daily News`;
  }

  return (
    <div className="WebInfoCollector">
      {/* INPUT FORM FOR A NEW RECORD */}
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="flexRow width100 ">
          <button
            type="button"
            onClick={() => handleCategoryButtonClick("Video")}
          >
            Video
          </button>
          <button
            type="button"
            onClick={() => handleCategoryButtonClick("Information")}
          >
            Information
          </button>
          <button
            type="button"
            onClick={() => handleCategoryButtonClick("Daily News")}
          >
            Daily News
          </button>

          <input
            id="category"
            name="category"
            type="text"
            value={newInfo.category}
            placeholder="Category"
            onChange={handleChange}
          />

          <input
            id="title"
            name="title"
            type="text"
            value={newInfo.title}
            placeholder="Title"
            onChange={handleChange}
          />

          <label htmlFor="textOffsetX">X Offest:</label>
          <input
            id="textOffsetX"
            name="textOffsetX"
            type="number"
            value={newInfo.textOffsetX}
            placeholder=""
            onChange={handleChange}
          />

          <label htmlFor="textOffsetY">Y Offset:</label>
          <input
            id="textOffsetY"
            name="textOffsetY"
            type="number"
            value={newInfo.textOffsetY}
            placeholder=""
            onChange={handleChange}
          />

          <input
            id="imageURL"
            name="imageURL"
            type="text"
            value={newInfo.imageURL}
            placeholder="Image URL"
            onChange={handleChange}
          />

          <label htmlFor="scale">Scale:</label>
          <input
            id="scale"
            name="scale"
            type="number"
            value={newInfo.scale}
            placeholder="Scale"
            onChange={handleChange}
          />

          <label htmlFor="x">X:</label>
          <input
            id="x"
            name="x"
            type="number"
            value={newInfo.x}
            placeholder="X"
            onChange={handleChange}
          />

          <label htmlFor="y">Y:</label>
          <input
            id="y"
            name="y"
            type="number"
            value={newInfo.y}
            placeholder="Y"
            onChange={handleChange}
          />

          <label htmlFor="h">H:</label>
          <input
            id="h"
            name="h"
            type="number"
            value={newInfo.h}
            placeholder="Height"
            onChange={handleChange}
          />

          <label htmlFor="w">W:</label>
          <input
            id="w"
            name="w"
            type="number"
            value={newInfo.w}
            placeholder="Width"
            onChange={handleChange}
          />

          <label htmlFor="order">Order:</label>
          <input
            id="order"
            name="order"
            type="number"
            value={newInfo.order}
            placeholder="Order"
            onChange={handleChange}
          />

          <input
            id="videoURL"
            name="videoURL"
            type="text"
            value={newInfo.videoURL}
            placeholder="Video URL"
            onChange={handleChange}
          />

          <button onClick={addInfo}>Save</button>
          <button type="button" onClick={handleLinkButtonClick}>
            Insert Link
          </button>
        </div>

        <div>
          <textarea
            id="text"
            name="text"
            style={{
              width: "98%",
              height: isFocused ? "300px" : "20px",
              transition: "height 0.5s",
            }}
            value={newInfo.text}
            placeholder="Description text"
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </div>
      </form>
    </div>
  );
};

export default HelpInputForm;
