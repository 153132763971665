import { useEffect } from "react";
import { realtimeDb, ref, onValue } from "../../firebase";

function SyncButtons(props) {
  useEffect(() => {
    const buttonsRef = ref(realtimeDb, "buttons");
    onValue(buttonsRef, (snapshot) => {
      const data = snapshot.val();
      const buttonsArray = Object.values(data || {});
      syncButtonsToLocal(buttonsArray);
    });
  }, []);

  const syncButtonsToLocal = (buttons) => {
    const openRequest = indexedDB.open("myButtonDatabase", 5);

    openRequest.onupgradeneeded = function (e) {
      // ... (existing code)
    };

    openRequest.onsuccess = function (e) {
      const db = e.target.result;
      const transaction = db.transaction(
        ["buttons", "deletedButtons"],
        "readwrite"
      );
      const store = transaction.objectStore("buttons");

      // Get all buttons from the object store
      const getRequest = store.getAll();

      getRequest.onsuccess = function (e) {
        const allButtons = e.target.result;

        // Create a map of all buttons in the object store, indexed by id
        const buttonMap = {};
        for (const button of allButtons) {
          buttonMap[button.id] = button;
        }

        // Update the buttons in the object store
        for (const button of buttons) {
          // If the button is not marked as deleted in the object store, update it
          if (!buttonMap[button.id] || !buttonMap[button.id].deleted) {
            // If the button already exists in the object store, check the lastModified timestamp
            if (buttonMap[button.id]) {
              const localLastModified = new Date(
                buttonMap[button.id].lastModified || 0
              );
              const serverLastModified = new Date(button.lastModified || 0);

              // Only update the local button if the server button is newer
              if (serverLastModified > localLastModified) {
                store.put(button);
              }
            } else {
              // The button does not exist locally, so add it
              store.add(button);
            }
          }
        }

        // Inform the parent component that the buttons have been updated
        props.onButtonsUpdated(buttons);
      };
    };
  };
}

export default SyncButtons;
