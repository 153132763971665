import React, { useState } from "react";
import { Parser } from "json2csv";

const DownloadCSVButton = ({ data, buttonText }) => {
  const [filename, setFilename] = useState("download.csv");

  const convertToCSV = data => {
    const fields = [
      { label: "Sub-Niche", value: "niche" },
      { label: "Category", value: "category" },
      { label: "Sub-Category", value: "sub_category" },
      { label: "Title", value: "template" },
      { label: "Order", value: "order" },
      { label: "Description", value: "prompt_description" },
      { label: "Prompt", value: "prompt_text" },
      { label: "Buttons", value: "button" }
    ];

    const parser = new Parser({ fields });
    return parser.parse(data);
  };

  const downloadCSV = (csv, filename) => {
    let csvFile;
    let downloadLink;

    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  const downloadData = () => {
    const csvData = convertToCSV(Object.values(data));
    downloadCSV(csvData, filename);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <input
        type="text"
        value={filename}
        onChange={e => setFilename(e.target.value)}
        style={{ marginRight: "10px" }}
      />
      <button onClick={downloadData} style={{ marginRight: "10px" }}>
        {buttonText || "Download CSV"}
      </button>
    </div>
  );
};

export default DownloadCSVButton;
