import React, { useState, useEffect } from "react";
import CommunityLibrary from "./PageComponents/NicheSelector";
import DBAdmin from "./DBAdmin";
import TextChunker from "./PageComponents/ChunkText.js";
import ChatGPTHelper from "./ChatGPTHelper";
import ManageNiches from "./PageComponents/NicheSelector.js";
import SaveLibrary from "./SaveLibrary";
import WebInfoCollector from "./WebInfoCollector";
import MyLibrary from "./PageComponents/MyLibrary.js";
import CustomInstructions from "./PageComponents/CustomInstructions.js";
import QuickPick from "./PageComponents/QuickpickSelects.js";
//import HelperModal from "./HelperModal"; // Load this from localbrowser
import HelpInputForm from "./PageComponents/HelpInputForm";
import Hub from "./Hub";
import AdminHubButtons from "./AdminHubButtons";
import Login from "./Login";
import Account from "./Account";

import "../components/styles/AppWindows.css";

const DEFAULT_TAB_KEY = "CommunityLibrary";

const AppWindows = () => {
  const [activeTab, setActiveTab] = useState(() => {
    const initialTab = localStorage.getItem("activeTab") || DEFAULT_TAB_KEY;
    //console.log("Initial tab:", initialTab);
    return initialTab;
  });

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  const tabs = [
    {
      tab: "ChatGPT",
      key: "CommunityLibrary",
      label: "Comm Library",
      component: <CommunityLibrary />,
    },
    {
      tab: "ChatGPT",
      key: "MyLibrary",
      label: "My Library",
      component: <MyLibrary />,
    },
    {
      tab: "ChatGPT",
      key: "CustomInstructions",
      label: "Cust. Instr.",
      component: <CustomInstructions />,
    },
    {
      tab: "ChatGPT",
      key: "QuickPick",
      label: "Quick Pick",
      component: <QuickPick />,
    },
    {
      tab: "ChatGPT",
      key: "TextChunker",
      label: "Chunker",
      component: <TextChunker />,
    },

    {
      tab: "ChatGPT",
      key: "helper",
      label: "Helper",
      component: (
        <iframe
          //src="http://localhost:3000/helper_modal"
          src="https://app.mybrainonai.com/helper_modal"
          title="My iframe example"
          width="800"
          height="600"
          style={{ border: "none" }}
        />
      ),
    },
    {
      tab: "All",
      key: "Hub",
      label: "Hub",
      component: <Hub />,
    },
    {
      tab: "All",
      key: "SaveLibrary",
      label: "Save Library",
      component: <SaveLibrary />,
    },

    {
      tab: "Admin",
      key: "ManageNiches",
      label: "Niches",
      component: <ManageNiches />,
    },
    {
      tab: "Admin",
      key: "DBAdmin",
      label: "Community",
      component: <DBAdmin />,
    },

    {
      tab: "Admin",
      key: "AdminHubButtons",
      label: "Admin Hub",
      component: <AdminHubButtons />,
    },
    {
      tab: "Admin",
      key: "Login",
      label: "Login",
      component: <Login />,
    },
    {
      tab: "Admin",
      key: "Account",
      label: "Account",
      component: <Account />,
    },
    {
      tab: "Help",
      key: "WebInfoCollector",
      label: "Web Info Collector",
      component: <WebInfoCollector />,
    },
    {
      tab: "Help",
      key: "ChatGPTHelper",
      label: "Help",
      component: <ChatGPTHelper />,
    },
    {
      tab: "Help",
      key: "HelpInputForm",
      label: "Help Input",
      component: <HelpInputForm />,
    },
  ];

  const activeComponent = tabs.find((tab) => tab.key === activeTab)?.component;
  const tabCategories = [...new Set(tabs.map((tab) => tab.tab))];

  return (
    <div>
      <div className="flexRowNoWrap">
        {tabCategories.map((category) => (
          <div key={category}>
            {category}
            {tabs
              .filter((tab) => tab.tab === category)
              .map((tab) => (
                <button key={tab.key} onClick={() => setActiveTab(tab.key)}>
                  {tab.label}
                </button>
              ))}
          </div>
        ))}
      </div>
      <div className="section">
        {activeComponent}
        {activeTab === DEFAULT_TAB_KEY && <hr />}
      </div>{" "}
    </div>
  );
};
export default AppWindows;
