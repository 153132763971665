import React, { useState, useEffect } from "react";
import { realtimeDb, ref, onValue } from "../firebase";
import "../components/styles/helperModal.css";

const HelperModal = () => {
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [isOpen] = useState(true);
  // THIS IS THE DEFAULT MENU ITEM
  // IT IS HARD CODED TO IF YOU CHANGE THIS DB 'selectedCategory' IT WILL NOT BE SELECTED BY DEFAULT
  const [selectedCategory, setSelectedCategory] = useState("ChatGPT Help");
  const categories = [...new Set(data.map((item) => item.category))];
  const [activeCategory, setActiveCategory] = useState("ChatGPT Help");

  categories.sort((a, b) => {
    if (a === "ChatGPT Help") return -1;
    if (b === "ChatGPT Help") return 1;
    return a.localeCompare(b);
  });

  const handleCloseModal = () => {
    window.top.postMessage(
      {
        source: "myReactApp",
        type: "myMessageType",
        text: "closeHelp",
      },
      "https://chat.openai.com"
    );
  };

  // Filter the data to only include items with the selected category
  const filteredData = data.filter(
    (item) => item.category === selectedCategory
  );

  const currentItem = filteredData[currentIndex];
  const { title, text } = currentItem || {};
  /**
   * Helper function to handle image navigation and message sending
   * @param {Number} index - The index of the image in the filteredData array
   * @param {String} type - The type of message to send ("moveImage", "firstImage", etc.)
   */
  const handleImageNavigation = (index, type) => {
    const { id, x, y, w, h, imageURL, scale } = filteredData[index];

    const message = {
      source: "myReactApp",
      type: type,
      id,
      x,
      y,
      w,
      h,
      imgURL: imageURL,
      scale,
    };

    // Post message to the top-level window
    window.top.postMessage(message, "https://chat.openai.com");
  };

  // Handle the next image
  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % filteredData.length;
      handleImageNavigation(newIndex, "moveImage");
      return newIndex;
    });
  };

  // Handle the first image
  const handleFirst = () => {
    if (filteredData.length > 0) {
      handleImageNavigation(0, "firstImage");
      setCurrentIndex(0);
    }
  };

  // Handle the previous image
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = Math.max(0, prevIndex - 1);
      handleImageNavigation(newIndex, "moveImage");
      return newIndex;
    });
  };

  useEffect(() => {
    // Create a reference to the 'help-items' collection in the database
    const dbRef = ref(realtimeDb, "help-items");
    onValue(dbRef, (snapshot) => {
      // Get the data from the snapshot
      const data = snapshot.val();
      // If there is data...
      if (data) {
        // Map over the data to create a new array
        const sortedData = Object.values(data)
          .map((item, index) => {
            // Get the data from the database
            return {
              id: index,
              ...item,
              x: Number(item.x),
              y: Number(item.y),
              w: Number(item.w),
              h: Number(item.h),
              textOffsetX: Number(item.textOffsetX),
              textOffsetY: Number(item.textOffsetY),
              scale: Number(item.scale),
              cantDismiss: item.cantDismiss || false,
              lastUpdated: item.lastUpdated || new Date(),
            };
          })
          // Sort the new array by the 'order' property if it's greater than 0,
          // otherwise sort by the 'dateCreated' property in descending order
          .sort((a, b) => {
            if (a.order > 0 && b.order > 0) {
              return a.order - b.order;
            } else if (a.order > 0) {
              return -1;
            } else if (b.order > 0) {
              return 1;
            } else {
              return new Date(b.dateCreated) - new Date(a.dateCreated);
            }
          });
        setData(sortedData);
        handleFirst(sortedData);
      }
    });
  }, []);

  // @@@ HANDLE CHANGING CATEGORIES
  useEffect(() => {
    setCurrentIndex(0);
    handleFirst(data);
  }, [selectedCategory]);

  useEffect(() => {
    if (data.length > 0) {
      handleFirst(data);
    }
  }, [data]);

  if (!data.length) {
    return <div>Loading...</div>;
  }

  if (!isOpen) {
    return null; // Don't render anything if the modal is closed
  }

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    handleFirst(data);
    setCurrentIndex(0);
    setActiveCategory(category);
  };

  return (
    <div>
      <div className="flex-container">
        <div className="category-list">
          {categories.map((category, index) => (
            <div
              key={index}
              className={activeCategory === category ? "selected" : ""}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </div>
          ))}
        </div>

        <div className="text-blob">
          <div className="ztitle">{title}</div>

          <div className="vhtml" dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </div>

      <div className="userControls">
        <button onClick={handleNext}>Next</button>
        &nbsp; [ {currentIndex + 1} of {filteredData.length} ] &nbsp;
        {currentIndex > 0 && (
          <button onClick={handlePrevious}>Previous</button>
        )}{" "}
        <button onClick={handleCloseModal}>Close</button>
      </div>
    </div>
  );
};

export default HelperModal;
