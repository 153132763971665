import React, { useState, useEffect } from "react";
import { realtimeDb, ref, set, push, onValue, update, get } from "../firebase";
import UploadCSV from "../components/UploadCSV";
import DownloadCSVButton from "../components/DownloadToCSV";

const DBExperts = () => {
  const [data, setData] = useState({
    sub_category: [],
    category: [],
    template: [],
    niche: [],
    order: [],
    prompt_description: [],
    prompt_text: [],
    button: [],
  });

  const [category, setCategory] = useState("");
  const [newSubCategory, setNewSubCategory] = useState("");
  const [newTemplate, setNewTemplate] = useState("");
  const [newNiche, setNewNiche] = useState("");
  const [newPromptDescription, setNewPromptDescription] = useState("");
  const [NewPromptValue, setNewPromptValue] = useState("");
  const [button] = useState("");
  const [order, setOrder] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [showInputs, setShowInputs] = useState(true);
  const [filterCategory, setFilterCategory] = useState("");
  const [niches, setNiches] = useState([]);
  const [expertData, setExpertData] = useState([]);
  const [showNiches, setShowNiches] = useState(false);

  // Fetch niches
  useEffect(() => {
    const nichesRef = ref(realtimeDb, "niches");
    onValue(nichesRef, (snapshot) => {
      const data = snapshot.val();
      const nichesArray = Object.entries(data || {}).map(([id, niche]) => ({
        id,
        ...niche,
      }));
      // console.log("Niches array:", nichesArray);
      setNiches(nichesArray);
    });
  }, []);

  // Fetch expert data
  useEffect(() => {
    const expertInfoRef = ref(realtimeDb, "expert_information");
    onValue(expertInfoRef, (snapshot) => {
      const data = snapshot.val();
      // console.log("Expert data:", data);
      setData(data);

      const expertDataArray = Object.entries(data || {}).map(
        ([id, expert]) => ({
          id,
          ...expert,
        })
      );
      // console.log("Expert data array:", expertDataArray);
      setExpertData(expertDataArray);
    });
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [unusedNichesPerCategory, setUnusedNichesPerCategory] = useState({});

  useEffect(() => {
    if (niches.length > 0 && expertData.length > 0) {
      // Create an empty array to hold unused niches for each expert category
      let unusedNichesPerCategory = {};

      // Iterate over each expert data
      expertData.forEach((expert) => {
        // Filter niches to find those that do not include the expert's category
        const unusedNichesForThisCategory = niches.filter(
          (niche) => !niche.categories.includes(expert.category)
        );

        // Add these unused niches to the unusedNichesPerCategory object
        unusedNichesPerCategory[expert.category] = unusedNichesForThisCategory;
      });

      // console.log("Setting unusedNichesPerCategory:", unusedNichesPerCategory);
      setUnusedNichesPerCategory(unusedNichesPerCategory);
    }
  }, [niches, expertData]);

  // 10 ADD RECORD
  const addRecord = () => {
    const newSubCategoryRef = push(ref(realtimeDb, "expert_information"));
    set(newSubCategoryRef, {
      category: category,
      sub_category: newSubCategory,
      template: newTemplate,
      niche: newNiche,
      prompt_description: newPromptDescription,
      prompt_text: NewPromptValue,
      button: button,
      order: parseInt(order) || 0,
    });
  };

  // 20 UPDATE RECORD
  const updateRecord = (
    id,
    category = null,
    sub_category = "",
    template = "",
    niche = "",
    order = 0,
    prompt_description = "",
    prompt_text = "",
    button = null
  ) => {
    const recordRef = ref(realtimeDb, `expert_information/${id}`);
    update(recordRef, {
      category,
      sub_category,
      template,
      niche,
      order: parseInt(order) || 0,
      prompt_description,
      prompt_text,
      button,
    });
  };

  // 30 DELETE RECORD
  const deleteRecord = (id) => {
    const recordRef = ref(realtimeDb, `expert_information/${id}`);
    set(recordRef, null);
  };

  // 40 DELETE ALL RECORDS
  const deleteAllRecords = () => {
    const password = window.prompt(
      "Please enter the password to delete all records:"
    );
    if (password === "11qqaaZZ") {
      Object.entries(data).forEach(([id, _]) => {
        const recordRef = ref(realtimeDb, `expert_information/${id}`);
        set(recordRef, null);
      });
    } else {
      alert("Incorrect password. Records not deleted.");
    }
  };

  // 50 SEARCH
  const filteredData = () => {
    if (!searchTerm) {
      return Object.entries(data);
    }

    return Object.entries(data).filter(([_, record]) => {
      const recordValues = Object.values(record);
      return recordValues.some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  // 55 GET UNIQUE CATEGORY VALUES
  const getUniqueCategories = () => {
    if (!data) {
      return [];
    }

    const categories = Object.values(data).map((record) => record.category);
    return [...new Set(categories)];
  };

  const handleDuplicate = (record) => {
    const newRecord = { ...record };
    delete newRecord.id; // Remove the id, so that a new one will be generated when pushed to the database

    // Add the duplicated record to the database
    push(ref(realtimeDb, "expert_information"), newRecord)
      .then((newRecordRef) => {})
      .catch((error) => {});
  };

  const getNichesForExpert = (category) => {
    return niches
      .filter((niche) => niche.categories.includes(category))
      .map((niche) => (
        <button
          key={niche.id}
          onClick={() => removeCategoryFromNiche(niche.id, category)}
        >
          {niche.name}
        </button>
      ));
  };
  // const ExpertRow = ({ expert, handleDeleteExpert, niches }) => {
  //   const getNichesForExpert = (expertCategory) => {
  //     return niches
  //       .filter(
  //         (niche) =>
  //           niche.categories && niche.categories.includes(expertCategory)
  //       )
  //       .map((niche) => niche.name)
  //       .join(", ");
  //   };

  //   return (
  //     <div className="inline-row">
  //       <p>Niches: {getNichesForExpert(expert.category)}</p>
  //       <button onClick={() => handleDeleteExpert(expert.id)}>Delete</button>
  //     </div>
  //   );
  // };

  // const handleDeleteExpert = (expertId, category) => {
  //   // Deleting the expert from the expert_information
  //   const expertRef = ref(realtimeDb, `expert_information/${expertId}`);
  //   set(expertRef, null);

  //   // Now, we will delete the expert from each niche it is part of.
  //   // We will iterate over all the niches.
  //   niches.forEach((niche) => {
  //     // Check if the niche has the category of the expert to be deleted
  //     if (niche.categories && niche.categories.includes(category)) {
  //       // The expert's category is present in this niche, so we have to delete it.
  //       // To do that, we create a new list of categories that doesn't include the expert's category
  //       const newCategoriesList = niche.categories.filter(
  //         (cat) => cat !== category
  //       );

  //       // Now we update this niche's categories in the database
  //       const nicheRef = ref(realtimeDb, `niches/${niche.id}`);
  //       update(nicheRef, {
  //         name: niche.name,
  //         categories: newCategoriesList,
  //       });
  //     }
  //   });
  // };
  const isNicheUsed = (niche, expertDataArray) => {
    for (let i = 0; i < expertDataArray.length; i++) {
      if (niche.categories.includes(expertDataArray[i].category)) {
        return true;
      }
    }
    return false;
  };

  const addCategoryToNiche = async (nicheId, category) => {
    const nicheRef = ref(realtimeDb, `niches/${nicheId}`);
    const snapshot = await get(nicheRef);
    const niche = snapshot.val();
    const updatedCategories = [...niche.categories, category];
    await update(nicheRef, { categories: updatedCategories });
  };

  const removeCategoryFromNiche = async (nicheId, category) => {
    const nicheRef = ref(realtimeDb, `niches/${nicheId}`);
    const snapshot = await get(nicheRef);
    const nicheData = snapshot.val();

    if (nicheData && nicheData.categories) {
      const updatedCategories = nicheData.categories.filter(
        (cat) => cat !== category
      );
      await update(nicheRef, { categories: updatedCategories });
    }
  };

  return (
    <div>
      <div className="toggleInputsContainer">
        <h2>Input Expert Data</h2>
        {/* CHECKBOX TO SHOW OR HIDE THE DATA */}
        <div className="check_show_hide_form">
          <input
            id="toggleInputs"
            type="checkbox"
            className="checkbox"
            checked={showInputs}
            onChange={(e) => setShowInputs(e.target.checked)}
          />
          <label id="checkbox-show-hide" htmlFor="toggleInputs">
            Show/Hide "Input Expert Data"
          </label>
        </div>
        {/* UPLOAD CSV   NOTE -  CONDITIONALLY HIDE IT */}
        <div style={{ display: showInputs ? "block" : "none" }}>
          <UploadCSV />
        </div>
      </div>

      {/* EXPERT INPUT FORM     NOTE - CONDITIONALLY HIDE IT */}
      <form
        onSubmit={(e) => e.preventDefault()}
        style={{ display: showInputs ? "block" : "none" }}
      >
        {/* FORM CONTAINER */}

        <div className="expert-input-flex">
          <div className="row">
            <div className="column1">
              {/* CATEGORY INPUT */}
              <div className="input-container">
                <input
                  className="niche"
                  type="text"
                  placeholder="Niche"
                  value={newNiche}
                  onChange={(e) => setNewNiche(e.target.value)}
                />
                <input
                  className="category"
                  type="text"
                  placeholder="Category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />
                {/* SUBCATEGORY INPUT */}
                <input
                  className="expert-type"
                  type="text"
                  value={newSubCategory}
                  placeholder="Sub-Category"
                  onChange={(e) => setNewSubCategory(e.target.value)}
                />
                {/* TEMPLATE INPUT */}
                <input
                  type="text"
                  placeholder="Title"
                  value={newTemplate}
                  onChange={(e) => setNewTemplate(e.target.value)}
                />
                <div className="order-add-button">
                  {/* SORT ORDER INPUT */}
                  <input
                    className="order"
                    type="number"
                    placeholder="Order"
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                  />
                  <button onClick={addRecord}>Add</button>
                </div>
              </div>
            </div>
            <div className="prompt-input-description">
              <textarea
                rows={3}
                type="text"
                value={newPromptDescription}
                placeholder="Prompt Description"
                onChange={(e) => setNewPromptDescription(e.target.value)}
              />
              <textarea
                rows={6}
                type="text"
                placeholder="Prompt Text"
                value={NewPromptValue}
                onChange={(e) => setNewPromptValue(e.target.value)}
              />
            </div>
          </div>
        </div>
      </form>

      {/* ================================ TOP OF DATA TABLE =============================== */}

      <div className="table-data-heading">
        <h2>Existing Records</h2> {/* DELETE ALL RECORDS!! */}
        <button className="deleteAll" onClick={deleteAllRecords}>
          Delete All Records
        </button>
        <div className="expert_download_box">
          <DownloadCSVButton data={data} />
        </div>
      </div>
      <hr />
      <div className="top-row-search-sort-categories">
        {/* SELECT RECORDS */}
        <div className="slider_niches">
          <label className="switch">
            {/* <label> */}
            <input
              type="checkbox"
              checked={showNiches}
              onChange={(e) => setShowNiches(e.target.checked)}
            />
            <span className="slider"></span>
          </label>
          <label>{showNiches ? "Hide Niches" : "Show Niches"}</label>
        </div>
        <label>Sort</label>
        <select
          className="sort_select"
          value={filterCategory}
          onChange={(e) => setFilterCategory(e.target.value)}
        >
          <option key="all" value="">
            All Categories
          </option>
          {getUniqueCategories().map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </select>
        {/* SEARCH */}
        <label htmlFor="search">Search:</label>
        <input
          id="search"
          type="text"
          placeholder="Search records..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="category-buttons">
          {getUniqueCategories().map((category, index) => (
            <button key={index} onClick={() => setSearchTerm(category)}>
              {category}
            </button>
          ))}
        </div>
      </div>

      {/* DATA IN TABLE */}
      {data ? (
        <div className="">
          <div className="myTable alternating-rows">
            {/* FILTER THE RECORD */}
            {filteredData()
              .filter(
                ([_, record]) =>
                  !filterCategory || record.category === filterCategory
              )
              .map(([id, record]) => {
                // Calculate unused niches for this record
                const unusedNichesForThisRecord = niches.filter(
                  (niche) => !isNicheUsed(niche, [record])
                );

                return (
                  <div key={id} className="table_row">
                    {/* UNUSED NICHES */}
                    {showNiches && (
                      <div className="niche_data_buttons">
                        <div>
                          <ul className="unused_niche_buttons">
                            <h2>Unused Niches: </h2>
                            {unusedNichesForThisRecord.map((niche) => (
                              <li key={niche.id}>
                                <button
                                  onClick={() =>
                                    addCategoryToNiche(
                                      niche.id,
                                      record.category
                                    )
                                  }
                                >
                                  {niche.name}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div key={category}>
                          <ul className="used_niche_buttons">
                            <h2>&nbsp;&nbsp;&nbsp; Used Niches: </h2>{" "}
                            {getNichesForExpert(record.category)}
                          </ul>
                        </div>
                      </div>
                    )}
                    {/* CATEGORY */}
                    <div className="prompts_row_record">
                      <div className="input_area">
                        <div className="label_input_wrapper">
                          <label className="info">Category</label>
                          <input
                            type="text"
                            value={record.category || ""}
                            onChange={(e) =>
                              updateRecord(
                                id,
                                e.target.value,
                                record.sub_category,
                                record.template,
                                record.niche,
                                record.order,
                                record.prompt_description,
                                record.prompt_text,
                                record.button
                              )
                            }
                          />
                        </div>
                        <div className="label_input_wrapper">
                          <label className="info">Sub-Category</label>
                          <input
                            type="text"
                            value={record.sub_category}
                            onChange={(e) =>
                              updateRecord(
                                id,
                                record.category,
                                e.target.value,
                                record.template,
                                record.niche,
                                record.order,
                                record.prompt_description,
                                record.prompt_text,
                                record.button
                              )
                            }
                          />
                        </div>
                        <div className="label_input_wrapper">
                          <label className="info">Title</label>
                          <input
                            type="text"
                            value={record.template}
                            onChange={(e) =>
                              updateRecord(
                                id,
                                record.category,
                                record.sub_category,
                                e.target.value,
                                record.niche,
                                record.order,
                                record.prompt_description,
                                record.prompt_text,
                                record.button
                              )
                            }
                          />
                        </div>
                        {/* NICHE */}
                        <div className="label_input_wrapper">
                          <label className="info">Niche</label>
                          <input
                            type="text"
                            value={record.niche}
                            onChange={(e) =>
                              updateRecord(
                                id,
                                record.category,
                                record.sub_category,
                                record.template,
                                e.target.value,
                                record.order,
                                record.prompt_description,
                                record.prompt_text,
                                record.button
                              )
                            }
                          />
                        </div>
                        {/* ORDER DELETE DUPLCATE */}
                        <div className="multi_label_wrapper">
                          <label className="info">Order</label>
                          <input
                            type="number"
                            value={record.order || 0}
                            onChange={(e) =>
                              updateRecord(
                                id,
                                record.category,
                                record.sub_category,
                                record.template,
                                record.niche,
                                e.target.value,
                                record.prompt_description,
                                record.prompt_text,
                                record.button
                              )
                            }
                          />

                          <div>
                            <button
                              onClick={() => handleDuplicate(record)}
                              type="button"
                              className="btn btn-outline-secondary"
                            >
                              Duplicate
                            </button>
                            <button
                              className="delete_button"
                              onClick={() => deleteRecord(id)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* PROMPT DESCRIPTION */}
                      <div className="description_and_prompt">
                        <label className="info">Description</label>{" "}
                        <textarea
                          rows={3}
                          value={record.prompt_description}
                          onChange={(e) =>
                            updateRecord(
                              id,
                              record.category,
                              record.sub_category,
                              record.template,
                              record.niche,
                              record.order,
                              e.target.value,
                              record.prompt_text,
                              record.button
                            )
                          }
                          style={{ width: "98%" }}
                        />
                        {/* PROPMT */}
                        <label className="info">Prompt</label>
                        <textarea
                          rows={5}
                          value={record.prompt_text}
                          onChange={(e) =>
                            updateRecord(
                              id,
                              record.category,
                              record.sub_category,
                              record.template,
                              record.niche,
                              record.order,
                              record.prompt_description,
                              e.target.value,
                              record.button
                            )
                          }
                          style={{ width: "98%" }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            {/* SPACER */}
          </div>
        </div>
      ) : (
        <p>The database is blank.</p>
      )}
    </div>
  );
};

export default DBExperts;
