import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStop } from "@fortawesome/free-solid-svg-icons";

const StopSpeakButton = React.memo(() => {
  const stopSpeaking = (event) => {
    // Add event here
    event.preventDefault();
    event.stopPropagation();
    window.speechSynthesis.cancel();
  };

  return (
    <button onClick={stopSpeaking} className="speak-button">
      <FontAwesomeIcon icon={faStop} />
    </button>
  );
});

export default StopSpeakButton;
