import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

const SpeakButton = ({ text }) => {
  const speakText = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const rate = parseFloat(localStorage.getItem("speedRate") || "1"); // Retrieve from localStorage with a default value of 1
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.rate = rate; // Set the rate of the utterance
    // console.log("SpeakButton", rate)
    window.speechSynthesis.speak(utterance);
  };

  return (
    <button onClick={speakText} className="speak-button">
      <FontAwesomeIcon icon={faPlay} />
    </button>
  );
};

export default SpeakButton;
