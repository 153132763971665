import React, { useState, useEffect } from "react";

import "../../components/styles/myLibrary.css";
import SyncButtons from ".././ButtonComponents/SyncButtons";
import AreaDropdowns from ".././ButtonComponents/AreaDropdowns";
import QuickPicInstructions from ".././PageComponents/QuickPickInstructions";
import QuickPickButtons from ".././PageComponents/QuickpickButtons";
import QuickPickEdits from ".././PageComponents/QuickpickEdits";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

function QuickPickSelects() {
  const [buttons, setButtons] = useState([]);
  const [showInstructions, setShowInstructions] = useState(false);
  const [activeSection, setActiveSection] = useState(null);

  const toggleInstructions = () => {
    setShowInstructions((prev) => !prev);
  };

  const toggleSection = (section) => {
    setActiveSection((prev) => (prev === section ? null : section));
  };

  useEffect(() => {
    fetchButtonsFromLocal();
  }, []);

  const handleButtonsUpdated = () => {
    // Refresh the buttons
    fetchButtonsFromLocal();
  };

  const fetchButtonsFromLocal = () => {
    const openRequest = indexedDB.open("myButtonDatabase", 5);

    openRequest.onupgradeneeded = function (e) {
      const db = e.target.result;

      // Create the object stores if they don't exist
      if (!db.objectStoreNames.contains("buttons")) {
        db.createObjectStore("buttons", { keyPath: "id", autoIncrement: true });
      }

      if (!db.objectStoreNames.contains("deletedButtons")) {
        db.createObjectStore("deletedButtons", {
          keyPath: "id",
          autoIncrement: true,
        });
      }
    };

    openRequest.onsuccess = function (e) {
      const db = e.target.result;
      const transaction = db.transaction(
        ["buttons", "deletedButtons"],
        "readonly"
      );
      const store = transaction.objectStore("buttons");
      const deletedStore = transaction.objectStore("deletedButtons");

      const getRequest = store.getAll();
      const getDeletedRequest = deletedStore.getAll();

      getRequest.onsuccess = function (e) {
        const allButtons = e.target.result;

        getDeletedRequest.onsuccess = function (e) {
          const deletedButtons = e.target.result;
          const deletedButtonIds = deletedButtons.map((button) => button.id);

          const nonDeletedButtons = allButtons.filter(
            (button) => !deletedButtonIds.includes(button.id)
          );

          // Sort the buttons alphabetically by the 'text' property
          nonDeletedButtons.sort((a, b) => {
            // Sort by frequency in descending order
            if (a.frequency > b.frequency) return -1;
            if (a.frequency < b.frequency) return 1;

            return a.text.localeCompare(b.text);
          });

          setButtons(nonDeletedButtons);
        };
      };
    };
  };

  const handleButtonClick = (button) => {
    // Open the database
    const openRequest = indexedDB.open("myButtonDatabase", 5);

    openRequest.onsuccess = function (e) {
      const db = e.target.result;
      const transaction = db.transaction("buttons", "readwrite");
      const store = transaction.objectStore("buttons");
      const getRequest = store.get(button.id);

      getRequest.onsuccess = function (e) {
        const dbButton = e.target.result;

        if (!dbButton.hasOwnProperty("frequency")) {
          dbButton.frequency = 0;
        }

        dbButton.frequency += 1;
        const putRequest = store.put(dbButton);
        putRequest.onsuccess = function () {
          fetchButtonsFromLocal(); // Refresh the buttons
        };
      };
    };

    const message = {
      source: "myReactApp",
      type: "sendToChatGPT",
      text: button.text + " ",
    };
    window.top.postMessage(message, "https://chat.openai.com");
  };

  return (
    <div>
      <div style={{ position: "relative" }}>
        <FontAwesomeIcon
          icon={faInfoCircle}
          style={{
            position: "absolute",
            top: "10px",
            right: "0px",
            cursor: "pointer",
          }}
          onClick={toggleInstructions}
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <button
            onClick={() => toggleSection("QuickPickButtons")}
            style={{
              backgroundColor:
                activeSection === "QuickPickButtons" ? "darkgreen" : "#4CAF50",
              color: activeSection === "QuickPickButtons" ? "white" : "white",
              border: "1px solid darkgreen",
              borderRadius: "4px",
              padding: "5px",
            }}
          >
            Toggle Buttons
          </button>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <button
            onClick={() => toggleSection("QuickPickEdits")}
            style={{
              backgroundColor:
                activeSection === "QuickPickEdits" ? "darkgreen" : "#4CAF50",
              color: activeSection === "QuickPickEdits" ? "white" : "white",
              border: "1px solid darkgreen",
              borderRadius: "4px",
              padding: "5px",
            }}
          >
            Toggle Edits
          </button>
        </div>
        {activeSection !== "QuickPickButtons" &&
          activeSection !== "QuickPickEdits" && (
            <h1>Select from the 6 categories to create a prompt</h1>
          )}
        {activeSection === "QuickPickButtons" && (
          <h1>
            Click the buttons in the 6 categories to create a prompt in ChatGPT
          </h1>
        )}
        {activeSection === "QuickPickEdits" && (
          <h1>Quick Pick Edits - Edit, Add and Delete Quick Pick Buttons</h1>
        )}
      </div>

      {activeSection === "QuickPickButtons" && (
        <div>
          <QuickPickButtons />
        </div>
      )}

      {activeSection === "QuickPickEdits" && (
        <div>
          <QuickPickEdits />
        </div>
      )}

      {showInstructions ? (
        <div>
          <QuickPicInstructions />
        </div>
      ) : (
        <div>
          <SyncButtons onButtonsUpdated={handleButtonsUpdated} />
          {activeSection !== "QuickPickButtons" &&
            activeSection !== "QuickPickEdits" && (
              <AreaDropdowns
                buttons={buttons}
                onOptionClick={handleButtonClick}
              />
            )}
        </div>
      )}
    </div>
  );
}

export default QuickPickSelects;
