import React, { useState } from "react";
import "../components/styles/Register.css";
import {
  auth,
  // googleAuthProvider,
  createUserWithEmailAndPassword,
  updateProfile, // Add this import
} from "../firebase";

import { useNavigate } from "react-router-dom";
// import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleEmailPasswordSignUp = async (e) => {
    e.preventDefault();

    // verify reCAPTCHA token
    // console.log("Debug: reCAPTCHA Token is", captcha);

    if (email === "" || password === "" || name === "" || captcha === "") {
      setErrorMessage("Please fill out all fields.");
    } else {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        await updateProfile(user, { displayName: name });
        navigate("/"); // Add this line to navigate to the home page after successful registration
      } catch (error) {
        // the 'email-already-in-use' error specifically
        if (error.code === "auth/email-already-in-use") {
          setErrorMessage(
            "The email address is already in use by another account."
          );
        } else {
          //  other errors
          setErrorMessage(
            "Error signing up. Please try again. " + error.message
          );
        }
      }
    }
  };

  return (
    <div className="register-form">
      <div className="register-container">
        <h2>Register here for an account on MyBrainOnAI.Com</h2>
        <h4></h4>
        <form onSubmit={handleEmailPasswordSignUp}>
          <div>
            <label>Email:</label>
            <input
              className="register-input"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email"
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              className="register-input"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
            />
          </div>
          <div>
            <label>Name:</label>
            <input
              className="register-input"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter name"
            />
          </div>
          {/* <div>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={(value) => setCaptcha(value)}
            />
          </div> */}
          <div>
            <button className="register-button" type="submit">
              Register
            </button>
          </div>
          {errorMessage && <p className="register-error">{errorMessage}</p>}
        </form>
      </div>
    </div>
  );
};

export default Register;
