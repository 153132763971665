import React from "react";
import "../../components/styles/QuickPick.css";
import useDocumentTitle from ".././PageComponents/useDocumentTitle";
import Accordion from "../../components/Accordion";
import SpeakButton from "../../components/SpeakButton";
import StopSpeakButton from "../../components/StopSpeakButton";

function QuickPickInstructions() {
  useDocumentTitle("Quick Pick - Instructions");

  const content = `
1. Task: It should start with an action verb, clearly delineating the desired outcome, which could range from a straightforward to a multi-step task.
2. Context: Context should ideally answer three questions. - 1. Detailing the users background. or 2. Defining what success looks like. or 3. Describing the environment they are in.
3. Examples: This refers to incorporating examples in the prompt to enhance the output quality. It could involve following a defined structure or referencing a real-world example.
4. Persona: This component involves identifying a persona who would ideally help with the task at hand, even specifying renowned individuals or fictional characters if suitable.
5. Format: Visualizing the end result helps in defining the exact format needed for the output, which could be tables, paragraphs in markdown, bullet points, etc.
6. Tone: Specifying the tone for the output could involve using precise adjectives or describing the feeling aimed to be evoked.
`;

  return (
    <div>
      <h1>Quick Pick Instructions</h1>
      <Accordion
        title="Quick Pick Selects"
        content={
          "<strong>Quick Pick Selects</strong> - These 6 Selects let you create prompts using 6 elements of a great prompt. When you select an item it is put into the chatGPT input area where you can further customize your new prompt.<br />" +
          "<br /><strong>Note</strong> - As buttons are used more often they will be sorted by use and they will change colors so you can identify them more quickly."
        }
      />
      <Accordion
        title="Quick Pick Buttons"
        content={
          "<strong>Quick Pick Buttons</strong> - These buttons are divided into the same six areas as you scroll down the page. When you click on a button it is put into the chatGPT input area where you can further customize your new prompt.<br />" +
          "<br /><strong>Note</strong> - As buttons are used more often they will be sorted by use and they will change colors so you can identify them more quickly.<br />"
        }
      />
      <Accordion
        title="Edit Quick Picks"
        content={
          "<strong>Edit Quick Picks</strong> - The button area and text can be modified here. Also new buttons can be created and existing buttons deleted.<br />" +
          "<br /><strong>Note</strong> - Changes here affect both the Quick Pick Buttons and the Quick Pick Selects.<br />"
        }
      />
      <div className="fancy_text">
        1. <strong></strong>Task: It should start with an action verb, clearly
        delineating the desired outcome, which could range from a
        straightforward to a multi-step task. <br />
        2. Context: Context should ideally answer three questions 1. Detailing
        the users background. Or 2. Defining what success looks like. Or 3.
        Describing the environment they are in. <br />
        3. Example: This refers to incorporating examples in the prompt to
        enhance the output quality. It could involve following a defined
        structure or referencing a real-world example.
        <br />
        4. Persona: This component involves identifying a persona who would
        ideally help with the task at hand, even specifying renowned individuals
        or fictional characters if suitable.
        <br />
        5. Format: Visualizing the end result helps in defining the exact format
        needed for the output, which could be tables, paragraphs in markdown,
        bullet points, etc. <br />
        6. Tone: Specifying the tone for the output could involve using precise
        adjectives or describing the feeling aimed to be evoked.
        <SpeakButton text={content} />
        <StopSpeakButton />
      </div>
      <br />
      <div style={{ textAlign: "center" }}>
        <h3>
          <b>
            8:29 video by Jeff Su - Master the Perfect ChatGPT Prompt Formula
          </b>
        </h3>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          height: "100vh",
        }}
      >
        <iframe
          title="6 section prompt information"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/jC4v5AS4RIM"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ border: "none", maxWidth: "100%", maxHeight: "100%" }}
        ></iframe>
      </div>
    </div>
  );
}

export default QuickPickInstructions;
