import React, { useState, useEffect } from "react";
import { realtimeDb, ref, set, push, get, update } from "../firebase";
import "../components/styles/ChatGPT_helper.css";
import MouseTracker from "./PageComponents/MouseTracker";

const ChatGPTHelper = () => {
  const images = [
    "https://drive.google.com/uc?id=132clPbUP_2LMAB6GiIbsZBnIFEAZXUIA",
    "https://drive.google.com/uc?id=1WhewcdoyQGV_jtydWgZ65mFA2_eTcKmb",
    "https://drive.google.com/uc?id=185u4MWmSHH8_i4f3S4aoPvNq0IVPWhlg",
    "https://drive.google.com/uc?id=13nfz4xf2ThIgm1MARSpvAWTiKzelwOzQ",
    "https://drive.google.com/uc?id=16tXKPObHa_XZDuO4jdpR_8ARo8HyyT0l",
    "https://drive.google.com/uc?id=1UOcEBLNcXrBAukV3lMBAC-PvRDn--cSD",
    "https://drive.google.com/uc?id=1-yiO0S_IoutwlgyzzvExYMzyHQhrYvbG",
    "https://drive.google.com/uc?id=1w0h3dJPh2a7ip7IznhwV0dDGnQYa-gaY",
    "https://drive.google.com/uc?id=1ap0HaxuEp81fKrhrbedhU6RPLtOGAcMh",
    "https://drive.google.com/uc?id=11niy81zkMFoa9HPqqyvE831q2kQ23YcZ",
    "https://drive.google.com/uc?id=1Ne2B6eRR2QqSnYcMbjSnl90kE9lHxCy0",
    "https://drive.google.com/uc?id=1abfAwSeFg-co2HQ5DVtvkTx4c9mAyzNS",
    "https://drive.google.com/uc?id=1zizVlwvvRbG1lHKUOlB3UOkHfrSC9TCi",
    "https://drive.google.com/uc?id=1V3R2Tr02L3iRhebdVO_74ErjfRopf-IU",
    "https://drive.google.com/uc?id=1cRiDxqI_jB0pF7KWQpRHcz7b87nzIv6_",
    "https://drive.google.com/uc?id=1vdzDXOvkwByUOq2bFj4zwErwf7qEXGWl",
    "https://drive.google.com/uc?id=102m6lLVcbHA0qa3HKn0WQoOLDCl1RqEx",
    "https://drive.google.com/uc?id=1dZivnnSpWXNmffv5kXzJBgZuuchA0n4u",
  ];

  const [data, setData] = useState({});
  const [newInfo, setNewInfo] = useState({
    title: "",
    text: "",
    imageURL: "",
    x: 0,
    y: 0,
    h: 0,
    w: 0,
    order: 0,
    scale: 100,
    textOffsetX: 0,
    textOffsetY: 150,
    dateCreated: new Date(),
    cantDismiss: false,
    lastUpdated: new Date(),
    videoURL: "",
    category: "",
  });
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [savedPosition, setSavedPosition] = useState({ x: 0, y: 0 });
  const [isTracking, setIsTracking] = useState(false);

  const [activeField, setActiveField] = useState(null);
  // const [activeRowKey, setActiveRowKey] = useState(null); // or useState('some default key')
  const [showTrackingControls, setShowTrackingControls] = useState(false);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [pendingUpdates, setPendingUpdates] = useState({});
  const [showMouseTracking, setShowMouseTracking] = useState(false);
  const [showImageButtons, setShowImageButtons] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  // MOUSE TRACKER CODE
  const updateRecord = (key, data) => {
    handleRowChange(key, "x", data.x);
    handleRowChange(key, "y", data.y);
    handleRowChange(key, "w", data.w);
    handleRowChange(key, "h", data.h);
  };

  // CREATE A NEW RECORD
  const addInfo = () => {
    // Provide default values for each field
    const defaultInfo = {
      title: "",
      text: "",
      imageURL: "",
      x: 0,
      y: 0,
      h: 0,
      w: 0,
      order: 0,
      scale: 30,
      textOffsetX: 0,
      textOffsetY: 150,
      dateCreated: new Date().toISOString(),
      cantDismiss: false,
      lastUpdated: new Date(),
      videoURL: "",
      category: "ChatGPT Help", // Default category
    };

    // Use the defaultInfo object to fill in any undefined properties
    const completeInfo = { ...defaultInfo, ...newInfo };

    const newInfoRef = push(ref(realtimeDb, "help-items"));
    set(newInfoRef, completeInfo);

    // setNewInfo(defaultInfo);
    setData((prevData) => ({
      ...prevData,
      [newInfoRef.key]: completeInfo, // Use the unique key from the new reference
    }));
  };

  // Function to set the selected row key
  const handleRowSelect = (key) => {
    // console.log(`Setting selectedRowKey to: ${key}`); // Logging the value
    setSelectedRowKey(key);
  };

  // USE EFFECT TO FETCH DATA FROM REALTIME DATABASE AND APPLY PENDING UPDATES
  useEffect(() => {
    const infoRef = ref(realtimeDb, "help-items");
    get(infoRef)
      .then((snapshot) => {
        const fetchedData = snapshot.val();

        // Handle case when fetchedData is null or undefined
        if (!fetchedData) {
          console.warn("No data fetched");
          return;
        }

        const dataWithNumbers = Object.entries(fetchedData).reduce(
          (acc, [key, value]) => {
            acc[key] = {
              ...value,
              x: Number(value.x),
              y: Number(value.y),
              h: Number(value.h),
              w: Number(value.w),
              order: Number(value.order),
              textOffsetX: Number(value.textOffsetX),
              textOffsetY: Number(value.textOffsetY),
              lastUpdated: value.lastUpdated,
            };
            return acc;
          },
          {}
        );

        // Apply pending updates
        const updatedData = Object.entries(pendingUpdates).reduce(
          (acc, [key, updates]) => {
            if (acc[key]) {
              acc[key] = { ...acc[key], ...updates };
            } else {
              // Handle adding new entries based on pendingUpdates
              acc[key] = updates;
            }
            return acc;
          },
          dataWithNumbers
        );

        setData(updatedData);
      })
      .catch((error) => {
        // Error Handling
        console.error("An error occurred while fetching data: ", error);
      });
  }, []);

  const handleRowChange = (key, field, value) => {
    // Update local state
    setData((prevData) => {
      const newData = {
        ...prevData,
        [key]: {
          ...prevData[key],
          [field]: value,
          lastUpdated: new Date().toISOString(),
        },
      };
      return newData;
    });

    // Update database
    update(ref(realtimeDb, `help-items/${key}`), {
      [field]: value,
      lastUpdated: new Date().toISOString(),
    })
      .then(() => {
        // Remove from pending updates
        setPendingUpdates((prevUpdates) => {
          const newUpdates = { ...prevUpdates };
          if (newUpdates[key]) {
            delete newUpdates[key][field];
            if (newUpdates[key] && Object.keys(newUpdates[key]).length === 0) {
              delete newUpdates[key];
            }
          }
          return newUpdates;
        });
      })
      .catch((error) => {
        // console.error("Error updating data:", error);
      });
  };

  const deleteRecord = (id) => {
    // Delete the record from the database
    const recordRef = ref(realtimeDb, `help-items/${id}`);
    set(recordRef, null);

    // Update the state to trigger a re-render
    setData((prevData) => {
      const newData = { ...prevData };
      delete newData[id];
      return newData;
    });
  };

  const handleDuplicateRow = (key) => {
    // Create a new key for the duplicated row. This could be a random string, a UUID, or a sequential ID depending on your needs.
    const newKey = push(ref(realtimeDb, "help-items")).key;

    // Duplicate the row data
    let duplicatedRow = { ...data[key] };

    // Check if lastUpdated is undefined and assign a default value if it is
    if (duplicatedRow.lastUpdated === undefined) {
      duplicatedRow.lastUpdated = new Date().toISOString();
    }

    // Add the duplicated row to the data state
    setData((prevData) => ({
      ...prevData,
      [newKey]: duplicatedRow,
    }));

    // Add the duplicated row to the database
    set(ref(realtimeDb, `help-items/${newKey}`), duplicatedRow);
  };

  const ImageButtons = ({
    images,
    setActiveField,
    setNewInfo,
    handleRowChange,
    selectedRowKey,
  }) => {
    return (
      <div>
        {images.map((image, index) => (
          <button
            key={index}
            // USING THE NEW FUNCTION HERE
            onClick={() =>
              handleButtonClick(
                image,
                setActiveField,
                setNewInfo,
                handleRowChange,
                selectedRowKey
              )
            }
            style={{
              width: "50px",
              height: "50px",
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              border: "none",
              margin: "5px",
            }}
          />
        ))}
      </div>
    );
  };

  const handleButtonClick = (
    image,
    setActiveField,
    setNewInfo,
    handleRowChange,
    selectedRowKey
  ) => {
    //console.log("handleButtonClick called with parameters:", {
    //   image,
    //   selectedRowKey,
    // });
    setActiveField(image);
    setNewInfo((prevInfo) => ({ ...prevInfo, imageURL: image }));
    if (selectedRowKey !== null) {
      handleRowChange(selectedRowKey, "imageURL", image);
      //console.log("Record updated for selectedRowKey:", selectedRowKey);
    }
  };

  return (
    <div className="ChatGPT_helper">
      <h2>ChatGPT Helper Data</h2>

      {/* @@@@ NEW RECORD @@@@ */}
      <div className="flexRowNoWrap">
        <button onClick={addInfo}>Add a new record</button>
      </div>

      <div className="div-rows">
        {/* @@@ SORT ROWS OF DATA @@@ */}
        {data &&
          Object.entries(data)
            .sort((a, b) => {
              if (a[1].category < b[1].category) {
                return -1;
              }
              if (a[1].category > b[1].category) {
                return 1;
              }
              return (a[1].order || 0) - (b[1].order || 0); // Added null checks
            })
            .map(([key, info]) => {
              return (
                <div key={key}>
                  <div
                    className={`flex-table-row-wrapper ${
                      selectedRowKey === key ? "selected" : ""
                    }`}
                    onClick={() => {
                      handleRowSelect(key);
                    }}
                  >
                    {
                      // Type check before using info.imageURL
                      info?.imageURL && showImageButtons && (
                        <div style={{ display: "inline-block" }}>
                          <ImageButtons
                            images={images}
                            setActiveField={setActiveField}
                            setNewInfo={setNewInfo}
                            handleRowChange={handleRowChange}
                            selectedRowKey={selectedRowKey}
                          />
                        </div>
                      )
                    }
                    {selectedRowKey === key && showMouseTracking && (
                      <MouseTracker
                        updateRecord={updateRecord}
                        selectedRecordKey={selectedRowKey}
                      />
                    )}
                    <div className="flex-table-row-top">
                      {/* **************** */}
                      <label className="Info" htmlFor={`category-input-${key}`}>
                        Category:
                      </label>
                      <input
                        id={`category-input-${key}`}
                        value={info.category}
                        className="input-field"
                        type="text"
                        onChange={(e) =>
                          handleRowChange(key, "category", e.target.value)
                        }
                      />
                      {/* **************** */}
                      <label className="Info" htmlFor={`title-input-${key}`}>
                        Title:{" "}
                      </label>
                      <input
                        id={`title-input-${key}`}
                        value={info.title}
                        className="input-field"
                        onChange={(e) =>
                          handleRowChange(key, "title", e.target.value)
                        }
                      />{" "}
                      {/* ************** */}
                      <label className="Info" htmlFor={`scale-input-${key}`}>
                        Scale:
                      </label>
                      <input
                        id={`scale-input-${key}`}
                        value={info.scale}
                        type="number"
                        className="input-field"
                        onChange={(e) =>
                          handleRowChange(key, "scale", e.target.value)
                        }
                      />
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={showMouseTracking}
                        onChange={(e) => {
                          setShowMouseTracking(e.target.checked);
                        }}
                      />
                      <span className="checkbox-span">Show Mouse Tracking</span>
                      {/* ************** */}
                      <label className="Info" htmlFor={`x-input-${key}`}>
                        X:
                      </label>
                      <input
                        id={`x-input-${key}`}
                        value={info.x}
                        className="input-field"
                        type="number"
                        onChange={(e) =>
                          handleRowChange(key, "x", e.target.value)
                        }
                      />
                      {/* **************** */}
                      <label className="Info" htmlFor={`y-input-${key}`}>
                        Y:
                      </label>
                      {/* w */}
                      <input
                        id={`y-input-${key}`}
                        value={info.y}
                        className="input-field"
                        type="number"
                        onChange={(e) =>
                          handleRowChange(key, "y", e.target.value)
                        }
                      />
                      {/* ***************** */}
                      <label className="Info" htmlFor={`w-input-${key}`}>
                        W:
                      </label>
                      <input
                        id={`w-input-${key}`}
                        value={info.w}
                        type="number"
                        className="input-field"
                        onChange={(e) =>
                          handleRowChange(key, "w", e.target.value)
                        }
                      />
                      {/* ***************** */}
                      <label className="Info" htmlFor={`h-input-${key}`}>
                        H:
                      </label>
                      <input
                        id={`h-input-${key}`}
                        value={info.h}
                        type="number"
                        className="input-field"
                        onChange={(e) =>
                          handleRowChange(key, "h", e.target.value)
                        }
                      />
                      {/* ****************** */}
                      <label className="Info" htmlFor={`order-input-${key}`}>
                        Order:
                      </label>
                      <input
                        id={`order-input-${key}`}
                        value={info.order}
                        className="input-field"
                        type="number"
                        onChange={(e) =>
                          handleRowChange(key, "order", e.target.value)
                        }
                      />
                    </div>
                    <div className="flexRowNoWrap">
                      <input
                        type="checkbox"
                        id="showButtonImages"
                        className="checkbox"
                        checked={showImageButtons}
                        onChange={(e) => setShowImageButtons(e.target.checked)}
                      />
                      <span className="checkbox-span">Show Images</span>
                      <label className="Info" htmlFor={`imageURL-input-${key}`}>
                        Image URL:
                      </label>
                      <input
                        id={`imageURL-input-${key}`}
                        value={info?.imageURL || ""} // Added null check
                        className="input-field"
                        style={{ width: "480px" }}
                        onChange={(e) =>
                          handleRowChange(key, "imageURL", e.target.value)
                        }
                      />
                      {/* ************** */}
                      <label className="Info" htmlFor={`videoURL-input-${key}`}>
                        Video URL:
                      </label>
                      <input
                        id={`videoURL-input-${key}`}
                        value={info.videoURL}
                        className="input-field"
                        onChange={(e) =>
                          handleRowChange(key, "videoURL", e.target.value)
                        }
                      />

                      <button onClick={() => handleDuplicateRow(key)}>
                        Duplicate
                      </button>
                      <button onClick={() => deleteRecord(key)}>Delete</button>
                    </div>
                    <div className="flex-table-row-bottom">
                      {/* ************ */}
                      <textarea
                        id={`text-input-${key}`}
                        style={{ width: "100%" }}
                        value={info.text}
                        rows={3}
                        className="input-field"
                        onChange={(e) =>
                          handleRowChange(key, "text", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default ChatGPTHelper;
