import React, { useState, useEffect } from "react";
import { realtimeDb, ref, onValue } from "../firebase";
import "../components/styles/Hub.css";

const Hub = () => {
  const [buttonsData, setButtonsData] = useState([]);

  useEffect(() => {
    const buttonsRef = ref(realtimeDb, "hub_buttons");
    onValue(buttonsRef, (snapshot) => {
      const data = snapshot.val();
      const formattedData = data
        ? Object.keys(data)
            .map((key) => ({ ...data[key], id: key }))
            .sort((a, b) => a.order - b.order)
        : [];
      setButtonsData(formattedData);
    });
  }, []);

  // Function to open URLs in a new tab
  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div>
      <div className="hub-page-container">
        <div className="hub-content">
          <img
            src="https://drive.google.com/uc?export=view&id=1Cw9vJyRadDlGojyGq8bhVeGTjG5ikcHV"
            alt="Allen"
            className="top-image"
          />

          <h1>MYBRAINONAI Hub</h1>
          {buttonsData.map((button, index) => (
            <button
              key={index}
              onClick={() => openInNewTab(button.link)}
              className="hub_buttons"
            >
              <span dangerouslySetInnerHTML={{ __html: button.icon }}></span>{" "}
              &nbsp;
              {button.text}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Hub;
