import React, { useState } from "react";
import { auth, signInWithEmailAndPassword } from "../firebase";
import { useNavigate } from "react-router-dom";
import "../components/styles/Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  const handleEmailPasswordLogin = async (e) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setSuccessMessage("Login successful!");
      setErrorMessage("");
      navigate("/dbadmin");
    } catch (error) {
      console.error(error);
      setErrorMessage("Error logging in. Please try again.");
    }
  };

  const goToForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <div className="login-form">
      <div className="login-container">
        <h1>Login to your account</h1>
        {successMessage && <p>{successMessage}</p>}
        <form onSubmit={handleEmailPasswordLogin}>
          <div>
            <label>Email:</label>
            <input
              className="login-input"
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email"
              autoComplete="current-email"
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              className="login-input"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              autoComplete="current-password"
            />
          </div>
          {/* <div>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={(value) => setCaptcha(value)}
            />
          </div> */}
          <div>
            <button className="login-button" type="submit">
              Login
            </button>
          </div>
          <div>
            {/* <button
              className="register-button"
              type="button"
              onClick={goToRegistration}
            >
              Register
            </button> */}
            <button
              className="login-button"
              type="button"
              onClick={goToForgotPassword}
            >
              Forgot Password
            </button>
          </div>
          {/* <div>
            <button
              className="login-button"
              type="button"
              onClick={handleGoogleSignIn}
            >
              Login with Google
            </button>
          </div> */}
          {errorMessage && <p className="login-error">{errorMessage}</p>}
        </form>
      </div>
    </div>
  );
};

export default Login;
