import React, { useState, useEffect } from "react";
import ChangePassword from "../components/ChangePassword";
import { auth, updateProfile } from "../firebase";
import "firebase/auth"; // Import this to use updateEmail function
import "../components/styles/Account.css";

const Account = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (auth.currentUser) {
      setUsername(auth.currentUser.displayName || "");
      setEmail(auth.currentUser.email || ""); // Set initial email value
    }
  }, []);

  const handleUsernameChange = async (e) => {
    e.preventDefault();

    try {
      await updateProfile(auth.currentUser, {
        displayName: username,
      });
      setMessage("Username updated successfully!");
    } catch (error) {
      setMessage("Error updating username. Please try again.");
    }
  };

  const handleEmailChange = async (e) => {
    e.preventDefault();

    try {
      await auth.currentUser.updateEmail(email); // Update email
      setMessage("Email updated successfully!");
    } catch (error) {
      setMessage("Error updating email. Please try again.");
    }
  };

  return (
    <div className="account">
      <h1>Account</h1>

      <form onSubmit={handleUsernameChange} className="username">
        <div className="borderAround">
          <h2>Change Username</h2>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <button type="submit">Update Username</button>
        </div>
      </form>

      <hr />

      <form onSubmit={handleEmailChange} className="email">
        <div className="borderAround">
          <h2>Change Email</h2>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit">Update Email</button>
        </div>
      </form>

      <hr />

      <div className="borderAround">
        <ChangePassword />
      </div>
      <hr />
      {message && <p>{message}</p>}

      <hr />
    </div>
  );
};

export default Account;
