import React, { useState, useEffect } from "react";
import "./styles/headerFooter.css";
import { auth, onAuthStateChanged, signOut } from "../firebase";
import { Link } from "react-router-dom";

const Header = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <header className="header">
      <h1>ChatGPT Assistant</h1>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
      />

      <nav>
        {/* <Link to="/dbadmin">Database Admin</Link> */}
        {loggedIn ? (
          <>
            {/* <Link to="/">Home</Link> */}
            {/* <div className="dropdown">
              Commands
             <div className="dropdown-content">
                <Link to="/account">Account</Link>
                <Link to="/manageniches">Manage Niches</Link>
                <Link to="/chatgpt_helper">ChatGPT Helper</Link>
                <Link to="/dbadmin">Database Admin</Link>
                <Link to="/appwindows">App Windows</Link>
                <Link to="/manage-buttons-react">Manage Buttons-React</Link>
                <Link to="/custom-instructions">Custom Instructions</Link> */}
            {/* <div className="submenu">
                  <span>App Windows</span>
                  <Link to="/web-info-collector">Web Info Collector</Link>
                  <Link to="/community-library">Community Library</Link>
                  <Link to="/my-library">My Library</Link>
                  <Link to="/helper_modal">Helper</Link>
                  <Link to="/textchunker">Text Chunker</Link>
                </div>
              </div>  
            </div>*/}
            <Link to="/appwindows">App Windows</Link>
            <Link to="/" onClick={handleLogout}>
              Logout
            </Link>
          </>
        ) : (
          <a href="/login">Login</a>
        )}
      </nav>
    </header>
  );
};

export default Header;
