import React, { useState, useEffect } from "react";
import "../../components/styles/QuickPick.css";
import SyncButtons from ".././ButtonComponents/SyncButtons";
import ManageLocalButtons from ".././ButtonComponents/ManageLocalButtons";
import useDocumentTitle from ".././PageComponents/useDocumentTitle";

function QuickPickButtons() {
  const [buttons, setButtons] = useState([]);

  useEffect(() => {
    fetchButtonsFromLocal();
  }, []);

  const handleButtonsUpdated = () => {
    // Refresh the buttons
    fetchButtonsFromLocal();
  };

  useDocumentTitle("Quick Pick - Buttons");

  const fetchButtonsFromLocal = () => {
    const openRequest = indexedDB.open("myButtonDatabase", 5);

    openRequest.onupgradeneeded = function (e) {
      const db = e.target.result;

      // Create the object stores if they don't exist
      if (!db.objectStoreNames.contains("buttons")) {
        db.createObjectStore("buttons", { keyPath: "id", autoIncrement: true });
      }

      if (!db.objectStoreNames.contains("deletedButtons")) {
        db.createObjectStore("deletedButtons", {
          keyPath: "id",
          autoIncrement: true,
        });
      }
    };

    openRequest.onsuccess = function (e) {
      const db = e.target.result;
      const transaction = db.transaction(
        ["buttons", "deletedButtons"],
        "readonly"
      );
      const store = transaction.objectStore("buttons");
      const deletedStore = transaction.objectStore("deletedButtons");

      const getRequest = store.getAll();
      const getDeletedRequest = deletedStore.getAll();

      getRequest.onsuccess = function (e) {
        const allButtons = e.target.result;

        getDeletedRequest.onsuccess = function (e) {
          const deletedButtons = e.target.result;
          const deletedButtonIds = deletedButtons.map((button) => button.id);

          const nonDeletedButtons = allButtons.filter(
            (button) => !deletedButtonIds.includes(button.id)
          );

          // Sort the buttons alphabetically by the 'text' property
          nonDeletedButtons.sort((a, b) => {
            // Sort by frequency in descending order
            if (a.frequency > b.frequency) return -1;
            if (a.frequency < b.frequency) return 1;

            return a.text.localeCompare(b.text);
          });

          setButtons(nonDeletedButtons);
        };
      };
    };
  };

  const handleButtonClick = (button) => {
    // Open the database
    const openRequest = indexedDB.open("myButtonDatabase", 5);

    openRequest.onsuccess = function (e) {
      const db = e.target.result;
      const transaction = db.transaction("buttons", "readwrite");
      const store = transaction.objectStore("buttons");
      const getRequest = store.get(button.id);

      getRequest.onsuccess = function (e) {
        const dbButton = e.target.result;

        if (!dbButton.hasOwnProperty("frequency")) {
          dbButton.frequency = 0;
        }

        dbButton.frequency += 1;
        const putRequest = store.put(dbButton);
        putRequest.onsuccess = function () {
          fetchButtonsFromLocal(); // Refresh the buttons
        };
      };
    };

    const message = {
      source: "myReactApp",
      type: "sendToChatGPT",
      text: button.text + " ",
    };
    window.top.postMessage(message, "https://chat.openai.com");
  };

  const getButtonColor = (frequency) => {
    if (frequency > 1) {
      return "yellow";
    }
    if (frequency > 10) {
      return "orange";
    }
    if (frequency > 20) {
      return "green";
    }
  };

  return (
    <div>
      <SyncButtons onButtonsUpdated={handleButtonsUpdated} />

      <ManageLocalButtons
        buttons={buttons}
        fetchButtonsFromLocal={fetchButtonsFromLocal}
        onButtonClick={handleButtonClick}
        getButtonColor={getButtonColor}
      />
    </div>
  );
}

export default QuickPickButtons;
