import React, { useState, useEffect } from "react";
import { realtimeDb, ref, push, onValue, set, update } from "../firebase";
import "../components/styles/WebInfoCollector.css";

const WebInfoCollector = () => {
  const [data, setData] = useState({});
  const [setActiveField] = useState("");
  const [newInfo, setNewInfo] = useState({
    title: "",
    text: "",
    imageURL: "",
    x: 0,
    y: 0,
    h: 0,
    w: 0,
    order: 0,
    scale: 100,
    textOffsetX: 0,
    textOffsetY: 150,
    dateCreated: new Date(),
    cantDismiss: false,
    lastUpdated: new Date(),
    videoURL: "",
    category: "",
  });
  const [isFocused, setIsFocused] = useState(false);
  const [setPendingUpdates] = useState({});
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showButtonImages, setShowButtonImages] = useState(false);
  const [categories, setCategories] = useState([""]);

  const infoRef = ref(realtimeDb, "help-items");

  const addInfo = () => {
    // Push the new information to Firebase
    const newInfoRef = push(infoRef);
    set(newInfoRef, {
      ...newInfo,
      dateCreated: new Date().toISOString(),
      lastUpdated: new Date().toISOString(),
    }).then(() => {
      // Update the local state with the newly added record
      setNewInfo({
        title: "",
        text: "",
        imageURL: "",
        x: 0,
        y: 0,
        h: 0,
        w: 0,
        order: 0,
        scale: 100,
        textOffsetX: 0,
        textOffsetY: 150,
        dateCreated: new Date(),
        cantDismiss: false,
        lastUpdated: new Date(),
        videoURL: "",
        category: "",
      });
    });
  };

  const deleteRecord = (id) => {
    // Delete the record from the database
    const recordRef = ref(realtimeDb, `help-items/${id}`);
    set(recordRef, null);

    // Update the state to trigger a re-render
    setData((prevData) => {
      const newData = { ...prevData };
      delete newData[id];
      return newData;
    });
  };

  const handleDuplicateRow = (key) => {
    // Create a new key for the duplicated row. This could be a random string, a UUID, or a sequential ID depending on your needs.
    const newKey = push(ref(realtimeDb, "help-items")).key;

    // Duplicate the row data
    let duplicatedRow = { ...data[key] };

    // Check if lastUpdated is undefined and assign a default value if it is
    if (duplicatedRow.lastUpdated === undefined) {
      duplicatedRow.lastUpdated = new Date().toISOString();
    }

    // Add the duplicated row to the data state
    setData((prevData) => ({
      ...prevData,
      [newKey]: duplicatedRow,
    }));

    // Add the duplicated row to the database
    set(ref(realtimeDb, `help-items/${newKey}`), duplicatedRow);
  };

  const handleChange = (e) => {
    setNewInfo({ ...newInfo, [e.target.name]: e.target.value });
  };

  const handleCategoryButtonClick = (category) => {
    setNewInfo({ ...newInfo, category });

    // Call formatDate function and set the title input value
    if (category === "Daily News") {
      const date = new Date(); // current date
      const formattedDate = formatDate(date); // "July 7th Daily News"
      setNewInfo({ ...newInfo, title: formattedDate, category: "Daily News" });
    }
  };

  const handleLinkButtonClick = () => {
    const { title, text } = newInfo;
    const linkText = title ? title : "Insert_a_title";
    const link = `<a href="insert_link" target="_blank">${linkText}</a>`;
    setNewInfo({ ...newInfo, text: `${text} ${link}` });
  };

  const handleRowChange = (key, field, value) => {
    // Update local state
    setData((prevData) => {
      const newData = {
        ...prevData,
        [key]: {
          ...prevData[key],
          [field]: value,
          lastUpdated: new Date().toISOString(),
        },
      };
      return newData;
    });

    // Update database
    update(ref(realtimeDb, `help-items/${key}`), {
      [field]: value,
      lastUpdated: new Date().toISOString(),
    })
      .then(() => {
        // Remove from pending updates
        setPendingUpdates((prevUpdates) => {
          const newUpdates = { ...prevUpdates };
          if (newUpdates[key]) {
            delete newUpdates[key][field];
            if (newUpdates[key] && Object.keys(newUpdates[key]).length === 0) {
              delete newUpdates[key];
            }
          }
          return newUpdates;
        });
      })
      .catch((error) => {
        // console.error("Error updating data:", error);
      });
  };

  useEffect(() => {
    const infoRef = ref(realtimeDb, "help-items");

    // Fetch initial data from Firebase
    onValue(infoRef, (snapshot) => {
      const fetchedData = snapshot.val();
      if (fetchedData) {
        setData(fetchedData);

        // Get all categories
        const allCategories = [
          ...new Set(Object.values(fetchedData).map((item) => item.category)),
        ];
        setCategories(allCategories);
      }
    });
  }, [selectedCategory]);

  function formatDate(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = date.getDate();
    let daySuffix;

    if (day > 3 && day < 21) daySuffix = "th";
    switch (day % 10) {
      case 1:
        daySuffix = "st";
        break;
      case 2:
        daySuffix = "nd";
        break;
      case 3:
        daySuffix = "rd";
        break;
      default:
        daySuffix = "th";
    }

    const month = monthNames[date.getMonth()];

    return `${month} ${day}${daySuffix} Daily News`;
  }

  return (
    <div className="width100">
      {/* INPUT FORM FOR A NEW RECORD */}
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="flexRow width100 ">
          <button
            type="button"
            onClick={() => handleCategoryButtonClick("Video")}
          >
            Video
          </button>
          <button
            type="button"
            onClick={() => handleCategoryButtonClick("Information")}
          >
            Information
          </button>
          <button
            type="button"
            onClick={() => handleCategoryButtonClick("Daily News")}
          >
            Daily News
          </button>

          <input
            id="category"
            name="category"
            type="text"
            value={newInfo.category}
            placeholder="Category"
            onChange={handleChange}
          />

          <input
            id="title"
            name="title"
            type="text"
            value={newInfo.title}
            placeholder="Title"
            onChange={handleChange}
          />
          <input
            id="imageURL"
            name="imageURL"
            type="text"
            value={newInfo.imageURL}
            placeholder="Image URL"
            onChange={handleChange}
          />

          <label htmlFor="order">Order:</label>
          <input
            id="order"
            name="order"
            type="number"
            value={newInfo.order}
            placeholder="Order"
            onChange={handleChange}
          />

          <input
            id="videoURL"
            name="videoURL"
            type="text"
            value={newInfo.videoURL}
            placeholder="Video URL"
            onChange={handleChange}
          />

          <button onClick={addInfo}>Save</button>
          <button type="button" onClick={handleLinkButtonClick}>
            Insert Link
          </button>
        </div>

        <div className="width100">
          <textarea
            id="text"
            name="text"
            style={{
              width: "98%",
              height: isFocused ? "300px" : "20px",
              transition: "height 0.5s",
            }}
            value={newInfo.text}
            placeholder="Description text"
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </div>
      </form>

      <div className="width100">
        <div className="category-filter">
          <label htmlFor="category-filter">Filter by Category:</label>
          <select
            id="category-filter"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">All</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>

        {/* @@@ ROWS OF DATA @@@ */}
        {data &&
          Object.entries(data)
            .filter(
              ([key, info]) =>
                !selectedCategory || info.category === selectedCategory
            )
            .sort((a, b) => a[1].order - b[1].order)
            .map(([key, info]) => {
              return (
                <div
                  key={key}
                  className={`flex-table-row-wrapper ${
                    selectedRowKey === key ? "selected" : ""
                  }`}
                  onClick={() => {
                    setSelectedRowKey(key);
                  }}
                >
                  <div className="flex-table-row-top width100">
                    {/* **************** */}
                    <label className="Info" htmlFor={`category-input-${key}`}>
                      Category:
                    </label>
                    <input
                      id={`category-input-${key}`}
                      value={info.category}
                      className="input-field"
                      type="text"
                      onChange={(e) =>
                        handleRowChange(key, "category", e.target.value)
                      }
                    />
                    {/* **************** */}
                    <label className="Info" htmlFor={`title-input-${key}`}>
                      Title:{" "}
                    </label>
                    <input
                      id={`title-input-${key}`}
                      value={info.title}
                      className="input-field"
                      onChange={(e) =>
                        handleRowChange(key, "title", e.target.value)
                      }
                    />{" "}
                    {/* ************** */}
                    <label className="Info" htmlFor={`imageURL-input-${key}`}>
                      Image URL:
                    </label>
                    <input
                      id={`imageURL-input-${key}`}
                      value={info.imageURL}
                      className="input-field"
                      onChange={(e) =>
                        handleRowChange(key, "imageURL", e.target.value)
                      }
                    />
                    {/* ****************** */}
                    <label className="Info" htmlFor={`order-input-${key}`}>
                      Order:
                    </label>
                    <input
                      id={`order-input-${key}`}
                      value={info.order}
                      className="input-field"
                      type="number"
                      onChange={(e) =>
                        handleRowChange(key, "order", e.target.value)
                      }
                    />
                    <input
                      id={`lastUpdated-${key}`}
                      value={info.lastUpdated}
                      className="input-field"
                      onChange={(e) =>
                        handleRowChange(key, "lastUpdated", e.target.value)
                      }
                    />
                    {/* ************** */}
                    <label className="Info" htmlFor={`videoURL-input-${key}`}>
                      Video URL:
                    </label>
                    <input
                      id={`videoURL-input-${key}`}
                      value={info.videoURL}
                      className="input-field"
                      onChange={(e) =>
                        handleRowChange(key, "videoURL", e.target.value)
                      }
                    />
                    <button onClick={() => handleDuplicateRow(key)}>
                      Duplicate
                    </button>
                    <button onClick={() => deleteRecord(key)}>Delete</button>
                  </div>
                  <div className="flex-table-row-bottom">
                    {selectedRowKey === key ? (
                      <textarea
                        id={`text-input-${key}`}
                        style={{ width: "100%", height: "250px" }} // Larger textarea
                        value={info.text}
                        rows={3}
                        className="input-field"
                        onChange={(e) =>
                          handleRowChange(key, "text", e.target.value)
                        }
                      />
                    ) : (
                      <textarea
                        id={`text-input-${key}`}
                        style={{ width: "100%" }} // Smaller textarea
                        value={info.text}
                        rows={2}
                        className="input-field"
                        onChange={(e) =>
                          handleRowChange(key, "text", e.target.value)
                        }
                      />
                    )}
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default WebInfoCollector;
