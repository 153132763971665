import React, { useState, useEffect } from "react";
import "../components/styles/SaveLibrary.css";
import Accordion from "../components/Accordion";

function MyPromptsInput() {
  const [formData, setFormData] = useState({
    category: "",
    subcategory: "",
    area: "",
    type: "",
    title: "",
    prompt: "",
    description: "",
    source: "",
  });
  const [records, setRecords] = useState([]);

  function closeWindow() {
    window.top.postMessage(
      {
        source: "myReactApp",
        type: "CLOSE_WINDOW",
      },
      "https://chat.openai.com"
    );
    // console.log("sent a message");
  }

  const addRecordToDatabase = (record) => {
    const openRequest = indexedDB.open("myDatabase2", 3);

    openRequest.onsuccess = function (e) {
      const db = e.target.result;
      const transaction = db.transaction(["myStore"], "readwrite");
      const store = transaction.objectStore("myStore");

      const addRequest = store.add(record);

      addRequest.onsuccess = function () {
        // console.log("Record added to the database");
        readFromDatabase(); // UPDATE THE TABLE
      };

      addRequest.onerror = function () {
        // console.log("Error adding record to the database");
      };
    };

    openRequest.onerror = function (e) {
      // console.log("Error opening database");
    };
  };

  function readFromDatabase() {
    const openRequest = indexedDB.open("myDatabase2", 3);

    openRequest.onupgradeneeded = function (e) {
      const db = e.target.result;
      if (!db.objectStoreNames.contains("myStore")) {
        db.createObjectStore("myStore", { keyPath: "id", autoIncrement: true });
      }
    };

    openRequest.onsuccess = function (e) {
      const db = e.target.result;
      const transaction = db.transaction("myStore", "readonly");
      const store = transaction.objectStore("myStore");
      const getRequest = store.getAll();

      getRequest.onsuccess = function () {
        // Set the data into the records state
        setRecords(getRequest.result);
      };

      getRequest.onerror = function () {
        // console.log("There was an error in fetching the data");
      };
    };

    openRequest.onerror = function (e) {
      // console.log("There was an error in opening the database");
    };
    // console.log("read from Data");
  }

  useEffect(() => {
    readFromDatabase();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    addRecordToDatabase(formData);

    // Reset the form data
    setFormData({
      category: "",
      subcategory: "",
      area: "",
      type: "",
      title: "",
      prompt: "",
      source: "",
      description: "",
    });

    closeWindow();
  };

  // INCOMING MESSAGE FROM MODAL
  // useEffect(() => {
  //   const handleMessage = (event) => {
  //     if (event.data.type === "CHAT_ASSISTANT_MESSAGE") {
  //       //console.log("REACTAPP.MYLIBRARY got a message = " + event.data.text);
  //     }
  //   };

  //   window.addEventListener("message", handleMessage);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("message", handleMessage);
  //   };
  // }, []);

  return (
    <div className="page">
      <div className="on-top">
        <Accordion
          title="Instructions - Save to your Personal Library"
          content={
            "<strong>Introduction</strong> - Easily copy prompts from other inspirational prompts you see on the web.<br /><br /> " +
            "Just enter the information for the prompt and press the <strong>'Submit'</strong> button.<br /><br />" +
            "The next time you open the <strong>'My Library'</strong> in the <strong>ChatGPT Assistant</strong> all your new prompts will be there, ready to be editied or used"
          }
        />
      </div>
      <form id="userPrompts" onSubmit={handleSubmit} className="behind">
        <div className="user-prompts">
          <div className="prompt-input">
            <input
              name="category"
              placeholder="Category"
              value={formData.category}
              onChange={handleChange}
            />
            <input
              name="subcategory"
              placeholder="Subcategory"
              value={formData.subcategory}
              onChange={handleChange}
            />
            <input
              name="area"
              placeholder="Area"
              value={formData.area}
              onChange={handleChange}
            />
            <input
              name="type"
              placeholder="Type"
              value={formData.type}
              onChange={handleChange}
            />
            <input
              name="title"
              placeholder="Title"
              value={formData.title}
              onChange={handleChange}
            />
            <input
              name="source"
              placeholder="Source"
              value={formData.source}
              onChange={handleChange}
            />
          </div>

          <div className="prompt-textarea">
            <textarea
              name="prompt"
              placeholder="Prompt"
              rows={6}
              value={formData.prompt}
              onChange={handleChange}
            />{" "}
            <textarea
              name="description"
              rows={1}
              placeholder="Description"
              value={formData.description}
              onChange={handleChange}
            />
          </div>
          <button type="submit">Submit</button>
        </div>
      </form>

      <div className="records-table">
        <table>
          <thead>
            <tr>
              <th>Category</th>
              <th>Subcategory</th>
              <th>Area</th>
              <th>Type</th>
              <th>Title</th>
              <th>Prompt</th>
              <th>Description</th>
              <th>Source</th>
            </tr>
          </thead>
          <tbody>
            {records.map((record, index) => (
              <tr key={index}>
                <td>{record.category}</td>
                <td>{record.subcategory}</td>
                <td>{record.area}</td>
                <td>{record.type}</td>
                <td>{record.title}</td>
                <td>{record.prompt}</td>
                <td>{record.description}</td>
                <td>{record.source}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MyPromptsInput;
