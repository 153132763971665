// useCheckExtension.js
import { useEffect } from "react";

function useCheckExtension() {
  useEffect(() => {
    function handleMessage(event) {
      if (event.source !== window) return;

      const message = event.data;

      // Check that the message is in the format we expect
      if (typeof message !== "object" || message === null || !message.source)
        return;

      // Check the sender ID
      if (message.source !== "njfffcfiflopfkbidnhgnkjecdneijik") return;

      // If the sender ID matches, handle the message...
      // console.log("Received message:", message);
    }

    window.addEventListener("message", handleMessage);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
}

export default useCheckExtension;
