import React from "react";

const VoiceTones = ({ selectedValue, setSelectedValue }) => {
  const options = [
    "Authoritative",
    "Friendly",
    "Inspirational",
    "Conversational",
    "Humorous",
    "Professional",
    "Enthusiastic",
    "Formal",
    "Informative",
    "Empathetic",
    "Persuasive",
    "Casual",
    "Encouraging",
    "Passionate",
    "Witty",
    "Sincere",
    "Confident",
    "Relaxed",
    "Assertive",
    "Nurturing",
    "Storytelling",
    "Excited",
    "Provocative",
    "Reflective",
    "Intellectual",
    "Lighthearted",
    "Bold",
    "Reassuring",
    "Comedic",
    "Motivational",
    "Educational",
    "Thoughtful",
    "Uplifting",
    "Poetic",
    "Sarcastic",
    "Intimate",
    "Honest",
    "Vibrant",
    "Analytical",
    "Curious",
    "Satirical",
    "Mystical",
    "Irreverent",
    "Intriguing",
    "Dramatic",
    "Visionary",
    "Calming",
    "Cynical",
    "Sensitive",
    "Energetic",
    "Philosophical",
    "Soothing",
    "Imaginative",
    "Sympathetic",
    "Adventurous",
    "Compelling",
    "Optimistic",
    "Playful",
    "Pragmatic",
    "Whimsical"
  ];

  const sortedOptions = options.sort();

  const handleSelectChange = event => {
    setSelectedValue(event.target.value);
  };

  return (
    <div>
      <select value={selectedValue} onChange={handleSelectChange}>
        <option value="">Select an option</option>
        {sortedOptions.map((option, index) =>
          <option key={index} value={option}>
            {option}
          </option>
        )}
      </select>
    </div>
  );
};

export default VoiceTones;
