import React, { useState, useEffect, useRef } from "react";
import "../../components/styles/CustomInstructions.css";
import SpeakButton from "../../components/SpeakButton";
import StopSpeakButton from "../../components/StopSpeakButton";
import TextareaAutosize from "react-textarea-autosize";
import CIInstructions from ".././PageComponents/CustomInstructionInstruction";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

function CustomUserPreferences() {
  const [preferences, setPreferences] = useState([]);
  const [isInModal, setIsInModal] = useState(false);
  const dbVersion = 2;
  const dbName = "userPreferencesDB";
  const storeName = "preferences";
  const [showInstructions, setShowInstructions] = useState(false);
  const toggleInstructions = () => {
    setShowInstructions((prev) => !prev);
  };

  const textAreaRef1 = useRef(null);
  const textAreaRef2 = useRef(null);

  // Function to open and create the database
  const openDatabase = () => {
    return new Promise((resolve, reject) => {
      const openRequest = indexedDB.open(dbName, dbVersion);

      openRequest.onupgradeneeded = function (e) {
        const db = e.target.result;

        // Check if the object store already exists
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      };

      openRequest.onsuccess = function (e) {
        resolve(e.target.result);
      };

      openRequest.onerror = function (e) {
        reject(e.target.error);
      };
    });
  };

  useEffect(() => {
    readFromDatabase();
  }, []);

  // Function to read preferences from the database
  const readFromDatabase = () => {
    openDatabase()
      .then((db) => {
        const transaction = db.transaction([storeName], "readonly");
        const store = transaction.objectStore(storeName);

        // Open the cursor in descending order
        const cursorRequest = store.openCursor(null, "prev");
        const fetchedPreferences = [];

        cursorRequest.onsuccess = function (e) {
          const cursor = e.target.result;
          if (cursor) {
            fetchedPreferences.push(cursor.value);
            cursor.continue();
          } else {
            setPreferences(fetchedPreferences);
          }
        };
      })
      .catch(console.error);
  };

  // Function to handle the change in user preferences
  const handleInputChange = (e, id, field) => {
    const newPreferences = [...preferences];
    const preferenceIndex = newPreferences.findIndex((p) => p.id === id);
    newPreferences[preferenceIndex][field] = e.target.value;
    setPreferences(newPreferences);

    // Save the updated preference to the database
    savePreferenceToDatabase(newPreferences[preferenceIndex]);
  };

  // Function to add a new user preference to the database
  const addUserPreference = () => {
    const newUserPreference = {
      aboutUser: "",
      responsePreference: "",
      date: new Date(),
    };

    // Add the new preference to the beginning of the preferences array
    setPreferences([newUserPreference, ...preferences]);

    openDatabase()
      .then((db) => {
        const transaction = db.transaction([storeName], "readwrite");
        const store = transaction.objectStore(storeName);
        const addRequest = store.add(newUserPreference);

        addRequest.onsuccess = function () {
          readFromDatabase(); // This might be redundant, but I'm leaving it for now.
        };
      })
      .catch(console.error);
  };

  const savePreferenceToDatabase = (preference) => {
    openDatabase()
      .then((db) => {
        const transaction = db.transaction([storeName], "readwrite");
        const store = transaction.objectStore(storeName);
        const putRequest = store.put(preference);

        putRequest.onerror = function (e) {
          console.error("Error saving preference: ", e.target.error);
        };
      })
      .catch(console.error);
  };

  const deletePreference = (id) => {
    const userConfirmation = window.confirm(
      "Are you sure you want to delete this custom instruction?"
    );

    if (userConfirmation) {
      openDatabase()
        .then((db) => {
          const transaction = db.transaction([storeName], "readwrite");
          const store = transaction.objectStore(storeName);
          const deleteRequest = store.delete(id);

          deleteRequest.onsuccess = function () {
            // Remove the preference from the state
            const updatedPreferences = preferences.filter((p) => p.id !== id);
            setPreferences(updatedPreferences);
          };
        })
        .catch(console.error);
    }
  };

  const copyToClipboard = async (ref) => {
    const textToCopy = ref.current.value;
    try {
      await navigator.clipboard.writeText(textToCopy);
      // console.log("Window - Text copied to clipboard", textToCopy);
    } catch (err) {
      // alert("Failed to copy text");
    }
  };

  // USED IN CHATGPT MODAL MODE
  const inAppCopyToClipboardClick = (text) => {
    const message = {
      source: "myReactApp",
      type: "copy",
      text: text,
    };
    // console.log("sent COPY message to extension", message);
    window.top.postMessage(message, "https://chat.openai.com");
  };

  useEffect(() => {
    // Check if the app is running in a modal
    const inModal = window.self === window.top;
    setIsInModal(inModal);
  }, []);

  // Convert preferences array to CSV
  const convertToCSV = (array) => {
    // Escape and wrap quotes around each field
    const escape = (field) => {
      if (field) {
        const str = field.toString();
        return `"${str.replace(/"/g, '""')}"`;
      }
      return "";
    };

    // Rearrange the object keys so that 'title' comes first
    const rearrangedObject = (obj) => {
      const { id, title, ...rest } = obj;
      return { id, title, ...rest };
    };

    // Generate the header from the first rearranged object
    const header = Object.keys(rearrangedObject(array[0]))
      .map(escape)
      .join(",");

    // Generate the values
    const values = array
      .map((obj) => Object.values(rearrangedObject(obj)).map(escape).join(","))
      .join("\n");

    // Combine the header and values separated by a newline
    return `${header}\n${values}`;
  };

  const downloadCSV = () => {
    const csvContent = convertToCSV(preferences);
    const blob = new Blob(["\uFEFF" + csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "user_preferences.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="noSpace">
      <div style={{ position: "relative" }}>
        <FontAwesomeIcon
          icon={faInfoCircle}
          style={{
            position: "absolute",
            top: "10px",
            right: "0px",
            cursor: "pointer",
          }}
          onClick={toggleInstructions}
        />
      </div>
      {showInstructions ? (
        <div>
          <CIInstructions />
        </div>
      ) : (
        <div>
          <div className="flexRowNoWrap">
            <h1 className="marginRight10px">Custom Instructions</h1>
            <button onClick={addUserPreference}>
              Add New Custom Instructions
            </button>
            <button className="download" onClick={downloadCSV}>
              Download as CSV
            </button>{" "}
          </div>

          {preferences.map((preference, index) => (
            <div
              className={`${
                index % 2 === 0 ? "evenRow borderBottom" : "oddRow borderBottom"
              }`}
            >
              {/* @@@@@@ TITLE @@@@@@ */}
              <div className="flexRowNoWrap ">
                <label>Title:</label>
                <input
                  type="text"
                  onBlur={() => savePreferenceToDatabase(preference)}
                  value={preference.title}
                  onChange={(e) => handleInputChange(e, preference.id, "title")}
                  className="title marginRight10px"
                />
                <button
                  className="delete"
                  onClick={() => deletePreference(preference.id)}
                >
                  Delete
                </button>
              </div>
              <div className="flexRowWrap">
                {/* @@@@@@ ABOUT YOU @@@@@ */}
                <div className="about_you">
                  <label>
                    About You - What would you like ChatGPT to know about you to
                    provide better responses?
                  </label>

                  <TextareaAutosize
                    ref={textAreaRef1}
                    style={{ width: "97%" }}
                    maxLength={1500}
                    value={preference.aboutUser}
                    onChange={(e) =>
                      handleInputChange(e, preference.id, "aboutUser")
                    }
                  />
                  <div className="flexRowNoWrap">
                    <span className="character_count marginRight10px">
                      Character Count {preference.aboutUser.length}/1500{" "}
                    </span>
                    {!isInModal ? (
                      <>
                        <button
                          className="marginRight10px"
                          onClick={() =>
                            inAppCopyToClipboardClick(preference.aboutUser)
                          }
                        >
                          Copy 'About You'
                        </button>
                      </>
                    ) : (
                      <button
                        className="marginRight10px"
                        onClick={() => copyToClipboard(textAreaRef1)}
                      >
                        Copy 'About You'
                      </button>
                    )}
                    <SpeakButton text={preference.aboutUser} />
                    <StopSpeakButton />
                  </div>
                </div>
                {/* @@@@@@ RESPONSE @@@@@ */}
                <div className="response">
                  <label>
                    Response - How would you like ChatGPT to respond?{" "}
                  </label>
                  <TextareaAutosize
                    style={{ width: "97%" }}
                    // className="fullWidth"
                    ref={textAreaRef2}
                    maxLength={1500}
                    value={preference.responsePreference}
                    onChange={(e) =>
                      handleInputChange(e, preference.id, "responsePreference")
                    }
                  />
                  {/* LINE AFTER RESPONSE */}
                  <div className="flexRowNoWrap">
                    <span className="character_count marginRight10px">
                      Character Count {preference.responsePreference.length}
                      /1500{" "}
                    </span>
                    {!isInModal ? (
                      <>
                        <button
                          className="marginRight10px"
                          onClick={() =>
                            inAppCopyToClipboardClick(
                              preference.responsePreference
                            )
                          }
                        >
                          Copy 'Response'
                        </button>
                      </>
                    ) : (
                      <button
                        className="marginRight10px"
                        onClick={() => copyToClipboard(textAreaRef2)}
                      >
                        Copy 'Response'
                      </button>
                    )}
                    <SpeakButton text={preference.responsePreference} />

                    <StopSpeakButton />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CustomUserPreferences;
