import React from "react";
import "../../components/styles/QuickPick.css";
import useDocumentTitle from ".././PageComponents/useDocumentTitle";
import Accordion from "../../components/Accordion";
// import SpeakButton from "../../components/SpeakButton";
// import StopSpeakButton from "../../components/StopSpeakButton";

function CommunityLibraryInstructions() {
  useDocumentTitle("Chunker - Instructions");

  //   const content = `
  //   text chunker.
  // `;

  return (
    <div>
      <Accordion
        title="Community Library Instructions"
        content={
          "<strong>Step 1</strong> - Select a <strong>Niche</strong>. These are areas of focus or specialties, such as Coaches, Educators, Businesses, and more.<br />" +
          "<strong>Step 2</strong> - Select a <strong>Category</strong> that is specific to that niche. <br />" +
          "<strong>Step 3</strong> - Select a <strong>Sub-category</strong> to focus your search even further. <br />" +
          "<strong>Step 4</strong> - Select a <strong>Title</strong> from the available options. Now, the relevant prompts will appear. There could be one prompt or a series of prompts associated with the selected title.<br />" +
          "<strong>Step 5</strong> - Customize each prompt individually or use the provided option to update all prompt variables at once.<br />" +
          "<strong>Step 6</strong> - Once a prompt is fully customized, it can either be copied to your clipboard for later use or directly sent to ChatGPT. If you send it to ChatGPT, all you need to do is press the enter button in ChatGPT.<br />" +
          "<strong>Step 7</strong> - After initiating a conversation thread with a customized prompt, the ChatGPT Assistant provides the option to capture the responses from ChatGPT into a designated response field.<br />" +
          "For future reference or further use, you have the option to download the entire sequence of prompts and responses. Just click the 'Download' button and the sequence will be saved as a text file on your PC. This way, you can revisit or repurpose the entire conversation anytime you want, making it extremely convenient for iterative learning, training, or even creating pre-defined AI conversations for different use cases."
        }
      />
    </div>
  );
}

export default CommunityLibraryInstructions;
