// Firebase core and service imports
import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  updatePassword,
  sendPasswordResetEmail,
  updateProfile,
} from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import {
  getDatabase,
  ref,
  set,
  get,
  push,
  onValue,
  update,
  off,
} from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";

// Firebase app configuration object
const informationConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

let informationApp; // Variable to hold the Firebase app instance

// Initialize the Firebase application
try {
  informationApp = initializeApp(informationConfig, "information");
  // console.log("Firebase App initialized.");
} catch (error) {
  // console.error("Firebase App Initialization Error:", error);
  throw error; // Stop further execution if initialization fails
}

// Initialize Firebase services after app initialization
const auth = getAuth(informationApp);
const googleAuthProvider = new GoogleAuthProvider();
const realtimeDb = getDatabase(informationApp);

let firestoreDb; // Variable to hold the Firestore database instance

// Initialize Firestore
try {
  firestoreDb = getFirestore(informationApp);
} catch (error) {
  console.error("Firestore Initialization Error:", error);
}

const storage = getStorage(informationApp);
const messaging = getMessaging(informationApp); // Initialize Firebase Messaging

// Export the initialized services
export {
  auth,
  googleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  signInWithPopup,
  onAuthStateChanged,
  updatePassword,
  signOut,
  sendPasswordResetEmail,
  realtimeDb,
  ref,
  set,
  get,
  push,
  onValue,
  update,
  firestoreDb,
  storage,
  getDatabase,
  informationApp,
  off,
  messaging,
};
