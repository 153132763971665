import React from "react";

const Languages = ({ selectedValue, setSelectedValue }) => {
  const options = [
    "Arabic",
    "Bengali",
    "Chinese",
    "Dutch",
    "English",
    "French",
    "German",
    "Greek",
    "Hindi",
    "Indonesian",
    "Irish",
    "Italian",
    "Japanese",
    "Korean",
    "Persian",
    "Polish",
    "Portuguese",
    "Punjabi",
    "Romanian",
    "Russian",
    "Spanish",
    "Swahili",
    "Swedish",
    "Tamil",
    "Telugu",
    "Thai",
    "Turkish",
    "Ukrainian",
    "Urdu",
    "Vietnamese"
  ];

  const sortedOptions = options.sort();

  const handleSelectChange = event => {
    setSelectedValue(event.target.value);
  };

  return (
    <div>
      <select value={selectedValue} onChange={handleSelectChange}>
        <option value="">Select an option</option>
        {sortedOptions.map((option, index) =>
          <option key={index} value={option}>
            {option}
          </option>
        )}
      </select>
    </div>
  );
};

export default Languages;
