import React from "react";

const WritingStyles = ({ selectedValue, setSelectedValue }) => {
  const options = [
    "Absurdist",
    "allegory",
    "autobiographical",
    "black comedy",
    "bildungsroman",
    "Campus novel",
    "cyberpunk",
    "dark fantasy",
    "detective fiction",
    "descriptive",
    "dystopian",
    "epistolary",
    "episodic",
    "experimental fiction",
    "expository",
    "fable",
    "First-person narrative",
    "flash fiction",
    "frame narrative",
    "Gothic",
    "hardboiled",
    "Heroic fantasy",
    "historical fiction",
    "magical realism",
    "metafiction",
    "minimalist",
    "modernist",
    "mythopoeia",
    "New Journalism",
    "noir",
    "nonlinear narrative",
    "objective",
    "parable",
    "parody",
    "pastoral",
    "picaresque",
    "poetic",
    "postmodernist",
    "persuasive",
    "psychological thriller",
    "pulp fiction",
    "realist",
    "romantic",
    "satire",
    "science fantasy",
    "slipstream",
    "social realism",
    "Southern Gothic",
    "space opera",
    "speculative fiction",
    "steampunk",
    "stream of consciousness",
    "surrealism",
    "third-person limited",
    "third-person omniscient",
    "tragicomedy",
    "travelogue",
    "utopian",
    "urban fantasy"
  ];

  const sortedOptions = options.sort();

  const handleSelectChange = event => {
    setSelectedValue(event.target.value);
  };

  return (
    <div>
      <select value={selectedValue} onChange={handleSelectChange}>
        <option value="">Select an option</option>
        {sortedOptions.map((option, index) =>
          <option key={index} value={option}>
            {option}
          </option>
        )}
      </select>
    </div>
  );
};

export default WritingStyles;
