import React, { useState, useEffect } from "react";
import { realtimeDb, ref, set, onValue } from "../firebase";
import "../components/styles/Hub.css";

const SelectorValuesManager = () => {
  const [selectorsData, setSelectorsData] = useState({
    saveAsChildDiv: "",
    saveAsDivs: "",
  });

  useEffect(() => {
    const selectorsRef = ref(realtimeDb, "selector_values");

    onValue(selectorsRef, (snapshot) => {
      const data = snapshot.val();
      //console.log("Data fetched from database: ", data); // log the data
      if (data) {
        setSelectorsData(data);
      }
    });
  }, []);

  // useEffect(() => {
  //   const selectorsRef = ref(realtimeDb, "selector_values");

  //   onValue(selectorsRef, (snapshot) => {
  //     const data = snapshot.val();
  //     //console.log("Data fetched from database: ", data); // log the data
  //     if (data) {
  //       setSelectorsData(data);

  //       // Respond with the data as JSON
  //       document.body.innerHTML = JSON.stringify(data, null, 2);
  //     }
  //   });
  // }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectorsData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleUpdate = () => {
    const selectorsRef = ref(realtimeDb, "selector_values");
    set(selectorsRef, selectorsData);
  };

  return (
    <div className="selector-values-manager">
      <h1>Selector Values Manager</h1>

      <div>
        <label>
          Save As Child Div Selector:
          <input
            type="text"
            name="saveAsChildDiv"
            value={selectorsData.saveAsChildDiv}
            onChange={handleInputChange}
          />
        </label>
      </div>

      <div>
        <label>
          Save As Divs Selector:
          <input
            type="text"
            name="saveAsDivs"
            value={selectorsData.saveAsDivs}
            onChange={handleInputChange}
          />
        </label>
      </div>

      <button onClick={handleUpdate}>Update Selectors</button>
    </div>
  );
};

export default SelectorValuesManager;
