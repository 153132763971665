import React, { useRef, useState, useEffect } from "react";
import VoiceTones from "./VoiceTones";
import WritingStyles from "./WritingStyles";
import Languages from "./Languages";
import SpeakButton from "../SpeakButton";
import StopSpeakButton from "../StopSpeakButton";
import DownloadPrompts from "./DownloadPrompts";
import TextareaAutosize from "react-textarea-autosize";
import ".././styles/Prompter.css";

const MatchingItems = ({
  matchingItems,
  selectedTemplateData,
  setSelectedTemplateData,
  itemData,
  setItemData,
  resetForm,
  showInputForm,
  setShowInputForm,
}) => {
  // const MatchingItems = ({ matchingItems }) => {
  const [showAdditionalText, setShowAdditionalText] = useState(false);
  const prevMatchingItems = useRef();
  const [fillDown, setFillDown] = useState(false);
  const [lastUpdatedVariable, setLastUpdatedVariable] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [showPromptVariables, setShowPromptVariables] = useState(false);
  const [isInModal, setIsInModal] = useState(false);

  // Handle input change
  const handleInputChange = (e, key, index) => {
    const newValue = e.target.value;
    setItemData((prevItemData) => {
      const newItemData = {
        ...prevItemData,
        [index]: {
          ...prevItemData[index],
          inputValues: {
            ...prevItemData[index].inputValues,
            [key]: newValue,
          },
        },
      };
      if (fillDown) {
        for (let i = index + 1; i < matchingItems.length; i++) {
          if (newItemData[i]) {
            newItemData[i] = {
              ...newItemData[i],
              inputValues: {
                ...newItemData[i].inputValues,
                [key]: newValue,
              },
            };
          }
        }
      }
      return newItemData;
    });
    setLastUpdatedVariable({ key, value: newValue });
  };

  const handleUpdateClick = (index) => {
    let updatedPromptText = itemData[index].selectedTemplateData.prompt_text;
    const updatedInputValues = { ...itemData[index].inputValues };

    // Get the keys of the inputValues object and sort them by length in descending order
    const keys = Object.keys(updatedInputValues);
    keys.sort((a, b) => b.length - a.length);

    // Replace the prompt variables in the order determined by the sorted keys
    for (const key of keys) {
      updatedPromptText = updatedPromptText.replace(
        new RegExp(`\\[${key}\\]`, "g"),
        updatedInputValues[key]
      );
    }

    const updatedItemData = {
      ...itemData[index],
      selectedTemplateData: {
        ...itemData[index].selectedTemplateData,
        prompt_text: updatedPromptText,
      },
      inputValues: updatedInputValues,
    };

    setItemData((prevItemData) => ({
      ...prevItemData,
      [index]: updatedItemData,
    }));
  };

  const handleCopyClick = (text) => {
    const message = {
      source: "myReactApp",
      type: "copy",
      text: text,
    };

    window.top.postMessage(message, "https://chat.openai.com");
  };

  const handleSendToChatGPTClick = (text) => {
    const message = {
      source: "myReactApp",
      type: "sendToChatGPT",
      text: text,
    };
    // console.log("Sent to Modal ", text);
    window.top.postMessage(message, "https://chat.openai.com");
  };

  async function copyToClipboard() {
    const selection = window.getSelection().toString();
    try {
      await navigator.clipboard.writeText(selection);
      // alert("Text copied to clipboard");
    } catch (err) {
      // alert("Failed to copy text");
    }
  }

  useEffect(() => {
    // Check if the app is running in a modal
    const inModal = window.self === window.top;
    setIsInModal(inModal);
  }, []);

  // Initialize state for each item
  useEffect(() => {
    if (
      JSON.stringify(matchingItems) !==
      JSON.stringify(prevMatchingItems.current)
    ) {
      setItemData(
        matchingItems.reduce((acc, item, index) => {
          acc[index] = {
            originalPromptText: item.prompt_text,
            selectedTemplateData: item,
            inputValues: {
              ...(item.inputValues || {}), // Ensure item.inputValues exists before spreading its properties
              LANGUAGE: item.inputValues
                ? item.inputValues.LANGUAGE || "English"
                : "English",
              // Add default values for other keys as necessary
            },
          };
          return acc;
        }, {})
      );
      prevMatchingItems.current = matchingItems;
    }
  }, [matchingItems, setItemData]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === "CLIPBOARD_DATA") {
        const text = event.data.text;
        // Use 'text' to update your state
        setItemData((prevItemData) => ({
          ...prevItemData,
          [currentIndex]: {
            ...prevItemData[currentIndex],
            inputValues: {
              ...prevItemData[currentIndex].inputValues,
              additionalText: text,
            },
          },
        }));
      }
    };

    window.addEventListener("message", handleMessage);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [currentIndex]);

  // ADJUST HEIGHT XXXXXXXXXXXXXXXXX
  const textAreaRef = useRef(null);

  const adjustHeight = () => {
    const element = textAreaRef.current;
    if (element) {
      element.style.height = "inherit";
      element.style.height = `${element.scrollHeight}px`;
      // console.log("adjusted height", element.scrollHeight);
    }
  };

  // Effect to run adjustHeight when selectedTemplateData.prompt_text changes
  useEffect(() => {
    adjustHeight();
  }, [selectedTemplateData ? selectedTemplateData.prompt_text : ""]);

  useEffect(() => {
    if (textAreaRef.current) {
      adjustHeight();
    }
  }, [textAreaRef.current]);

  // ADJUST HEIGHT XXXXXXXXXXXXXXXXXXXXXX

  // @@@@@@@@@@@@@@@@@@@ @ @ @ @ @ @@@@@@@@@@@@@@@@@@@@@@@
  return (
    <div className="prompt-container">
      {matchingItems.length > 0 && (
        <div className="prompt-container-header">
          <label className="switch">
            <input
              type="checkbox"
              className="checkbox"
              checked={showInputForm}
              onChange={(e) => setShowInputForm(e.target.checked)}
              title="Show or Hide Input Form to save space"
            />
            <span className="slider"></span>
          </label>
          <label>
            {showInputForm ? "Hide Prompt Selectors" : "Show Prompt Selectors"}
          </label>
          {/* SHOW HIDE VARIABLES */}
          <label className="switch">
            <input
              type="checkbox"
              className="checkbox"
              checked={showPromptVariables}
              title="Show Prompt [Variable] input field to fill the corresponding Prompt text"
              onChange={(e) => setShowPromptVariables(e.target.checked)}
            />
            <span className="slider"></span>
          </label>
          <label>
            {showPromptVariables
              ? "Hide Prompt Variables"
              : "Show Prompt Variables"}
          </label>
          {/* // Fill DOWN */}
          <label className="switch">
            <input
              type="checkbox"
              className="checkbox"
              checked={fillDown}
              onChange={(e) => setFillDown(e.target.checked)}
              title="Fill Prompt Variables in All of the Prompts down the page"
            />
            <span className="slider"></span>
          </label>
          <label>{fillDown ? "Do not fill Down" : "Fill Down"}</label>
          {/* // SHOW HIDE RESPONSES */}
          <label className="switch">
            <input
              className="checkbox"
              type="checkbox"
              checked={showAdditionalText}
              onChange={(e) => setShowAdditionalText(e.target.checked)}
              title="Show ChatGPT responses field"
            />
            <span className="slider"></span>
          </label>
          <label className="label">
            {showAdditionalText
              ? "Hide Responses field"
              : "Show Responses field"}
          </label>
          {/* // THE DOWNLOAD BUTTON */}
          <DownloadPrompts
            matchingItems={matchingItems}
            itemData={itemData}
          />{" "}
        </div>
      )}
      {matchingItems.map((item, index) => {
        if (!item || !itemData[index]) {
          return null;
        }
        const {
          // originalPromptText,
          selectedTemplateData,
          inputValues,
        } = itemData[index];

        const handlePasteClick = () => {
          setCurrentIndex(index);
          // Send a message to the top-level window asking for the clipboard data
          window.top.postMessage(
            { type: "REQUEST_CLIPBOARD_DATA" },
            "https://chat.openai.com"
          );
        };

        // Parse prompt_text to find all substrings enclosed in []
        const matches =
          selectedTemplateData && selectedTemplateData.prompt_text
            ? selectedTemplateData.prompt_text.match(/\[([^\]]+)\]/g)
            : null;

        return (
          <div
            key={index}
            className={`borderBottom flex-item row ${
              index === activeRowIndex ? "active" : ""
            }`}
            onClick={() => {
              // console.log(`Row ${index} clicked`);
              setActiveRowIndex(index);
            }}
          >
            <div className="title-n-speak">
              {item.prompt_description && (
                <>
                  <div className="label">Prompt Description:</div>
                  <div className="speak-buttons">
                    <SpeakButton text={item.prompt_description} />
                    <StopSpeakButton />
                  </div>
                </>
              )}
            </div>
            {item.prompt_description && (
              <div className="description-text">{item.prompt_description}</div>
            )}

            <div className="title-n-speak">
              <div className="label">Prompt Text:</div>
              <div className="speak-buttons">
                <SpeakButton
                  text={
                    selectedTemplateData ? selectedTemplateData.prompt_text : ""
                  }
                />
                <StopSpeakButton />
              </div>
            </div>
            <div>
              <div>
                <TextareaAutosize
                  ref={textAreaRef}
                  className="autosize-textarea"
                  value={
                    selectedTemplateData ? selectedTemplateData.prompt_text : ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setItemData((prevItemData) => {
                      const newItemData = {
                        ...prevItemData,
                        [index]: {
                          ...prevItemData[index],
                          selectedTemplateData: {
                            ...prevItemData[index].selectedTemplateData,
                            prompt_text: newValue,
                          },
                        },
                      };
                      return newItemData;
                    });

                    // IF FILLDOWN IS SEL
                    if (fillDown && lastUpdatedVariable) {
                      const currentIndex = matchingItems.findIndex(
                        (item) => item.id === index
                      );
                      for (
                        let i = currentIndex + 1;
                        i < matchingItems.length;
                        i++
                      ) {
                        const currentPromptText =
                          itemData[i].selectedTemplateData.prompt_text;
                        const updatedPromptText = currentPromptText.replace(
                          new RegExp(`\\[${lastUpdatedVariable.key}\\]`, "g"),
                          lastUpdatedVariable.value
                        );
                        setItemData((prevItemData) => ({
                          ...prevItemData,
                          [i]: {
                            ...prevItemData[i],
                            selectedTemplateData: {
                              ...prevItemData[i].selectedTemplateData,
                              prompt_text: updatedPromptText,
                            },
                          },
                        }));
                      }
                    }
                  }}
                />
              </div>

              <button onClick={() => handleUpdateClick(index)}>
                Update [values]
              </button>

              <button
                onClick={() => {
                  setItemData((prevItemData) => ({
                    ...prevItemData,
                    [index]: {
                      ...prevItemData[index],
                      selectedTemplateData: {
                        ...prevItemData[index].selectedTemplateData,
                        prompt_text: "",
                      },
                    },
                  }));
                }}
              >
                Clear
              </button>

              <button
                onClick={() => {
                  setItemData((prevItemData) => ({
                    ...prevItemData,
                    [index]: {
                      ...prevItemData[index],
                      selectedTemplateData: {
                        ...prevItemData[index].selectedTemplateData,
                        prompt_text: prevItemData[index].originalPromptText,
                      },
                    },
                  }));
                }}
              >
                Reload
              </button>

              {!isInModal ? (
                <>
                  <button
                    onClick={() =>
                      handleCopyClick(selectedTemplateData.prompt_text)
                    }
                  >
                    Copy All
                  </button>

                  <button
                    onClick={() =>
                      handleSendToChatGPTClick(selectedTemplateData.prompt_text)
                    }
                  >
                    Paste to ChatGPT
                  </button>
                </>
              ) : (
                <button onClick={copyToClipboard}>Copy Selection</button>
              )}

              <div className="prompt-variables">
                {/* Here are the inputs for each matched substring */}
                {showPromptVariables &&
                  matches &&
                  matches.map((match, i) => {
                    //const key = match.replace(/[\[\]]/g, "");
                    // THESE COULD FIX AN ERROR BUT I DONT HAVE TIME TO TEST
                    //const key = match.replace(/[[]]/g, "");
                    const key = match.replace(/[[\]]/g, "");
                    const parts = key.split("/");
                    return (
                      <div key={i}>
                        <label>
                          {"["}
                          {parts.length > 1
                            ? parts.map((part, partIndex) => (
                                <span
                                  key={partIndex}
                                  onClick={() =>
                                    handleInputChange(
                                      { target: { value: part } },
                                      key,
                                      index
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                    color: "blue",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {partIndex > 0 && " / "}
                                  {part}
                                </span>
                              ))
                            : key}
                          {"]"}
                        </label>
                        <textarea
                          value={
                            key === "LANGUAGE"
                              ? inputValues[key] || "English"
                              : inputValues[key] || ""
                          }
                          onChange={(e) => handleInputChange(e, key, index)}
                        />

                        {key === "VOICE TONE" && (
                          <VoiceTones
                            selectedValue={inputValues[key] || ""}
                            setSelectedValue={(value) =>
                              handleInputChange(
                                { target: { value } },
                                key,
                                index
                              )
                            }
                          />
                        )}
                        {key === "WRITING STYLE" && (
                          <WritingStyles
                            selectedValue={inputValues[key] || ""}
                            setSelectedValue={(value) =>
                              handleInputChange(
                                { target: { value } },
                                key,
                                index
                              )
                            }
                          />
                        )}
                        {key === "LANGUAGE" && (
                          <Languages
                            selectedValue={inputValues[key] || ""}
                            setSelectedValue={(value) =>
                              handleInputChange(
                                { target: { value } },
                                key,
                                index
                              )
                            }
                          />
                        )}
                      </div>
                    );
                  })}
                {/* <hr /> */}
              </div>

              {showAdditionalText && (
                <div>
                  <button onClick={() => handlePasteClick(index)}>Paste</button>
                  {/* <button  className="insert-from-clipboard" onClick={() => handlePasteClick(index)} > Insert Clipboard </button> */}
                  <br />
                  <TextareaAutosize
                    className="autosize-textarea"
                    value={itemData[index].inputValues.additionalText || ""}
                    onChange={(e) =>
                      handleInputChange(e, "additionalText", index)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        );
      })}
      {/* </Carousel> */}
    </div>
  );
};

export default MatchingItems;
