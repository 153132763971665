// MouseTracker.js
import React, { useState, useEffect } from "react";

// MAIN FUNCTION FOR THE MOUSE TRACKING COMPONENT
const MouseTracker = ({ updateRecord, selectedRecordKey }) => {
  // added props
  const [isTracking, setIsTracking] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [windowSize, setWindowSize] = useState({
    w: window.innerWidth,
    h: window.innerHeight,
  });
  const [savedPosition, setSavedPosition] = useState({ x: 0, y: 0 });
  const [isPositionSaved, setIsPositionSaved] = useState(false); // NEW STATE VARIABLE
  // Define a style for the overlay div
  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999, // Make sure it's on top of everything else
    backgroundColor: "rgba(0,0,0,0.1)", // Optional: semi-transparent background
  };

  // FUNCTION TO HANDLE MOUSE MOVE
  const handleMouseMove = (e) => {
    if (!isPositionSaved) {
      setPosition((prevPos) => ({ x: e.clientX, y: e.clientY }));
      //console.log("setPosition called", e.clientX, e.clientY);
    }
  };

  // FUNCTION TO SAVE MOUSE POSITION AND STOP TRACKING
  const saveAndStopTracking = (e) => {
    //console.log("saveAndStopTracking function called"); // <-- Log here

    // Check if the clicked element is a button
    if (e.target.tagName.toLowerCase() === "button") {
      return;
    }

    if (!isPositionSaved) {
      const newPosition = { x: e.clientX, y: e.clientY };
      //console.log("Position to be saved:", newPosition);
      setSavedPosition(newPosition);
      setIsTracking(false);
      setIsPositionSaved(true);
    }
  };

  // MAIN useEffect FOR MOUSE TRACKING
  useEffect(() => {
    //console.log("Updated savedPosition:", savedPosition);
  }, [savedPosition]);

  useEffect(() => {
    //console.log("useEffect for mousemove isTracking:", isTracking);
    if (isTracking) {
      window.addEventListener("mousemove", handleMouseMove);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      setIsPositionSaved(false); // RESET WHEN STOP TRACKING
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isTracking]);

  useEffect(() => {
    //console.log("useEffect for click isTracking:", isTracking);
    if (isTracking) {
      window.addEventListener("click", saveAndStopTracking);
    } else {
      window.removeEventListener("click", saveAndStopTracking);
    }
    return () => {
      window.removeEventListener("click", saveAndStopTracking);
    };
  }, [isTracking, isPositionSaved]);

  // FUNCTION TO SEND DATA TO A RECORD
  const sendDataToRecord = () => {
    if (selectedRecordKey) {
      updateRecord(selectedRecordKey, { ...position, ...windowSize });
    } else {
      console.error("No record selected.");
    }
  };

  return (
    <div>
      <button
        onClick={() => {
          //console.log("Start Tracking button clicked");
          setIsTracking(true);
        }}
      >
        Start Tracking
      </button>
      <button
        onClick={() => {
          setIsTracking(false);
        }}
      >
        Stop Tracking
      </button>
      <button onClick={sendDataToRecord}>Send Data to Record</button>

      <p>
        Current: X: {position.x}, Y: {position.y}, Width: {windowSize.w},
        Height: {windowSize.h}
      </p>
      <p>
        Saved: X: {savedPosition.x}, Y: {savedPosition.y}
      </p>

      {/* Overlay div */}
      {isTracking && (
        <div style={overlayStyle} onClick={saveAndStopTracking}></div>
      )}
    </div>
  );
};

export default MouseTracker;
