import React from "react";
import "../../components/styles/QuickPick.css";
import useDocumentTitle from ".././PageComponents/useDocumentTitle";
import Accordion from "../../components/Accordion";
// import SpeakButton from "../../components/SpeakButton";
// import StopSpeakButton from "../../components/StopSpeakButton";

function CustomInstructionInstruction() {
  useDocumentTitle("Chunker - Instructions");

  return (
    <div>
      <Accordion
        title="Instructions - Custom Instructions"
        content={
          "To begin adding new <strong>Custom Instructions</strong> press the <strong>Add New Custom Instructions </strong>button below. <br />" +
          "Then enter a <strong>Title </strong> for your custom instruction set, aiding quick future identification.<br /><br />" +
          "<strong>Personal Information Prompt</strong><br />" +
          "Respond to the query: 'What would you like ChatGPT to know about you to provide better responses?'. Type your context or details in the accompanying text area. Stay mindful of the 1500 character cap, using the decreasing character counter as a guide.<br /><br />" +
          "<strong>Response Preference Prompt</strong><br />" +
          "For the query: 'How would you like ChatGPT to respond?', write your desired response attributes in the text area. This, too, has a 1500 character limit.<br /><br />" +
          "<strong>Managing Instructions</strong><br />" +
          "To <strong>Edit </strong>Alter an existing set by selecting it, your changes are automatically saved.<br />" +
          "To <strong>Delete</strong> Custom Instruction set's, use the 'Delete' button corresponding to the undesired instruction set. A deletion confirmation will be necessary.<br /><br />"
        }
      />
      <Accordion
        title="Instructions - To use These Custom Instructions"
        content={
          "This is a two step process (for now... We are working to improve it).<br /><br />" +
          "<strong>Step 1 </strong>To use your Custom Instructions first copy the Custom Instruction text using the <strong>Copy 'About You'</strong> button.<br /><br />" +
          "Minimize this modal and Open the Custom Instructions from the bottom left corner by your name<<br />" +
          "Paste (using Coammand/Control V) the text into the appropriate text area.<br /><br />" +
          "Save and close the Custom Instructions dialog.<br /><br />" +
          "Maximize the Custom Instructions Modal and press the Copy 'Response' Button to copy the text in 'How you would like ChatGPT to respond' text area<br />" +
          "Again minimize the modal - or close it and paste the data into the second area<br />" +
          "Save and close the Custom Instructions dialog.<br /><hr />" +
          "Now when you create a new conversation thread, these new Custom Instructions will apply.<br /><br />"
        }
      />

      <Accordion
        title="Instructions - To use These Custom Instructions from a New Tab"
        content={
          "Open the Custom Instructions in the ChatGPT window.<br /><br />" +
          "<strong>Step 1</strong> To use your Custom Instructions press the <strong>Copy 'About You'</strong> button below.<br /><br />" +
          "In the ChatGPT Custome Instructions area, Paste the text into the appropriate text area.(using Coammand/Control V). <br /><br />" +
          "Now back in this window press the <strong>Copy 'Response'</strong> button to copy the text in 'How you would like ChatGPT to respond' text area." +
          "Again in the ChatGPT window paste the data into the second area." +
          "Save the Custom Instructions.<br /><hr />" +
          "Now when you create a new conversation thread, these new Custom Instructions will apply.<br /><br />"
        }
      />
      {/* <div className="fancy_text" style={{ textAlign: "center" }}>
        Youtube video.
        <SpeakButton text={content} />
        <StopSpeakButton />
      </div>
      <br />
      <div style={{ textAlign: "center" }}>
        <h3>
          <strong>Community Library</strong>
        </h3>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Pj1r_RMPadI"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ border: "none", maxWidth: "100%", maxHeight: "100%" }}
        ></iframe>{" "}
      </div> */}
    </div>
  );
}

export default CustomInstructionInstruction;
