import React from "react";
import "../../components/styles/QuickPick.css";
import useDocumentTitle from ".././PageComponents/useDocumentTitle";
import Accordion from "../../components/Accordion";
// import SpeakButton from "../../components/SpeakButton";
// import StopSpeakButton from "../../components/StopSpeakButton";

function CommunityLibraryInstructions() {
  useDocumentTitle("My Library - Instructions");

  //   const content = `
  //   My Library Text.
  // `;

  return (
    <div>
      <Accordion
        title="My Prompt Library Instructions "
        content={
          "<strong>Introduction</strong> - The Prompt Library is used to create, store, and use custom prompts in ChatGPT. <br /><br />" +
          "When you open the Prompt Library modal, you'll first see a button to <strong>'Add a New Prompt'</strong>. When you click that button a new record will appear and you can begin creating your prompt.<br /><br />" +
          "Below that is a filter to sort your prompts by 'Category' and 'Sub-category'.<br /><br />" +
          "To the right of the filter is a checkbox to <strong>Show All Fields</strong> associated with the prompt.<br /><br />" +
          "Beside that is a checkbox to <strong>'Show Quick Picks'</strong> which can help you quickly create your prompt text.<br /><br />" +
          "At the bottom is a button to <strong>'Send to ChatGPT's'</strong> input."
        }
      />
      {/* <div className="fancy_text" style={{ textAlign: "center" }}>
        My Library Youtube video.
        <SpeakButton text={content} />
        <StopSpeakButton />
      </div>
      <br />
      <div style={{ textAlign: "center" }}>
        <h3>
          <strong>My Library</strong>
        </h3>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Pj1r_RMPadI"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ border: "none", maxWidth: "100%", maxHeight: "100%" }}
        ></iframe>
      </div>
    </div> */}
    </div>
  );
}

export default CommunityLibraryInstructions;
