import React, { useState, useEffect } from "react";
import { realtimeDb, ref, set, push, onValue, update } from "../firebase";
import "../components/styles/Information.css";
import UploadInformation from "../components/UploadInformation";

const Information = () => {
  const [data, setData] = useState({});
  const [newInfo, setNewInfo] = useState({
    category: "",
    area: "",
    step: "",
    title: "",
    description: "",
    videoURL: "",
    imageURL: "",
    difficulty: "",
    externalResources: "",
  });
  // const [editingRow, setEditingRow] = useState(null);

  useEffect(() => {
    const infoRef = ref(realtimeDb, "information");
    onValue(infoRef, (snapshot) => {
      setData(snapshot.val());
    });
  }, []);

  const handleChange = (e) => {
    setNewInfo({ ...newInfo, [e.target.name]: e.target.value });
  };

  const handleRowChange = (key, field, value) => {
    setData((prevData) => ({
      ...prevData,
      [key]: {
        ...prevData[key],
        [field]: value,
      },
    }));
    // Update the database in real-time
    update(ref(realtimeDb, `information/${key}`), {
      [field]: value,
    });
  };

  const addInfo = () => {
    const newInfoRef = push(ref(realtimeDb, "information"));
    set(newInfoRef, newInfo);
    setNewInfo({
      category: "",
      area: "",
      step: "",
      title: "",
      description: "",
      videoURL: "",
      imageURL: "",
      difficulty: "",
      externalResources: "",
    });
  };

  const deleteRecord = (id) => {
    const recordRef = ref(realtimeDb, `information/${id}`);
    set(recordRef, null);
  };

  return (
    <div>
      <div className="top-line">
        <h2>Information</h2>
        <UploadInformation />
      </div>

      <form
        onSubmit={(e) => e.preventDefault()}
        className="information-input-form"
      >
        <div className="info_two_columns">
          <div className="info_input_col_1">
            <input
              id="category"
              name="category"
              type="text"
              value={newInfo.category}
              placeholder="Category"
              onChange={handleChange}
            />
            <input
              id="area"
              name="area"
              type="text"
              value={newInfo.area}
              placeholder="Area"
              onChange={handleChange}
            />
            <input
              id="videoURL"
              name="videoURL"
              type="text"
              value={newInfo.videoURL}
              placeholder="Video URL"
              onChange={handleChange}
            />

            <input
              id="title"
              name="title"
              type="text"
              value={newInfo.title}
              placeholder="Title"
              onChange={handleChange}
            />

            <input
              id="imageURL"
              name="imageURL"
              type="text"
              value={newInfo.imageURL}
              placeholder="Image-URL"
              onChange={handleChange}
            />
            <input
              id="externalResources"
              name="externalResources"
              type="text"
              value={newInfo.externalResources}
              placeholder="External Resources"
              onChange={handleChange}
            />
          </div>

          <div className="sort_diff_add_col_2">
            <input
              id="difficulty"
              name="difficulty"
              type="number"
              value={newInfo.difficulty}
              placeholder="Difficulty"
              onChange={handleChange}
            />
            <input
              id="step"
              name="step"
              type="number"
              value={newInfo.step}
              placeholder="Step Number"
              onChange={handleChange}
            />
            <button onClick={addInfo}>Add</button>
          </div>
        </div>

        <div className="info_col_3">
          <textarea
            className="input_description"
            name="description"
            type="text"
            rows={16}
            value={newInfo.description}
            placeholder="Description"
            onChange={handleChange}
          />
        </div>
      </form>

      <div className="">
        {Object.entries(data).map(([key, info]) => (
          <div key={key} className="flex-table-row">
            <div className="flex-table-cell tbl-col1">
              <div className="input-fields-container">
                <div className="input-container">
                  <label className="Info" htmlFor={`category-input-${key}`}>
                    Category:{" "}
                  </label>
                  <input
                    id={`category-input-${key}`}
                    value={info.category}
                    className="input-field"
                    onChange={(e) =>
                      handleRowChange(key, "category", e.target.value)
                    }
                  />
                </div>

                <div className="input-container">
                  <label className="Info" htmlFor={`area-input-${key}`}>
                    Area:
                  </label>
                  <input
                    id={`area-input-${key}`}
                    value={info.area}
                    className="input-field"
                    onChange={(e) =>
                      handleRowChange(key, "area", e.target.value)
                    }
                  />
                </div>

                <div className="input-container">
                  <label className="Info" htmlFor={`title-input-${key}`}>
                    Title:
                  </label>
                  <input
                    id={`title-input-${key}`}
                    value={info.title}
                    className="input-field"
                    onChange={(e) =>
                      handleRowChange(key, "title", e.target.value)
                    }
                  />
                </div>

                <div className="input-container">
                  <label className="Info" htmlFor={`step-input-${key}`}>
                    Step:
                  </label>
                  <input
                    id={`step-input-${key}`}
                    value={info.step}
                    className="input-field"
                    onChange={(e) =>
                      handleRowChange(key, "step", e.target.value)
                    }
                  />
                </div>

                <div className="input-container">
                  <label className="Info" htmlFor={`difficulty-input-${key}`}>
                    Difficulty:
                  </label>
                  <input
                    id={`difficulty-input-${key}`}
                    value={info.difficulty}
                    className="input-field"
                    onChange={(e) =>
                      handleRowChange(key, "difficulty", e.target.value)
                    }
                  />
                </div>

                <div className="input-container">
                  <label className="Info" htmlFor={`videoURL-input-${key}`}>
                    Video URL:
                  </label>
                  <input
                    id={`videoURL-input-${key}`}
                    value={info.videoURL}
                    className="input-field"
                    onChange={(e) =>
                      handleRowChange(key, "videoURL", e.target.value)
                    }
                  />
                </div>

                <div className="input-container">
                  <label className="Info" htmlFor={`imageURL-input-${key}`}>
                    Image URL:
                  </label>
                  <input
                    id={`imageURL-input-${key}`}
                    value={info.imageURL}
                    className="input-field"
                    onChange={(e) =>
                      handleRowChange(key, "imageURL", e.target.value)
                    }
                  />
                </div>

                <div className="input-container">
                  <label
                    className="Info"
                    htmlFor={`externalResources-input-${key}`}
                  >
                    External Resources:
                  </label>
                  <input
                    id={`externalResources-input-${key}`}
                    value={info.externalResources}
                    className="input-field"
                    onChange={(e) =>
                      handleRowChange(key, "externalResources", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="flex-table-cell tbl-col2">
              <textarea
                value={info.description}
                rows={16}
                className="table_description"
                onChange={(e) =>
                  handleRowChange(key, "description", e.target.value)
                }
              />
            </div>

            <div className="flex-table-cell tbl-col3">
              <button onClick={() => deleteRecord(key)}>Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Information;
