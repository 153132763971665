import React, { useState } from "react";
import { realtimeDb, ref, set, push } from "../firebase";
import Papa from "papaparse";
import "../components/styles/uploadInformation.css";

const UploadInformation = () => {
  const [csvFile, setCsvFile] = useState(null);
  const [messages, setMessages] = useState([]);

  const handleFileUpload = event => {
    setCsvFile(event.target.files[0]);
  };

  const addMessage = message => {
    setMessages(prevMessages => [...prevMessages, message]);
  };

  const parseAndUploadCSV = () => {
    if (!csvFile) {
      addMessage("Please select a CSV file first.");
      return;
    }

    Papa.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      complete: results => {
        const expectedHeaders = [
          "Category",
          "Area",
          "Step",
          "Title",
          "Description",
          "VideoURL",
          "ImageURL",
          "Difficulty",
          "ExternalResources"
        ];
        const actualHeaders = results.meta.fields;

        const headersMatch = expectedHeaders.every(
          (header, index) => header === actualHeaders[index]
        );

        if (!headersMatch) {
          addMessage(`The headings in the CSV file do not match the expected headings. 
            \nExpected: ${expectedHeaders.join(", ")} 
            \nFound: ${actualHeaders.join(", ")}`);
          return;
        }

        results.data.forEach((row, index) => {
          // Check if at least one value exists in the row
          const hasData = Object.values(row).some(
            value => value !== undefined && value !== null && value !== ""
          );

          if (hasData) {
            const newInfoRef = push(ref(realtimeDb, "information"));
            set(
              newInfoRef,
              {
                category: row["Category"],
                area: row["Area"],
                step: row["Step"],
                title: row["Title"],
                description: row["Description"],
                videoURL: row["VideoURL"],
                imageURL: row["ImageURL"],
                difficulty: row["Difficulty"],
                externalResources: row["ExternalResources"]
              },
              error => {
                if (error) {
                  addMessage(`Error uploading row ${index}: ${error.message}`);
                }
              }
            );
          } else {
            addMessage(
              `Skipped row ${index} with no values: ${JSON.stringify(row)}`
            );
          }
        });
      },
      error: error => {
        addMessage("CSV parsing error: " + error.message);
      }
    });
  };

  return (
    <div>
      <div className="info-flex-container">
        <div className="border-around-upload">
          <div className="">
            <label>Upload CSV file</label>
          </div>{" "}
          <div className="info-flex-item">
            <button className="custom-file-button">Choose file..</button>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
              className="custom-file-input"
            />
          </div>{" "}
          <div className="info-flex-item upload">
            <button className="info-upload-button" onClick={parseAndUploadCSV}>
              Upload
            </button>
          </div>
        </div>
      </div>
      {messages.length > 0 &&
        <div style={{ backgroundColor: "yellow" }}>
          <pre>
            {messages.join("\n")}
          </pre>
        </div>}
    </div>
  );
};

export default UploadInformation;
