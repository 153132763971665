import React, { useState } from "react";
import { realtimeDb } from "../firebase";
import { push } from "firebase/database";
import Papa from "papaparse";
import { ref, set } from "firebase/database";
import "../components/styles/DBadmin.css";

const UploadCSV = () => {
  const [csvFile, setCsvFile] = useState(null);
  const [messages, setMessages] = useState([]);
  const [includeExpertTypes, setIncludeExpertTypes] = useState(false);

  const handleFileUpload = event => {
    setCsvFile(event.target.files[0]);
  };

  const handleCheckboxChange = event => {
    setIncludeExpertTypes(event.target.checked);
  };

  const addMessage = message => {
    setMessages(prevMessages => [...prevMessages, message]);
  };

  const parseAndUploadCSV = () => {
    if (!csvFile) {
      addMessage("Please select a CSV file first.");
      return;
    }

    Papa.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      complete: results => {
        const expectedHeaders = [
          "Sub-Niche",
          "Category",
          "Sub-Category",
          "Title",
          "Order",
          "Description",
          "Prompt",
          "Buttons"
        ];
        const actualHeaders = results.meta.fields;

        const headersMatch = expectedHeaders.every(
          (header, index) => header === actualHeaders[index]
        );

        if (!headersMatch) {
          addMessage(`The headings in the CSV file do not match the expected headings. 
            \nExpected: ${expectedHeaders.join(", ")} 
            \nFound: ${actualHeaders.join(", ")}`);
          return;
        }

        results.data.forEach((row, index) => {
          // Check if at least one value exists in the row
          const hasData = Object.values(row).some(
            value => value !== undefined && value !== null && value !== ""
          );

          if (hasData) {
            const newExpertTypeRef = push(
              ref(realtimeDb, "expert_information")
            );
            set(
              newExpertTypeRef,
              {
                niche: row["Sub-Niche"],
                category: row["Category"],
                sub_category: row["Sub-Category"],
                template: row["Title"],
                order: row["Order"],
                prompt_description: row["Description"],
                prompt_text: row["Prompt"],
                button: row["Buttons"]
              },
              error => {
                if (error) {
                  addMessage(`Error uploading row ${index}: ${error.message}`);
                }
              }
            );
          } else {
            addMessage(
              `Skipped row ${index} with no values: ${JSON.stringify(row)}`
            );
          }
        });
      },
      error: error => {
        addMessage("CSV parsing error: " + error.message);
      }
    });
  };

  return (
    <div>
      <div className="flex-container">
        <div className="box-upload-file">
          <div className="choose-file-text">
            <label htmlFor="csvFileInput">1. Choose CSV file:</label>
          </div>
          <div className="file-input-container">
            <input
              type="file"
              accept=".csv"
              id="csvFileInput"
              onChange={handleFileUpload}
              className="custom-file-input"
            />
            <label htmlFor="csvFileInput" className="file-input-button">
              Choose File
            </label>
          </div>
          <div className="checkbox-div">
            <input
              id="toggleInputs"
              type="checkbox"
              className="checkbox"
              checked={includeExpertTypes}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="include-rows">
            <label>Include rows with empty columns</label>
          </div>
          <div className="upload-button">
            <button className="upload-button" onClick={parseAndUploadCSV}>
              2. Upload
            </button>
          </div>
        </div>
      </div>

      {messages.length > 0 &&
        <div style={{ backgroundColor: "yellow" }}>
          <pre>
            {messages.join("\n")}
          </pre>
        </div>}
    </div>
  );
};

export default UploadCSV;
