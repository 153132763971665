import React from "react";
import "../../components/styles/QuickPick.css";
import useDocumentTitle from ".././PageComponents/useDocumentTitle";
import Accordion from "../../components/Accordion";
import SpeakButton from "../../components/SpeakButton";
import StopSpeakButton from "../../components/StopSpeakButton";

function ChunkerInstructions() {
  useDocumentTitle("Chunker - Instructions");

  const content = `
  Below is a link to a Chrome Extension called 'Glasp' that can create a transcript of any Youtube video. In that extension there is an option to copy the text and you can paste it right into the text chunker.
`;

  return (
    <div>
      <h1>Chunker Instructions</h1>
      <Accordion
        title="Text Chunker"
        content={
          "<strong>The text Chunker</strong> feature facilitates a seamless interaction with ChatGPT by dividing large bodies of text into manageable chunks that can be processed by the GPT-3 or GPT-4 engine. Below is a step-by-step guide to using this feature.<br />" +
          "<br />1. Prepare Your Text: Have the text document ready that you want to chunk. The document can be a video transcript, research paper, an article, or any large body of text. Copy the text from your source.<br /><br />" +
          "2. Paste it into the field in the Text Chunker.<br /><br />" +
          "3. <strong>Select</strong> the Chunk Size as Select GPT-3 or GPT-4 depending which model you plan to use.<br /><br />" +
          "4. Press the <strong>'Split Text'</strong> button:<br /><br />" +
          "5. Review Chunks: Once the text has been chunked, you can review the individual chunks. Each chunk will be displayed separately, allowing for easy review and editing if necessary.<br /><br />" +
          "6. Press the <strong>'Paste to ChatGPT'</strong> button to send the chunk to chatGPT's input field.<br /><br />" +
          "<strong>Feedback and Support:</strong><br />" +
          "If you encounter any issues or have suggestions for improvements, please reach out using our Support Link. <a href='https://www.mybrainonai.com/support' target=_blank>Contact Support</a> "
        }
      />
      <div className="fancy_text" style={{ textAlign: "center" }}>
        Below is a link to a Chrome Extension called 'Glasp' that can create a
        transcript of any Youtube video. <br />
        In that extension there is an option to copy the text and you can paste
        it right into the text chunker.
        <br />
        <SpeakButton text={content} />
        <StopSpeakButton />
      </div>
      <br />
      <div style={{ textAlign: "center" }}>
        <h3>
          <strong>
            How to use Glasp Chrome Extension | Social Web Highlighter
          </strong>
        </h3>
        <iframe
          width="560"
          height="315"
          title="youtube video"
          src="https://www.youtube.com/embed/Pj1r_RMPadI"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ border: "none", maxWidth: "100%", maxHeight: "100%" }}
        ></iframe>{" "}
        <br />
        <a
          rel="noopener noreferrer"
          href="https://chrome.google.com/webstore/detail/glasp-social-web-highligh/blillmbchncajnhkjfdnincfndboieik"
          target="_blank"
        >
          {" "}
          <br />
          <button class="open-link-button">
            Our link to get the Glasp Chrome Extension from the Chrome Web Store
          </button>
        </a>
      </div>
    </div>
    // </div>
  );
}

export default ChunkerInstructions;
