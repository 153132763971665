import React, { useState, useEffect } from "react";
import { realtimeDb, ref, set, onValue, push, update } from "../firebase";
import "../components/styles/Hub.css";

const AdminHubButtons = () => {
  const [buttonsData, setButtonsData] = useState([]);
  const [newButton, setNewButton] = useState({
    icon: "",
    text: "",
    link: "",
    order: 0,
    description: "",
    type: "",
  });
  const [editing, setEditing] = useState(null); // Button currently being edited

  useEffect(() => {
    const buttonsRef = ref(realtimeDb, "hub_buttons");
    onValue(buttonsRef, (snapshot) => {
      const data = snapshot.val();
      const formattedData = Object.keys(data || {})
        .map((key) => ({ ...data[key], id: key }))
        .sort((a, b) => a.order - b.order);
      setButtonsData(formattedData);
    });
  }, []);

  const handleChange = (e) => {
    setNewButton({ ...newButton, [e.target.name]: e.target.value });
  };

  const addNewButton = () => {
    const newButtonRef = push(ref(realtimeDb, "hub_buttons"));
    set(newButtonRef, newButton);
    setNewButton({
      icon: "",
      text: "",
      link: "",
      order: 0,
      description: "",
      type: "",
    });
  };

  const deleteButton = (id) => {
    const buttonRef = ref(realtimeDb, `hub_buttons/${id}`);
    set(buttonRef, null);
  };

  const editButton = (button) => {
    setEditing(button);
    setNewButton(button);
  };

  const updateButton = () => {
    const buttonRef = ref(realtimeDb, `hub_buttons/${editing.id}`);
    update(buttonRef, newButton);
    setEditing(null);
    setNewButton({
      icon: "",
      text: "",
      link: "",
      order: 0,
      description: "",
      type: "",
    });
  };

  return (
    <div className="admin-hub-buttons">
      <h1>Admin Hub Buttons</h1>

      {/* Form to Add/Edit Button */}
      <div className="add-new-button flexRowWrap">
        {editing ? (
          <button onClick={updateButton}>Update Button</button>
        ) : (
          <button onClick={addNewButton}>Add New Button</button>
        )}
        <input
          type="number"
          placeholder="Order"
          name="order"
          onChange={handleChange}
          value={newButton.order}
        />
        <input
          type="text"
          placeholder="Icon"
          name="icon"
          onChange={handleChange}
          value={newButton.icon}
        />
        <input
          type="text"
          placeholder="Text"
          name="text"
          onChange={handleChange}
          value={newButton.text}
        />
        <input
          type="text"
          placeholder="Link"
          name="link"
          onChange={handleChange}
          value={newButton.link}
        />
        <input
          type="text"
          placeholder="Description"
          name="description"
          onChange={handleChange}
          value={newButton.description}
        />
        <input
          type="text"
          placeholder="Type"
          name="type"
          onChange={handleChange}
          value={newButton.type}
        />
      </div>

      {/* List Existing Buttons */}
      <ul>
        {buttonsData.map((button, index) => (
          <li key={index} className="hub_data">
            <button onClick={() => deleteButton(button.id)}>Delete</button>
            <button onClick={() => editButton(button)}>Edit</button>
            <span>Order: {button.order}</span>
            <span>Icon: {button.icon}</span>
            <span>Text: {button.text}</span>
            <span>Link: {button.link}</span>
            <span>Description: {button.description}</span>
            <span>Type: {button.type}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminHubButtons;
